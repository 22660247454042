// TableHead.tsx

import React from 'react';

interface TableHeadWithNoSelectAllProps {
    headerGroups: any[];
    selectionColumnId: string;
    isEnableSelectAll: boolean;
}

export const TableHeadWithNoSelectAll: React.FC<TableHeadWithNoSelectAllProps> = ({
    headerGroups,
    selectionColumnId,
    isEnableSelectAll,
}) => {

    return (
        <thead>
            {headerGroups.map((headerGroup: any) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column: any) => (
                        <th
                            {...column.getHeaderProps(column.getSortByToggleProps())}
                            className="text-left py-2 px-3 border-b-2 border-gray-200 bg-gray-100 font-semibold text-sm"
                            style={{ textAlign: 'center' }} // add this inline style to center text
                        >
                            {column.render('Header')}
                            <span>
                                {column.isSorted
                                    ? column.isSortedDesc
                                        ? ' 🔽'
                                        : ' 🔼'
                                    : ''}
                            </span>
                            {/* {column.id === 'phoneNumbers' && column.render('Filter')} */}
                        </th>
                    ))}
                </tr>
            ))}
        </thead>
    );
};