// HintForm.tsx

import React, { useState } from 'react';
import InputField from '../InputField';
import SelectField from '../SelectField';
import CustomButton from '../../components/CustomButton';
import { HintProps, HintReview } from '../../interfaces/Hint';
import HintReviewItem from '../tables/HintsTable/HintReviewItem';
import ConfirmationModal from '../ConfirmationModal';
import { Capitalize, RemovePrefix } from '../../utils/Strings';


const addUserRewivew = false

interface HintFormProps {
    onSubmit: (newHint: HintProps) => void;
    hint?: HintProps | undefined;
    onRequestClose: React.Dispatch<React.SetStateAction<boolean>>;
    opt?: string;
}

const HintForm: React.FC<HintFormProps> = ({
    onSubmit,
    hint,
    onRequestClose,
    opt,
}) => {
    // manage the data of the new/updated hint
    const [newHint, setNewHint] = useState<HintProps | undefined>(
        hint ? hint : {
            TimeStamp: '',
            Language: 'en',
            HintId: '',
            HintData: '',
            Stars: 0,
            HintReviews: {},
            IsActive: true,
        }
    );

    // manage the visibility of the ConfirmationModal
    const [showConfirmation, setShowConfirmation] = useState(false);

    // Keep track of invalid fields
    const [invalidFields, setInvalidFields] = useState<Set<keyof HintProps>>(() => {
        const initialInvalidFields = new Set<keyof HintProps>();
        if (!hint) {
            initialInvalidFields.add('HintId');
            initialInvalidFields.add('HintData');
        }
        return initialInvalidFields;
    });

    // manage the count state for the new user
    const [countNewReviews, setCountNewReviews] = useState<number>(0);

    // execute when the user clicks the confirm
    const handleConfirm = () => {
        // hide the confirmation modal
        setShowConfirmation(false);
        if (newHint) {
            // send the new/updated hint to the api
            onSubmit(newHint);
        }
    };

    // update the fields of the new/updated hint when the user type in the input fields.
    const handleFieldChange = (field: keyof HintProps, value: any) => {
        setNewHint((tempNewHint) => {
            if (tempNewHint) {
                return { ...tempNewHint, [field]: value };
            }
            return newHint;
        });
    };

    // Function to handle updating a Review
    const handleReviewUpdate = (keyPhoneNumber: string, updatedReview: HintReview) => {
        // debug
        console.log("handleReviewUpdate", keyPhoneNumber, updatedReview);

        setNewHint((newHint) => {
            if (newHint) {
                return { ...newHint, HintReviews: { ...newHint.HintReviews, [keyPhoneNumber]: updatedReview } };
            }
            return newHint;
        });
    };

    // Function to handle removing a Review
    const handleReviewRemove = (keyPhoneNumber: string) => {
        // debug
        console.log("handleReviewRemove", keyPhoneNumber);

        setNewHint((prevHint) => {
            if (prevHint) {
                handleFieldValidationChange('Stars', true, keyPhoneNumber);
                handleFieldValidationChange('HintReview', true, keyPhoneNumber);
                const updatedReviewsList = { ...prevHint.HintReviews };
                delete updatedReviewsList[keyPhoneNumber];
                return {
                    ...prevHint,
                    HintReviews: updatedReviewsList,
                };
            }

            return prevHint;
        });
    };


    // Update the newHint state when the hint prop changes
    const handleFieldValidationChange = (
        field: keyof HintProps | string,
        isValid: boolean,
        reviewIndex?: string
    ) => {
        // Set the new state of invalidFields based on the previous state
        setInvalidFields((prevInvalidFields) => {

            // debug
            console.log("prevInvalidFields", prevInvalidFields);

            // Create a new Set to store the updated invalid fields
            const newInvalidFields = new Set(prevInvalidFields);

            // If reviewIndex is provided, create a fieldKey using reviewIndex and field, otherwise use field as is
            const fieldKey = reviewIndex !== undefined ? `review_${reviewIndex}_${field}` : field;

            // If the field is valid, remove it from the set of invalid fields
            if (isValid) {
                newInvalidFields.delete(fieldKey as keyof HintProps);
            } else {
                // If the field is not valid, add it to the set of invalid fields
                newInvalidFields.add(fieldKey as keyof HintProps);
            }

            // Return the updated set of invalid fields
            return newInvalidFields;
        });
    };

    // Function to handle conformation for updating/inserting hint
    const handleFormSubmit = (event: React.FormEvent<HTMLFormElement | HTMLButtonElement>) => {
        event.preventDefault();
        // show the confirmation modal
        setShowConfirmation(true)
    };

    // Function to handle adding a new Review
    const handleAddReview = () => {
        setNewHint((prevHint) => {
            setCountNewReviews(countNewReviews + 1);
            if (prevHint) {
                // Create a new Review
                const keyPhoneNumber = 'newReview-' + countNewReviews; // generate a unique ID for this field
                const newReview: HintReview = {
                    HintReview: "",
                    Stars: 0,
                    PhoneNumber: "",
                    TimeStamp: "",
                };
                // add validation needed to pass for the new Review
                handleFieldValidationChange('Stars', false, keyPhoneNumber);
                handleFieldValidationChange('HintReview', false, keyPhoneNumber);
                return {
                    ...prevHint,
                    HintReviews: { ...prevHint.HintReviews, [keyPhoneNumber]: newReview },
                };
            } else {
                // Create the first Review in HintReviews.
                const keyPhoneNumber = 'newReview-' + countNewReviews; // generate a unique ID for this field
                const newReview: HintReview = {
                    HintReview: "",
                    Stars: 0,
                    PhoneNumber: "",
                    TimeStamp: "",
                };
                handleFieldValidationChange('Stars', false, keyPhoneNumber);
                handleFieldValidationChange('HintReview', false, keyPhoneNumber);
                return { ...prevHint as any, HintReviews: { [keyPhoneNumber]: newReview } };
            }
        });
    };

    console.log("invalidFields: ", invalidFields);
    console.log("HintForm");
    return (
        <form onSubmit={handleFormSubmit}>
            <InputField
                label="TimeStamp"
                onChange={(value) => handleFieldChange('TimeStamp', value)}
                onValidationChange={(isValid) => handleFieldValidationChange('TimeStamp', isValid)}
                type="text"
                value={newHint?.TimeStamp}
                readOnly={true}
            />
            <InputField
                label="Language"
                onChange={(value) => handleFieldChange('Language', value)}
                onValidationChange={(isValid) => handleFieldValidationChange('Language', isValid)}
                type="text"
                value={newHint?.Language}
                readOnly={opt === "update"}
            />
            <InputField
                label="Hint ID"
                onChange={(value) => handleFieldChange('HintId', value)}
                onValidationChange={(isValid) => handleFieldValidationChange('HintId', isValid)}
                type="text"
                value={newHint?.HintId}
                readOnly={opt === "update"}
            />
            <InputField
                label="HintData"
                onChange={(value) => handleFieldChange('HintData', value)}
                onValidationChange={(isValid) => handleFieldValidationChange('HintData', isValid)}
                type="text"
                value={newHint?.HintData}
                readOnly={false}
            />
            <InputField
                label="Stars"
                onChange={(value) => handleFieldChange('Stars', parseInt(value))}
                onValidationChange={(isValid) => handleFieldValidationChange('Stars', isValid)}
                type="number"
                min={0}
                max={5}
                value={newHint?.Stars.toString()}
                readOnly={true}
            />
            {opt !== 'insert' ? (
                <SelectField
                    label="Active?"
                    options={[
                        { value: 'Yes', label: 'Yes' },
                        { value: 'No', label: 'No' },
                    ]}
                    onChange={(value) => handleFieldChange('IsActive', value === 'Yes')}
                    value={newHint?.IsActive ? 'Yes' : 'No'}
                    readOnly={true}
                />
            ) : null}
            <div>
                <h2 className="font-bold mb-2">Hint Reviews</h2>
                <div
                    className="hint-reviews-grid"
                    style={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(3, 1fr)', // Change 'auto-fill' to 4 to have 4 hint reviews per row
                        gridGap: '1rem',
                        maxWidth: '100%',
                    }}
                >
                    {
                        Object.entries(newHint?.HintReviews || {}).map(([keyPhoneNumber, review], index) => {
                            if (!keyPhoneNumber.includes("newReview")) {
                                review.PhoneNumber = keyPhoneNumber;
                            }
                            return (
                                <HintReviewItem
                                    key={keyPhoneNumber}
                                    indexReview={index}
                                    phoneNumber={keyPhoneNumber}
                                    hintReview={review}
                                    handleFieldValidationChange={handleFieldValidationChange}
                                    onUpdate={handleReviewUpdate}
                                    onRemove={handleReviewRemove}
                                />)
                        })
                    }
                    {addUserRewivew &&
                        <CustomButton
                            className={`bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-300 active:bg-green-700 text-white px-4 py-2 rounded transition-colors duration-200`}
                            type="add"
                            onClick={handleAddReview}
                        >
                            Add Hint Review
                        </CustomButton>
                    }
                </div>
            </div>
            <div className="flex justify-between mt-4 pb-10">
                <ConfirmationModal
                    isOpen={showConfirmation}
                    onRequestClose={() => setShowConfirmation(false)}
                    title={`${opt} Hint`}
                    message={`Are you sure you want to ${opt} this Hint?`}
                    onCancel={() => setShowConfirmation(false)}
                    onConfirm={handleConfirm}
                />
                {opt === 'insert' || opt === 'update' ? (
                    <>
                        <CustomButton
                            onClick={handleFormSubmit}
                            disabled={invalidFields.size > 0}
                            type={opt}
                        >
                            {Capitalize(opt)}
                        </CustomButton>
                        <CustomButton
                            type="cancel"
                            onClick={() => onRequestClose(false)}>
                            Cancel
                        </CustomButton>
                    </>
                ) : opt === 'page-update' || opt === 'page-insert' ? (
                    <>
                        <CustomButton
                            onClick={handleFormSubmit}
                            disabled={invalidFields.size > 0}
                            type={RemovePrefix(opt, 'page-')}
                        >
                            {Capitalize(RemovePrefix(opt, 'page-'))}
                        </CustomButton>
                    </>
                ) : (<></>)}
            </div>
        </form>
    );
};

export default HintForm;

