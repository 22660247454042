// UpdateHint.tsx

import { useKeycloak } from '@react-keycloak/web';
import 'react-toastify/dist/ReactToastify.css';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import PageHeader from '../../../../components/PageHeader';
import SearchBar from '../../../../components/SearchBar/SearchBar';
import useApiCall from '../../../../hooks/apiCall';
import { useHintsActions } from '../../../../hooks/useHintsActions';
import HintForm from '../../../../components/Form/HintForm';

const UpdateHint = () => {
    // Get the Keycloak instance
    const { keycloak } = useKeycloak();

    // Get the apiCall hook
    const apiCall = useApiCall(keycloak);

    // Get the useHintsActions hook
    const {
        isLoading,
        selectedHint,
        handleUpdateSubmit,
        handleSearch,
    } = useHintsActions(apiCall, true);

    console.log('UpdateHint');
    return (
        <>
            {
                isLoading ? (
                    <LoadingSpinner />
                ) : (
                    <div className="container mx-auto mt-10">
                        <PageHeader
                            title="Update Hint"
                            className="text-2xl font-semibold mb-4"
                        />
                        <div className="mb-6">
                            <SearchBar
                                onSearch={handleSearch}
                                placeholder='Search Hint ID by name...'
                            />
                        </div>
                        <HintForm
                            onSubmit={handleUpdateSubmit}
                            hint={selectedHint}
                            onRequestClose={() => { }}
                            opt='page-update'
                        />
                    </div>
                )}
        </>
    );
};

export default UpdateHint;