import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

const navLinkVariants = {
  hidden: { opacity: 0, y: -10 },
  visible: { opacity: 1, y: 0 },
};

const NavLink = ({ to, name }: any) => (
  <motion.div
    initial="hidden"
    animate="visible"
    variants={navLinkVariants}
    transition={{ duration: 0.5 }}
    className="text-base px-0 py-2 rounded-md font-medium"
  >
    <Link
      to={to}
      className="text-gray-800 hover:text-gray-900 focus:outline-none focus:text-gray-900"
    >
      {name}
    </Link>
  </motion.div>
);

const NavLinks = ({ links }: any) => (
  <>
    {links.map(({ name, to }: any) => (
      <NavLink key={to} to={to} name={name} />
    ))}
  </>
);

export default NavLinks;
