// NotificationForm.tsx

import React, { useCallback, useEffect, useState } from "react";
import InputField from "../InputField";
import SelectField from "../SelectField";
import MultiSelect from "../MultiSelect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import ConfirmationModal from "../ConfirmationModal";
import CustomButton from "../CustomButton";
import {
  NotificationHistoryProps,
  NotificationProps,
} from "../../interfaces/Notification";

interface NotificationFormProps {
  opt: string;
  data?: NotificationHistoryProps;
  onSubmit: (notificationData: any) => void;
  onRequestClose?: (flag: boolean) => void;
  companyOptions?: Array<{ value: string; label: string }>;
}

// Options for platformOs
const platformOsOptions = [
  { value: "android", label: "Android" },
  { value: "ios", label: "iOS" },
];

const NotificationForm = React.memo<NotificationFormProps>(
  ({ opt, data, onSubmit, onRequestClose, companyOptions = [] }) => {
    // Use a single state for form data
    const [formData, setFormData] = useState<NotificationProps>(() => {
      if (data) {
        return data;
      } else {
        return {
          Body: "",
          CompanyId: "",
          Message: "",
          Priority: "high",
          Success: false,
          TimeStamp: "",
          Title: "",
          Type: "notification",
          Url: "",
          PlatformOsList: [],
        };
      }
    });

    // States for selected companies, platforms, and options
    const [selectedCompanies, setSelectedCompanies] = useState([]);
    const [selectedPlatformOsList, setSelectedPlatformOsList] = useState([]);

    // showConfirmation is used to show/hide the confirmation modal
    const [showConfirmation, setShowConfirmation] = useState(false);

    // Keep track of invalid fields
    const [invalidFields, setInvalidFields] = useState<
      Set<keyof NotificationProps>
    >(() => {
      const initialInvalidFields = new Set<keyof NotificationProps>();
      if (selectedPlatformOsList.length < 1) {
        initialInvalidFields.add("PlatformOSList");
      }
      if (selectedCompanies.length < 1) {
        initialInvalidFields.add("Companies");
      }
      if (!formData.Title) {
        initialInvalidFields.add("Title");
      }
      if (!formData.Message) {
        initialInvalidFields.add("Message");
      }
      if (!formData.Url) {
        initialInvalidFields.add("Url");
      }
      if (!formData.Body) {
        initialInvalidFields.add("Body");
      }
      return initialInvalidFields;
    });

    // Destructure form data
    const { Title, Message, Url, Body, Priority, Type } = formData;

    // Handle form data changes
    const handleChange = useCallback(
      (key: string, value: string) => {
        setFormData({ ...formData, [key]: value });
      },
      [formData]
    );

    // Handle form submission
    const handleSubmit = useCallback(() => {
      // event.preventDefault(); // Prevent default form submission behavior (page reload)
      const notificationData = {
        PlatformOsList: selectedPlatformOsList.map(
          (platform: { value: string }) => platform.value
        ),
        Type: Type,
        Title: Title,
        Message: Message,
        Url: Url,
        Body: Body,
        Priority: Priority,
        CompaniesMap: selectedCompanies.reduce(
          (
            acc: { [key: string]: null },
            company: { value: string; label: string }
          ) => {
            acc[company.label] = null;
            return acc;
          },
          {}
        ),
      };

      onSubmit(notificationData);
    }, [
      onSubmit,
      Title,
      Message,
      Url,
      Body,
      Priority,
      Type,
      selectedCompanies,
      selectedPlatformOsList,
    ]);

    // Update the newCompany state when the company prop changes
    const updateInvalidFields = useCallback(
      (field: keyof NotificationProps, isValid: boolean) => {
        setInvalidFields((prevInvalidFields) => {
          const newInvalidFields = new Set(prevInvalidFields);
          if (isValid) {
            newInvalidFields.delete(field);
          } else {
            newInvalidFields.add(field);
          }
          return newInvalidFields;
        });
      },
      []
    );

    // Update invalid fields for selected companies
    useEffect(() => {
      console.log("useEffect-selectedCompanies", selectedCompanies);
      updateInvalidFields("Companies", selectedCompanies.length > 0);
    }, [selectedCompanies, updateInvalidFields]);

    // Update invalid fields for selected PlatformOS
    useEffect(() => {
      console.log("useEffect-selectedPlatformOsList", selectedPlatformOsList);
      updateInvalidFields("PlatformOSList", selectedPlatformOsList.length > 0);
    }, [selectedPlatformOsList, updateInvalidFields]);

    // Handle confirming the confirmation modal
    const handleConfirm = useCallback(() => {
      // Hide the confirmation modal
      setShowConfirmation(false);
      // Submit the form
      handleSubmit();
    }, [handleSubmit]);

    // Handle the confirmation modal
    const handleFormSubmit = useCallback(
      (
        e:
          | React.FormEvent<HTMLFormElement>
          | React.MouseEvent<HTMLButtonElement>
      ) => {
        // Prevent the default form submission behavior
        e.preventDefault();
        // Perform form validation and data processing here
        // ...
        // Call the onSubmit prop with the updated company data
        setShowConfirmation(true);
      },
      []
    );

    console.log("invalidFields", invalidFields);
    console.log("NotificationForm");
    return (
      <>
        <form onSubmit={handleFormSubmit}>
          {opt === "none" && (
            <InputField
              label="Time Stamp"
              value={data?.TimeStamp}
              onChange={() => {}}
              show={data !== undefined}
              readOnly={true}
            />
          )}
          <InputField
            label="Title"
            value={Title}
            onChange={(value) => handleChange("Title", value)}
            placeholder="Enter notification Title"
            onValidationChange={(isValid) =>
              updateInvalidFields("Title", isValid)
            }
            type="text"
            readOnly={opt === "send" ? false : true}
          />
          <InputField
            label="Message"
            value={Message}
            onChange={(value) => handleChange("Message", value)}
            placeholder="Enter notification Message"
            onValidationChange={(isValid) =>
              updateInvalidFields("Message", isValid)
            }
            type="text"
            readOnly={opt === "send" ? false : true}
          />
          <InputField
            label="Url"
            value={Url}
            onChange={(value) => handleChange("Url", value)}
            placeholder="Enter notification Url"
            onValidationChange={(isValid) =>
              updateInvalidFields("Url", isValid)
            }
            type="url"
            readOnly={opt === "send" ? false : true}
          />
          <InputField
            label="Body"
            value={Body}
            onChange={(value) => handleChange("Body", value)}
            placeholder="Enter notification Body"
            onValidationChange={(isValid) =>
              updateInvalidFields("Body", isValid)
            }
            type="text"
            readOnly={opt === "send" ? false : true}
          />
          <SelectField
            label="Priority"
            value={Priority}
            onChange={(value) => handleChange("Priority", value)}
            options={[
              { value: "low", label: "Low" },
              { value: "normal", label: "Normal" },
              { value: "high", label: "High" },
            ]}
            readOnly={opt === "send" ? false : true}
          />
          <SelectField
            label="Type"
            value={Type}
            onChange={(value) => handleChange("Type", value)}
            options={[
              { value: "notification", label: "Notification" },
              { value: "Message", label: "Message" },
            ]}
            readOnly={opt === "send" ? false : true}
          />
          <MultiSelect
            label="Companies"
            options={companyOptions}
            onChange={setSelectedCompanies}
            value={
              opt === "send"
                ? selectedCompanies
                : data?.CompanyId
                ? [{ value: data.CompanyId, label: data.CompanyId }]
                : []
            }
            readOnly={opt === "send" ? false : true}
          />
          <MultiSelect
            label="Platform OS List"
            options={platformOsOptions}
            onChange={setSelectedPlatformOsList}
            value={
              opt === "send"
                ? selectedPlatformOsList
                : data?.PlatformOsList?.map((os) => ({ value: os, label: os }))
            }
            readOnly={opt === "send" ? false : true}
          />

          {/* Render the confirmation modal and submit button */}
          <div className="pt-10 pb-5">
            <ConfirmationModal
              isOpen={showConfirmation}
              onRequestClose={() => setShowConfirmation(false)}
              title="Send Notification"
              message="Are you sure you want to send the notification?"
              onCancel={() => setShowConfirmation(false)}
              onConfirm={handleConfirm}
            />
            {opt === "send" ? (
              <CustomButton
                type="send"
                // className={` ${Title && Message
                //     ? 'bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-300 active:bg-green-700'
                //     : 'bg-green-300 cursor-not-allowed'
                //     } text-white px-4 py-2 rounded transition-colors duration-200`}
                disabled={invalidFields.size > 0}
                onClick={handleFormSubmit}
              >
                <FontAwesomeIcon icon={faPaperPlane} className="mr-2" />
                Send Notification
              </CustomButton>
            ) : onRequestClose && opt === "none" ? (
              <CustomButton type="cancel" onClick={() => onRequestClose(false)}>
                Cancel
              </CustomButton>
            ) : (
              <></>
            )}
          </div>
        </form>
      </>
    );
  }
);

export default NotificationForm;
