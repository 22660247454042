// ApplicantCompanyForm.tsx

import React, { useState } from "react";
import InputField from "../InputField";
import SelectField from "../SelectField";
import CustomButton from "../CustomButton";

import ConfirmationModal from "../ConfirmationModal";
import { CompanyApplicant } from "../../interfaces/CompanyApplicant";
// import { CompanyApplicant } from '../../routes/pages/manager/companies/ApplicantCompanies';

interface ApplicantCompanyFormProps {
  onSubmit: (newApplicantCompany: CompanyApplicant) => void;
  company?: CompanyApplicant | undefined;
  onRequestClose?: () => void;
  opt?: string;
}

const ApplicantCompanyForm: React.FC<ApplicantCompanyFormProps> = ({
  onSubmit,
  company,
  onRequestClose,
  opt,
}) => {
  // manage the visibility of the ConfirmationModal
  const [showConfirmation, setShowConfirmation] = useState(false);

  // New company data to send to api
  const [newApplicantCompany, setNewApplicantCompany] = useState<
    CompanyApplicant | undefined
  >(() => {
    if (company) {
      return company;
    } else {
      return {
        CompanyId: "",
        ContactPhoneNumber: "",
        ContactEmail: "",
        IsActive: true,
        UsersList: {},
      };
    }
  });

  // Keep track of invalid fields
  const [invalidFields, setInvalidFields] = useState<
    Set<keyof CompanyApplicant>
  >(() => {
    const initialInvalidFields = new Set<keyof CompanyApplicant>();
    if (!company) {
      initialInvalidFields.add("CompanyId");
      initialInvalidFields.add("ContactPhoneNumber");
      initialInvalidFields.add("ContactEmail");
    }
    return initialInvalidFields;
  });

  // Update the newCompany state when the company prop changes
  const handleFieldChange = (field: keyof CompanyApplicant, value: any) => {
    setNewApplicantCompany((tempNewCompany) => {
      if (tempNewCompany) {
        return { ...tempNewCompany, [field]: value };
      }
      return newApplicantCompany;
    });
  };

  // Update the newCompany state when the company prop changes
  const handleFieldValidationChange = (
    field: keyof CompanyApplicant | string,
    isValid: boolean,
    userIndex?: string
  ) => {
    // Set the new state of invalidFields based on the previous state
    setInvalidFields((prevInvalidFields) => {
      // debug
      console.log("prevInvalidFields", prevInvalidFields);

      // Create a new Set to store the updated invalid fields
      const newInvalidFields = new Set(prevInvalidFields);

      // If userIndex is provided, create a fieldKey using userIndex and field, otherwise use field as is
      const fieldKey =
        userIndex !== undefined ? `user_${userIndex}_${field}` : field;

      // If the field is valid, remove it from the set of invalid fields
      if (isValid) {
        newInvalidFields.delete(fieldKey as keyof CompanyApplicant);
      } else {
        // If the field is not valid, add it to the set of invalid fields
        newInvalidFields.add(fieldKey as keyof CompanyApplicant);
      }

      // Return the updated set of invalid fields
      return newInvalidFields;
    });
  };

  const handleCancel = () => {
    setShowConfirmation(false);
  };

  const handleConfirm = () => {
    setShowConfirmation(false);
    if (newApplicantCompany) {
      onSubmit(newApplicantCompany);
    }
  };

  const handleFormSubmit = (
    e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>
  ) => {
    // Prevent the default form submission behavior
    e.preventDefault();

    // Perform form validation and data processing here

    // Call the onSubmit prop with the updated company data
    setShowConfirmation(true);
  };

  let optString = "";
  if (opt === "insert") {
    optString = "Add";
  } else if (opt === "update") {
    optString = "Update";
  }

  // e.preventDefault();
  console.log("invalidFields: ", invalidFields);
  console.log("ApplicantCompanyForm");
  return (
    <form onSubmit={handleFormSubmit}>
      <InputField
        label="Company"
        onChange={(value) => handleFieldChange("CompanyId", value)}
        onValidationChange={(isValid) =>
          handleFieldValidationChange("CompanyId", isValid)
        }
        type="text"
        value={newApplicantCompany?.CompanyId}
        readOnly={opt === "insert" ? false : true}
      />
      <InputField
        label="Time Stamp"
        value={newApplicantCompany?.TimeStamp}
        onChange={() => {}}
        show={company !== undefined}
        readOnly={true}
      />
      <InputField
        label="Contact Phone Number"
        onChange={(value) => handleFieldChange("ContactPhoneNumber", value)}
        onValidationChange={(isValid) =>
          handleFieldValidationChange("ContactPhoneNumber", isValid)
        }
        type="tel"
        value={newApplicantCompany?.ContactPhoneNumber}
        readOnly={company?.IsActive}
      />
      <InputField
        label="Contact Email"
        onChange={(value) => handleFieldChange("ContactEmail", value)}
        onValidationChange={(isValid) =>
          handleFieldValidationChange("ContactEmail", isValid)
        }
        type="email"
        value={newApplicantCompany?.ContactEmail}
        readOnly={company?.IsActive}
      />

      {opt === "insert" ? (
        <></>
      ) : (
        <>
          <SelectField
            label="Active?"
            options={[
              { value: "No", label: "No" },
              { value: "Yes", label: "Yes" },
            ]}
            onChange={(value) => handleFieldChange("IsActive", value === "Yes")}
            value={newApplicantCompany?.IsActive ? "Yes" : "No"}
            readOnly={true}
          />
        </>
      )}
      <div className="flex justify-between mt-4 pb-10">
        <ConfirmationModal
          isOpen={showConfirmation}
          onRequestClose={handleCancel}
          title={`${optString} Applicant Company`}
          message={`Are you sure you want to ${optString} this Applicant Company?`}
          onCancel={handleCancel}
          onConfirm={handleConfirm}
        />
        {opt === "insert" ? (
          <>
            <CustomButton
              onClick={handleFormSubmit}
              // onClick={() => formRef.current?.requestSubmit()}
              type="add"
              disabled={invalidFields.size > 0}
            >
              Add
            </CustomButton>
            <CustomButton
              type="cancel"
              onClick={onRequestClose}
              // color="gray"
            >
              Cancel
            </CustomButton>
          </>
        ) : opt === "update" ? (
          <>
            <CustomButton
              onClick={handleFormSubmit}
              // onClick={() => formRef.current?.requestSubmit()}
              type="update"
              disabled={company?.IsActive || invalidFields.size > 0}
            >
              Update
            </CustomButton>
            <CustomButton
              type="cancel"
              onClick={onRequestClose}
              // color="gray"
            >
              Cancel
            </CustomButton>
          </>
        ) : (
          <></>
        )}
      </div>
    </form>
  );
};

export default ApplicantCompanyForm;
