// UpdateCompany.tsx

import { useKeycloak } from '@react-keycloak/web';
import 'react-toastify/dist/ReactToastify.css';
// import { Company, User } from '../../../../components/CompaniesTable/CompaniesTable';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import PageHeader from '../../../../components/PageHeader';
// import CompanySearchBar from '../../../../components/CompanyTemplate/CompanySearchBar';
import CompanyForm from '../../../../components/Form/CompanyForm';
import SearchBar from '../../../../components/SearchBar/SearchBar';
import useApiCall from '../../../../hooks/apiCall';
import { useCompanyActions } from '../../../../hooks/useCompanyActions';


const UpdateCompany = () => {
    // Get the Keycloak instance
    const { keycloak } = useKeycloak();

    // Get the apiCall hook
    const apiCall = useApiCall(keycloak);

    // Get the useCompanyActions hook
    const {
        isLoading,
        // setIsLoading,
        selectedCompany,
        // setSelectedCompany,
        handleUpdateSubmit,
        handleSearch,
    } = useCompanyActions(apiCall, true);

    console.log('UpdateCompany');
    return (
        <>
            {
                isLoading ? (
                    <LoadingSpinner />
                ) : (
                    <div className="container mx-auto mt-10">
                        <PageHeader title="Update Company" className="text-2xl font-semibold mb-4" />
                        <div className="mb-6">
                            <SearchBar
                                onSearch={handleSearch}
                                placeholder='Search company by name...'
                            />
                        </div>
                        <CompanyForm
                            onSubmit={handleUpdateSubmit}
                            company={selectedCompany}
                            onRequestClose={() => { }}
                            opt='page-update'
                        />
                    </div>
                )}
        </>
    );
};

export default UpdateCompany;
