// TableBodyTouch.tsx

interface TableBodyTouchProps {
    rows: any[];
    prepareRow: any;
    getTableBodyProps: any;
    page: any;
    setSelectedRow: any;
    onDoubleClick: (row: any) => void;
}

export const TableBodyTouch: React.FC<TableBodyTouchProps> = ({
    rows,
    prepareRow,
    getTableBodyProps,
    page,
    setSelectedRow,
    onDoubleClick,
}) => {
    return (
        <tbody {...getTableBodyProps()}>

            {page.map((row: any) => {
                prepareRow(row);
                return (
                    <tr
                        {...row.getRowProps()}
                        onClick={() => setSelectedRow(row.original)}
                        onDoubleClick={() => onDoubleClick(row.original)}
                    >
                        {row.cells.map((cell: any) => {
                            return (
                                <td
                                    {...cell.getCellProps()}
                                    className="border px-4 py-2 cursor-pointer"
                                    style={{ textAlign: 'center' }}
                                >

                                    {cell.render("Cell")}

                                </td>
                            );
                        })}
                    </tr>
                );
            })}
        </tbody>
    );
};