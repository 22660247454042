import { z } from 'zod';

// Configuration options for each validation schema
const maxNumOfTelDigits = 15;
const passwordMinLength = 8;

// Define validation schemas for each input type
const emailSchema = z.string().email();
const passwordSchema = z.string().min(passwordMinLength);
const textSchema = z.string().nonempty();
const numberSchema = z.number();
const urlSchema = z.string().url();
const telSchema = z.string().regex(new RegExp(`^\\+?\\d{1,${maxNumOfTelDigits}}$`)); // Simple phone number validation
const dateSchema = z.string().regex(/^\d{4}-\d{2}-\d{2}$/); // YYYY-MM-DD format

export const validateInput = (type: string, value: string): string | null => {
  try {
    switch (type) {
      case 'email':
        // Validate email format
        emailSchema.parse(value);
        break;
      case 'password':
        // Validate password length (minimum length is specified by passwordMinLength)
        passwordSchema.parse(value);
        break;
      case 'number':
        // Validate if the input is a number
        numberSchema.parse(parseFloat(value));
        break;
      case 'url':
        // Validate URL format
        urlSchema.parse(value);
        break;
      case 'tel':
        // Validate phone number format (maximum digits is specified by maxNumOfTelDigits)
        telSchema.parse(value);
        break;
      case 'date':
        // Validate date format (YYYY-MM-DD)
        dateSchema.parse(value);
        break;
      default:
        // Validate non-empty text input
        textSchema.parse(value);
        break;
    }
    return null;
  } catch (error) {
    if (error instanceof z.ZodError) {
      return error.errors[0].message; // Return the first validation error message
    }
    return 'Invalid input';
  }
};