// HintsApi.ts

import { BASE_URL } from "../../env";
import { HINTS_BASE } from "./BaseManagers";

export const HintsApi = {
  Insert: {
    // Add new hint
    url: `${BASE_URL}${HINTS_BASE}`,
    method: "POST",
  },
  Update: {
    // Update hint
    url: `${BASE_URL}${HINTS_BASE}`,
    method: "PATCH",
  },
  Delete: {
    // Delete hint
    url: `${BASE_URL}${HINTS_BASE}/`,
    method: "DELETE",
  },
  Get: {
    // Get hints
    url: `${BASE_URL}${HINTS_BASE}`,
    method: "GET",
  },
  Review: {
    Base: `${HINTS_BASE}/review`,
    Update: {
      // Update hint review
      url: `${BASE_URL}${HINTS_BASE}/review`,
      method: "PATCH",
    },
    Delete: {
      // Delete hint review
      url: `${BASE_URL}${HINTS_BASE}/review`,
      method: "DELETE",
    },
  },
  Reviews: {
    Base: `${HINTS_BASE}/reviews`,
    DeleteAll: {
      // Delete hint reviews
      url: `${BASE_URL}${HINTS_BASE}/reviews`,
      method: "DELETE",
    },
  },
};
