// AddHint.tsx

import { useKeycloak } from '@react-keycloak/web';
import 'react-toastify/dist/ReactToastify.css';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import PageHeader from '../../../../components/PageHeader';
import useApiCall from '../../../../hooks/apiCall';
import { useHintsActions } from '../../../../hooks/useHintsActions';
import HintForm from '../../../../components/Form/HintForm';

const AddHint = () => {
    // Get the Keycloak instance
    const { keycloak } = useKeycloak();

    // Get the apiCall hook
    const apiCall = useApiCall(keycloak);

    // Get the useHintsActions hook
    const {
        isLoading,
        handleInsertSubmit,
    } = useHintsActions(apiCall, true);

    console.log('AddHint');
    return (
        <>
            {isLoading ? (
                <LoadingSpinner />
            ) : (
                <div className="container mx-auto mt-10">
                    <PageHeader title="Add Hint" className="text-2xl font-semibold mb-4" />
                    <HintForm
                        onSubmit={handleInsertSubmit}
                        opt='page-insert'
                        onRequestClose={() => { }} />
                </div>
            )
            }
        </>
    );
}

export default AddHint;
