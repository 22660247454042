// CustomButton.tsx

import React from 'react';
import { IconType } from 'react-icons';
import * as AiIcons from 'react-icons/ai';
import { TypeIconMap } from '../config/TypeIconMap';
import { TypeColorMap } from '../config/TypeColorMap';
import './CustomButton.css';

interface CustomButtonProps {
    children: React.ReactNode;
    disabled?: boolean;
    type?: 'button' | 'submit' | 'reset' | string;
    onClick?: (event: React.MouseEvent<HTMLButtonElement> | any) => void;
    className?: string;
    color?: string;
    style?: React.CSSProperties;
}

// const getDefaultColor = (type: string) => {
//     return TypeColorMap[type] || '#007bff'; // Fallback to blue if no color is found for the type
// };

// const lightenColor = (color: string, amount: number) => {
//     const [r, g, b] = color.match(/\w\w/g)!.map((c) => parseInt(c, 16));
//     return `#${((1 << 24) + (Math.max(0, Math.min(255, r + amount)) << 16) + (Math.max(0, Math.min(255, g + amount)) << 8) + Math.max(0, Math.min(255, b + amount)))
//         .toString(16)
//         .slice(1)
//         }`;
// };

const CustomButton: React.FC<CustomButtonProps> = ({
    children,
    disabled = false,
    type = 'button',
    onClick,
    className,
    color,
    style,
}) => {
    // Get the default color from the TypeColorMap
    const buttonColor = color || TypeColorMap[type] || '#007bff';

    const getIcon = () => {
        const IconComponent = TypeIconMap[type] || (AiIcons[type as keyof typeof AiIcons] as IconType | undefined);
        return IconComponent ? <IconComponent /> : null;
    };

    const buttonStyle: React.CSSProperties = {
        backgroundColor: disabled ? '#a5a5a5' : buttonColor,
        color: color === 'transparent' ? 'black' : 'white',
        ...style,
    };

    return (
        <button
            style={buttonStyle}
            disabled={disabled}
            type={(['button', 'submit', 'reset'].includes(type) ? type : 'button') as 'button' | 'submit' | 'reset' | undefined}
            onClick={onClick}
            className={`custom-button${disabled ? ' disabled' : ''} ${className}`}
        >
            {getIcon() && <span style={{ marginRight: '0.5rem' }}>{getIcon()}</span>}
            {children}
        </button>
    );
};

export default CustomButton;