// HintReviewItem.tsx

import React from 'react';
import InputField from '../../InputField';
import { HintReview } from '../../../interfaces/Hint';
import CustomButton from '../../CustomButton';

interface HintReviewItemProps {
    hintReview: HintReview;
    phoneNumber: string;
    indexReview: number;
    onUpdate: (phoneNumber: string, updatedHintReview: HintReview) => void;
    onRemove: (phoneNumber: string) => void;
    handleFieldValidationChange: any;
}

const HintReviewItem: React.FC<HintReviewItemProps> = ({
    hintReview,
    phoneNumber,
    indexReview,
    onUpdate,
    onRemove,
    handleFieldValidationChange,
}) => {
    const handleFieldChange = (field: keyof HintReview, value: any) => {
        const updatedHintReview = { ...hintReview, [field]: value };
        onUpdate(phoneNumber, updatedHintReview);
    };


    if (!hintReview || !phoneNumber || indexReview === undefined) {
        return <></>;
    }

    return (
        <div className="bg-white shadow rounded p-4 mb-4">
            <h3 className="font-bold mb-2">Hint Review {indexReview + 1}:</h3>
            <InputField
                label="Phone Number"
                value={phoneNumber}
                onChange={(value) => handleFieldChange('PhoneNumber', value)}
                onValidationChange={(isValid) => handleFieldValidationChange('PhoneNumber', isValid, phoneNumber)}
                type="tel"
                readOnly={true}

            />
            <InputField
                label="Time stamp"
                value={hintReview.TimeStamp}
                onChange={() => { }}
                type="text"
                readOnly={true}
            />
            <InputField
                label="Stars"
                value={hintReview.Stars.toString()}
                onChange={(value) => handleFieldChange('Stars', parseInt(value))}
                onValidationChange={(isValid) => handleFieldValidationChange('Stars', isValid, phoneNumber)}
                type="number"
                // min={0}
                // max={5}
                readOnly={true}
            />
            <InputField
                label="Hint Review"
                value={hintReview.HintReview}
                onChange={(value) => handleFieldChange('HintReview', value)}
                onValidationChange={(isValid) => handleFieldValidationChange('HintReview', isValid, phoneNumber)}
                type="text"
                readOnly={true}
            />
            <CustomButton
                className="bg-red-500 text-white px-2 py-1 rounded"
                type="remove"
                onClick={() => onRemove(phoneNumber)}
            >
                Remove
            </CustomButton>
        </div>
    );
};

export default HintReviewItem;