// ApplicantCompanyInfoModal.tsx

import React from 'react';
import Modal from 'react-modal';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import ApplicantCompanyForm from '../../Form/ApplicantCompanyForm';
import { CompanyApplicant } from '../../../interfaces/CompanyApplicant';


// Define the prop types for the CompanyInfoModal component
interface CompanyInfoModalProps {
    companyApplicant: CompanyApplicant | undefined;
    isOpen: boolean;
    onRequestClose: () => void;
    handleSubmit: (updateApplicantCompany: CompanyApplicant) => void;
    opt?: string;
}
const ApplicantCompanyInfoModal: React.FC<CompanyInfoModalProps> = ({
    companyApplicant,
    isOpen,
    onRequestClose,
    handleSubmit,
    opt,
}) => {

    // Function to handle closing the modal without saving
    const handleCloseWithoutSaving = () => {
        onRequestClose();
    };

    return (
        <TransitionGroup component={null}>
            {isOpen && (
                <CSSTransition classNames="modal-transition" timeout={300}>
                    <Modal
                        isOpen={isOpen}
                        onRequestClose={() => handleCloseWithoutSaving()}
                        contentLabel="Company Info Modal"
                        className="bg-white rounded-md p-8 mx-auto mt-24 modal-content"
                        overlayClassName="fixed inset-0 bg-black bg-opacity-40 flex items-center justify-center modal-overlay"
                    >
                        <ApplicantCompanyForm
                            onSubmit={handleSubmit}
                            company={companyApplicant}
                            onRequestClose={onRequestClose}
                            opt={opt}
                        />
                    </Modal>
                </CSSTransition>
            )}
        </TransitionGroup>
    );
};

export default ApplicantCompanyInfoModal;

