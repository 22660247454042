// TypeColorMap.ts

// Color map for different button types
export const TypeColorMap: Record<string, string> = {
  submit: "#007bff", // Blue
  reset: "#dc3545", // Red
  add: "#28a745", // Green
  remove: "#ffc107", // Yellow
  search: "#17a2b8", // Cyan
  download: "#20c997", // Teal
  upload: "#20c997", // Teal
  edit: "#ffc107", // Yellow
  save: "#007bff", // Blue
  delete: "#dc3545", // Red
  home: "#6c757d", // Gray
  email: "#6c757d", // Gray
  user: "#6c757d", // Gray
  info: "#17a2b8", // Cyan
  warning: "#ffc107", // Yellow
  help: "#17a2b8", // Cyan
  back: "#6c757d", // Gray
  next: "#6c757d", // Gray
  settings: "#6c757d", // Gray
  cart: "#007bff", // Blue
  notification: "#fd7e14", // Orange
  logout: "#dc3545", // Red
  login: "#28a745", // Green
  update: "#17a2b8", // Cyan
  cancel: "#6c757d", // Gray
  filter: "#6c757d", // Gray
  close: "#dc3545", // Red
  print: "#6c757d", // Gray
  share: "#6c757d", // Gray
  copy: "#6c757d", // Gray
  cut: "#6c757d", // Gray
  paste: "#6c757d", // Gray
  move: "#6c757d", // Gray
  lock: "#343a40", // Dark gray
  unlock: "#28a745", // Green
  calendar: "#6c757d", // Gray
  clock: "#6c757d", // Gray
  time: "#6c757d", // Gray
  location: "#6c757d", // Gray
  globe: "#6c757d", // Gray
  link: "#6c757d", // Gray
  image: "#6c757d", // Gray
  video: "#6c757d", // Gray
  play: "#007bff", // Blue
  pause: "#007bff", // Blue
  stop: "#007bff", // Blue
  previous: "#6c757d", // Gray
  zoomIn: "#6c757d", // Gray
  zoomOut: "#6c757d", // Gray
  fastForward: "#007bff", // Blue
  rewind: "#007bff", // Blue
  skipNext: "#6c757d", // Gray
  skipPrevious: "#6c757d", // Gray
  fullscreen: "#6c757d", // Gray
  exitFullscreen: "#6c757d", // Gray
  sound: "#6c757d", // Gray
  mute: "#6c757d", // Gray
  volumeUp: "#6c757d", // Gray
  volumeDown: "#6c757d", // Gray
  radioButtonOn: "#007bff", // Blue
  radioButtonOff: "#6c757d", // Gray
  checkBoxOn: "#28a745", // Green
  checkBoxOff: "#6c757d", // Gray
  dropdown: "#6c757d", // Gray
  sort: "#6c757d", // Gray
  expand: "#6c757d", // Gray
  collapse: "#6c757d", // Gray
};
