// Pagination.tsx

import React from 'react';

interface PaginationProps {
    canPreviousPage: boolean;
    canNextPage: boolean;
    gotoPage: (page: number) => void;
    nextPage: () => void;
    pageIndex: number;
    previousPage: () => void;
    pageOptions: number[];
}

const Pagination: React.FC<PaginationProps> = ({
    canPreviousPage,
    canNextPage,
    gotoPage,
    nextPage,
    pageIndex,
    previousPage,
    pageOptions,
}) => {
    return (
        <div className="pagination flex justify-center" style={{ marginTop: '40px', border: '1px solid #ddd', padding: '10px' }}>
            <button
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
                style={{ marginRight: '10px' }}
            >
                {'<'}
            </button>
            <button
                onClick={() => nextPage()}
                disabled={!canNextPage}
                style={{ marginLeft: '10px' }}
            >
                {'>'}
            </button>
            <span style={{ marginLeft: '20px', marginRight: '10px' }}>
                Page <strong>{pageIndex + 1} of {pageOptions.length}</strong>{' '}
            </span>
            {pageOptions.length > 1 && (
                <span>
                    | Go to page:{' '}
                    <input
                        type="number"
                        defaultValue={pageIndex + 1}
                        onChange={(e) => {
                            const page = e.target.value ? Number(e.target.value) - 1 : 0;
                            if (page < 0) {
                                gotoPage(0);
                            } else if (page >= pageOptions.length) {
                                gotoPage(pageOptions.length - 1);
                            } else {
                                gotoPage(page);
                            }
                        }}
                        min="1"
                        max={pageOptions.length}
                        style={{ width: '50px', marginLeft: '10px' }}
                    />
                </span>
            )}
        </div>
    );
};

export default Pagination;