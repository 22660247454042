// ApplicantCompanyInfoModal.tsx

import React from 'react';
import Modal from 'react-modal';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { HistoryNotificationInfoModalProps } from '../../interfaces/Notification';
import NotificationForm from '../Form/NotificationForm';


const HistoryNotificationInfoModal: React.FC<HistoryNotificationInfoModalProps> = ({
    rowData,
    isOpen,
    onRequestClose,
    opt,
}) => {

    // Function to handle closing the modal without saving
    const handleCloseWithoutSaving = () => {
        onRequestClose(false);
    };

    return (
        <TransitionGroup component={null}>
            {isOpen && (
                <CSSTransition classNames="modal-transition" timeout={300}>
                    <Modal
                        isOpen={isOpen}
                        onRequestClose={() => handleCloseWithoutSaving()}
                        contentLabel="Company Info Modal"
                        className="bg-white rounded-md p-8 mx-auto mt-24 modal-content"
                        overlayClassName="fixed inset-0 bg-black bg-opacity-40 flex items-center justify-center modal-overlay"
                    >
                        <NotificationForm
                            data={rowData}
                            onRequestClose={onRequestClose}
                            opt={opt}
                            onSubmit={() => { }}
                        />
                    </Modal>
                </CSSTransition>
            )}
        </TransitionGroup>
    );
};

export default HistoryNotificationInfoModal;

