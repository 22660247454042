// BaseManagers.ts

export const MANAGERS_BASE = '/managers';
// COMPANIES APPLICANT
export const COMPANIES_APPLICANT_BASE = `${MANAGERS_BASE}/companies-applicant`;
// COMPANIES
export const COMPANIES_BASE = `${MANAGERS_BASE}/companies`;
export const COMPANIES_USER_BASE = `${COMPANIES_BASE}/user`;
// NOTIFICATION
export const NOTIFICATION_BASE = `${MANAGERS_BASE}/notification`;
// HINTS
export const HINTS_BASE = `${MANAGERS_BASE}/hints`;