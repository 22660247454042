// apiCall.ts

import { useCallback } from "react";
import { ApiCallFunction } from "../interfaces/hooks";
import Keycloak from "keycloak-js";

const useApiCall = (keycloak: Keycloak): ApiCallFunction => {
  const apiCall = useCallback<ApiCallFunction>(
    async (url, method, body, config = {}) => {
      try {
        const response = await fetch(url, {
          method,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${keycloak.token}`,
            Accept: "application/json",
            ...config.headers,
          },
          body: body ? JSON.stringify(body) : undefined,
          ...config,
        });

        if (!response.ok) {
          throw new Error("Failed to process the request");
        }
        return await response.json(); // Parse the response as JSON
      } catch (error) {
        throw error;
      }
    },
    [keycloak]
  );

  return apiCall;
};

export default useApiCall;
