// Footer.tsx

import React from "react";
import {
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaLinkedinIn,
} from "react-icons/fa";
import FooterLink from "./FooterLink";
import SocialIcon from "./SocialIcon";
import "./Footer.css";

const Footer: React.FC = () => {
  const footerLinks = [
    // { name: 'Terms of Service', to: '/terms' },
    // { name: 'FAQ', to: '/faq' },
    // { name: 'Help', to: '/help' },
    // { name: 'Contact', to: '/contact' },
    { name: "About", to: "/about" },
  ];

  const chunkArray = (arr: any, chunkSize: any) => {
    const result = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      result.push(arr.slice(i, i + chunkSize));
    }
    return result;
  };

  const footerLinksColumns = chunkArray(footerLinks, 3);

  return (
    <footer className="bg-gray-800 text-white py-4 md:py-8 mt-auto">
      {/* <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
          <div>
            <h3 className="text-xl font-bold mb-1">BuildTech Company</h3>
            <p className="text-gray-400 text-xs">
              123 Main St, Anytown USA 12345
            </p>
            <p className="text-gray-400 text-xs">+1-555-555-1234</p>
            <p className="text-gray-400 text-xs">info@company.com</p>
          </div>
          <div>
            <h3 className="text-xl font-bold mb-1">Quick Links</h3>
            <ul>
              {footerLinksColumns[0].map((link: any, index: any) => (
                <FooterLink key={index} name={link.name} to={link.to} />
              ))}
            </ul>
          </div>
          {footerLinksColumns.slice(1).map((column, colIdx) => (
            <div key={colIdx}>
              <ul className="mt-6">
                {column.map((link: any, index: any) => (
                  <FooterLink key={index} name={link.name} to={link.to} />
                ))}
              </ul>
            </div>
          ))}
          <div className="flex flex-col items-start md:items-end">
            <h3 className="text-xl font-bold mb-1">Follow Us</h3>
            <div className="flex space-x-4">
              <SocialIcon
                icon={<FaFacebookF />}
                href="https://www.facebook.com"
              />
              <SocialIcon icon={<FaTwitter />} href="https://www.twitter.com" />
              <SocialIcon
                icon={<FaInstagram />}
                href="https://www.instagram.com"
              />
              <SocialIcon
                icon={<FaLinkedinIn />}
                href="https://www.linkedin.com"
              />
            </div>
          </div>
        </div>
      </div> */}
      <div className="mt-4 border-t border-gray-700 pt-3 text-center text-gray-400 text-xs">
        <p>
          &copy; {new Date().getFullYear()} BuildTech Company. All rights
          reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
