// SelectField.tsx

// SelectField.tsx

import React from 'react';

interface Option {
    value: string;
    label: string;
}

interface SelectFieldProps {
    label: string;
    value?: string;
    options: Option[];
    onChange: (value: string) => void;
    readOnly?: boolean;
}

const SelectField: React.FC<SelectFieldProps> = ({ label, value, options, onChange, readOnly }) => {
    // call the onChange function when the select value changes
    const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        if (!readOnly) {
            onChange(e.target.value);
        }
    };

    // Render select options in the order they appear in the options array
    const renderOptions = () =>
        options.map((option, index) => (
            <option key={index} value={option.value}>
                {option.label}
            </option>
        ));

    const styles = {
        select: {
            backgroundColor: readOnly ? '#f0f0f0' : 'white', // Change the background color based on the 'readOnly' prop
            borderColor: readOnly ? '#ccc' : '#007bff', // Change the borderColor based on the 'readOnly' prop
        },
    } as any;

    return (
        <div className="mb-4">
            <label htmlFor={label} className="block mb-2 font-bold">
                {label}
            </label>
            <select
                id={label}
                value={value}
                onChange={handleChange}
                className="border border-gray-300 px-3 py-2 rounded"
                disabled={readOnly}
                style={styles.select}
                onFocus={(e) => (e.target.style.borderColor = styles.select.borderColor)}
                onBlur={(e) => {
                    e.target.style.borderColor = readOnly ? '#ccc' : '#007bff';
                }}
            >
                {renderOptions()}
            </select>
        </div>
    );
};

export default SelectField;
