// CompaniesApplicantApi.ts

import { BASE_URL } from "../../env";
import { COMPANIES_APPLICANT_BASE } from "./BaseManagers";

export const CompaniesApplicantApi = {
  Insert: {
    // Add new applicant company
    url: `${BASE_URL}${COMPANIES_APPLICANT_BASE}`,
    method: "POST",
  },
  Update: {
    // Update inactive applicant company
    url: `${BASE_URL}${COMPANIES_APPLICANT_BASE}`,
    method: "PATCH",
  },
  Delete: {
    // Delete inactive applicant company from the collection
    url: `${BASE_URL}${COMPANIES_APPLICANT_BASE}/`,
    method: "DELETE",
  },
  Get: {
    // Get applicant companies data using params for specific data
    url: `${BASE_URL}${COMPANIES_APPLICANT_BASE}`,
    method: "GET",
  },
};
