// HistoryNotification.tsx

import React from "react";
import {
  useTable,
  useFilters,
  useSortBy,
  Column,
  // Row,
  useGlobalFilter,
  usePagination,
} from "react-table";
// import Modal from 'react-modal';
import LoadingSpinner from "../../../../components/LoadingSpinner";
import { TableBody } from "../../../../components/tables/TableBody";
import Pagination from "../../../../components/tables/Pagination";
import { useKeycloak } from "@react-keycloak/web";
import PageHeader from "../../../../components/PageHeader";
import { TableHeadWithNoSelectAll } from "../../../../components/tables/TableHeadWithNoSelectAll";
import "./HistoryNotification.css";
import useApiCall from "../../../../hooks/apiCall";
import useFetchHistoryNotification from "../../../../hooks/useFetchHistoryNotification";
import { useHistoryNotificationActions } from "../../../../hooks/useHistoryNotificationActions";
import { NotificationHistoryProps } from "../../../../interfaces/Notification";
import HistoryNotificationInfoModal from "../../../../components/HistoryNotification/HistoryNotificationInfoModal";
import FilterInputs from "../../../../components/tables/FilterInputs";
import { TableBodyTouch } from "../../../../components/tables/TableBodyTouch";
import {
  IS_ENABLE_SELECT_ALL,
  IS_LINE_CLICKABLE,
  MAX_NUM_OF_ROWS,
} from "../../../../config/env";

const HistoryNotification: React.FC = () => {
  // Get the Keycloak instance
  const { keycloak } = useKeycloak();

  // Get the apiCall hook
  const apiCall = useApiCall(keycloak);

  const {
    historyNotificationData,
    setHistoryNotificationData,
    selectedHistoryNotification,
    // setSelectedHistoryNotification,
    isLoading,
    setIsLoading,
    setSelectedRow,
    handleRowDoubleClick,
    modalHistoryNotificationOpen,
    setModalHistoryNotificationOpen,
    // startTime,
    setStartTime,
    // endTime,
    setEndTime,
    filterByTimestamp,
    handleCompanyFilterChange,
    handleTitleFilterChange,
  } = useHistoryNotificationActions(apiCall);

  useFetchHistoryNotification({
    setHistoryNotificationData,
    setIsLoading,
    apiCall,
  });

  // Define table columns
  const columns: Column[] = React.useMemo(
    () => [
      {
        Header: "Time Stamp",
        accessor: "TimeStamp",
        sortType: "basic",
      },
      {
        Header: "CompanyId",
        accessor: "CompanyId",
        sortType: "basic",
      },
      {
        Header: "Success",
        accessor: "Success",
        sortType: "basic",
        Cell: React.memo(({ value }: { value: boolean }) => (
          <div>{value ? "Yes" : "No"}</div>
        )),
      },
      {
        Header: "Type",
        accessor: "Type",
        sortType: "basic",
      },
      {
        Header: "Title",
        accessor: "Title",
        sortType: "basic",
      },
      {
        Header: "Message",
        accessor: "Message",
        sortType: "basic",
      },
      {
        Header: "Url",
        accessor: "Url",
        sortType: "basic",
      },
      {
        Header: "Body",
        accessor: "Body",
        sortType: "basic",
      },
      {
        Header: "Priority",
        accessor: "Priority",
        sortType: "basic",
      },
      {
        Header: "PlatformOsList",
        accessor: "PlatformOsList",
        sortType: "basic",
        Cell: React.memo(({ value }: { value: string[] }) => (
          <div>{value.join(", ")}</div>
        )),
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setFilter,
    setGlobalFilter,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    state: { pageIndex },
  } = useTable<NotificationHistoryProps>(
    {
      columns,
      data: React.useMemo(
        () => filterByTimestamp(historyNotificationData),
        [filterByTimestamp, historyNotificationData]
      ),
      initialState: { pageIndex: 0, pageSize: MAX_NUM_OF_ROWS },
      filterTypes: {
        // phoneFilter: phoneFilter,
      },
    } as any,
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  ) as any;

  console.log("HistoryNotification");
  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <>
      <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
        <div
          style={{ display: "flex", flexDirection: "column" }}
          className="screen-size-limit"
        >
          <PageHeader
            title="History Notification"
            className="text-3xl font-bold mb-8"
          />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <FilterInputs
              setGlobalFilter={setGlobalFilter}
              handleCompanyFilterChange={handleCompanyFilterChange(setFilter)}
              handleTitleFilterChange={handleTitleFilterChange(setFilter)}
              handleStartTimeFilterChange={(e) => setStartTime(e.target.value)}
              handleEndTimeFilterChange={(e) => setEndTime(e.target.value)}
            />
          </div>
          <div style={{ flexGrow: 1, width: "100%" }}>
            <table {...getTableProps()} className="w-full">
              <TableHeadWithNoSelectAll
                headerGroups={headerGroups}
                selectionColumnId={"selection"}
                isEnableSelectAll={IS_ENABLE_SELECT_ALL}
              />
              {IS_LINE_CLICKABLE ? (
                <TableBodyTouch
                  rows={rows}
                  prepareRow={prepareRow}
                  getTableBodyProps={getTableBodyProps}
                  page={page}
                  setSelectedRow={setSelectedRow}
                  onDoubleClick={handleRowDoubleClick}
                />
              ) : (
                <TableBody
                  rows={rows}
                  prepareRow={prepareRow}
                  getTableBodyProps={getTableBodyProps}
                  page={page}
                />
              )}
            </table>
          </div>
        </div>
      </div>
      <Pagination
        pageIndex={pageIndex}
        previousPage={previousPage}
        nextPage={nextPage}
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        pageOptions={pageOptions}
        gotoPage={gotoPage}
      />
      <HistoryNotificationInfoModal
        rowData={selectedHistoryNotification}
        isOpen={modalHistoryNotificationOpen}
        onRequestClose={setModalHistoryNotificationOpen}
        handleSubmit={() => {}}
        keycloak={keycloak}
        opt="none"
      />
    </>
  );
};

export default HistoryNotification;

console.log("HistoryNotification.tsx");
