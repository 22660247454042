// components/Home/CallToAction.tsx

import React from "react";
import { motion, AnimatePresence, MotionConfig } from "framer-motion";

const animationVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0 },
};

const CallToAction: React.FC = () => {
  return (
    <section className="bg-gradient-to-r from-blue-500 via-indigo-500 to-purple-500 py-12">
      <MotionConfig>
        <AnimatePresence mode="wait">
          <motion.div
            className="container mx-auto px-4 sm:px-6 lg:px-8 text-center"
            initial="hidden"
            animate="visible"
            exit="hidden"
            variants={animationVariants}
            transition={{ duration: 0.5 }}
          >
            <h2 className="text-3xl font-bold text-white mb-6">
              Transform Your Workflows with BuildTech
            </h2>
            <p className="text-white text-lg mb-8">
              Dive into the future of construction and beyond with BuildTech's
              AI-driven platform. From creating smart reports enriched with
              industry-specific insights to fostering unparalleled team
              collaboration and enriching workplace interactions, our platform
              is designed to elevate your project's efficiency and
              effectiveness. Experience a new era of productivity and
              innovation, ensuring every report is a step towards success, and
              every collaboration is a bridge to achievement.
            </p>
            {/* <motion.button
              className="bg-white text-indigo-700 font-bold px-8 py-3 rounded-lg hover:bg-indigo-200 transition-colors duration-300"
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
            >
              Get Started
            </motion.button> */}
          </motion.div>
        </AnimatePresence>
      </MotionConfig>
    </section>
  );
};

export default CallToAction;
