// ApplicantCompanies.tsx

import React from "react";
import {
  useTable,
  useFilters,
  useSortBy,
  Column,
  // Row,
  useGlobalFilter,
  usePagination,
} from "react-table";
// import Modal from 'react-modal';
import LoadingSpinner from "../../../../components/LoadingSpinner";
import { TableBody } from "../../../../components/tables/TableBody";
import Pagination from "../../../../components/tables/Pagination";
import { useKeycloak } from "@react-keycloak/web";
import ApplicantCompanyInfoModal from "../../../../components/tables/CompaniesTable/ApplicantCompanyInfoModal";
import PageHeader from "../../../../components/PageHeader";
import { TableHeadWithNoSelectAll } from "../../../../components/tables/TableHeadWithNoSelectAll";
import CustomButton from "../../../../components/CustomButton";
import CompanyInfoModal from "../../../../components/tables/CompaniesTable/CompanyInfoModal";
import ConfirmationModal from "../../../../components/ConfirmationModal";
import "./ApplicantCompanies.css";
import { useApplicantCompanyActions } from "../../../../hooks/useApplicantCompanyActions";
import useApiCall from "../../../../hooks/apiCall";
import useFetchApplicantCompanies from "../../../../hooks/useFetchApplicantCompanies";
import { CompanyApplicant } from "../../../../interfaces/CompanyApplicant";
import { TableBodyTouch } from "../../../../components/tables/TableBodyTouch";
import FilterInputs from "../../../../components/tables/FilterInputs";
import {
  IS_ENABLE_SELECT_ALL,
  IS_LINE_CLICKABLE,
  MAX_NUM_OF_ROWS,
} from "../../../../config/env";

const ApplicantCompanies = () => {
  // Get the Keycloak instance
  const { keycloak } = useKeycloak();

  // Get the apiCall hook
  const apiCall = useApiCall(keycloak);

  const {
    selectedCompany,
    setSelectedCompany,
    isLoading,
    setIsLoading,
    // selectedRow,
    setSelectedRow,
    // selectedRows,
    // setSelectedRows,
    applicantCompanyData,
    setApplicantCompanyData,
    selectedApplicantCompany,
    // setSelectedApplicantCompany,
    updateModalApplicantCompaniesOpen,
    setUpdateModalApplicantCompaniesOpen,
    deleteApplicantCompanyOpen,
    setDeleteApplicantCompanyOpen,
    addCompanyOpen,
    setAddCompanyOpen,
    insertModalApplicantCompaniesOpen,
    setInsertModalApplicantCompaniesOpen,
    //
    handleRowDoubleClick,
    handleAddCompanyOpen,
    // handleAddCompanyClose,
    handleDeleteApplicantCompany,
    handleAddCompany,
    handleUpdateApplicantCompany,
    handleAddApplicantCompany,
  } = useApplicantCompanyActions(apiCall);

  // Define table columns
  const columns: Column<CompanyApplicant>[] = React.useMemo(
    () => [
      {
        Header: "Time Stamp",
        accessor: "TimeStamp",
        sortType: "basic",
      },
      {
        Header: "CompanyId",
        accessor: "CompanyId",
        sortType: "basic",
      },
      {
        Header: "Contact Phone Number",
        accessor: "ContactPhoneNumber",
        sortType: "basic",
      },
      {
        Header: "Contact Email",
        accessor: "ContactEmail",
        sortType: "basic",
      },
      {
        Header: "Active",
        accessor: "IsActive",
        sortType: "basic",
        Cell: ({ value }: { value: boolean }) => (
          <div>{value ? "Yes" : "No"}</div>
        ),
      },
      {
        Header: "Operation",
        id: "operation",
        Cell: ({ row }: { row: any }) => {
          return (
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CustomButton
                  disabled={row?.values?.IsActive}
                  onClick={() => handleAddCompanyOpen(row.original)}
                  type="add"
                  // color='green'
                >
                  Add
                </CustomButton>
                <CustomButton
                  disabled={row?.values?.IsActive}
                  onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                    e.stopPropagation();
                    setSelectedCompany(row.original);
                    setDeleteApplicantCompanyOpen(true);
                  }}
                  type="delete"
                  // color='red'
                >
                  Delete
                </CustomButton>
              </div>
            </>
          );
        },
      },
    ],
    [handleAddCompanyOpen, setDeleteApplicantCompanyOpen, setSelectedCompany]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setFilter,
    setGlobalFilter,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    state: { pageIndex },
  } = useTable<CompanyApplicant>(
    {
      columns,
      data: applicantCompanyData,
      initialState: { pageIndex: 0, pageSize: MAX_NUM_OF_ROWS },
      filterTypes: {
        // phoneFilter: phoneFilter,
      },
    } as any,
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  ) as any;

  const handleCompanyFilterChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const target = event.target as HTMLInputElement;
    const { value } = target;
    setFilter("CompanyId", value);
  };

  const handleEmailFilterChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const target = event.target as HTMLInputElement;
    const { value } = target;
    setFilter("ContactEmail", value);
  };

  useFetchApplicantCompanies({
    applicantCompanyData,
    setApplicantCompanyData,
    setIsLoading,
    apiCall,
    keycloak,
  });

  // debug
  console.log("ApplicantCompanies");
  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <>
      <div className="table-container">
        <div className="table-content">
          <PageHeader
            title="Applicant Companies"
            className="text-3xl font-bold mb-8"
          />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <FilterInputs
              setGlobalFilter={setGlobalFilter}
              handleCompanyFilterChange={handleCompanyFilterChange}
              handleEmailFilterChange={handleEmailFilterChange}
            />
            <CustomButton
              disabled={isLoading}
              onClick={() => setInsertModalApplicantCompaniesOpen(true)}
              type="add"
              // color="green"
              // style={{ marginLeft: '400px' }}
            >
              Add Applicant Companies
            </CustomButton>
          </div>
          <div style={{ flexGrow: 1, width: "100%" }}>
            <table {...getTableProps()} className="w-full">
              <TableHeadWithNoSelectAll
                headerGroups={headerGroups}
                selectionColumnId={"selection"}
                isEnableSelectAll={IS_ENABLE_SELECT_ALL}
              />
              {IS_LINE_CLICKABLE ? (
                <TableBodyTouch
                  rows={rows}
                  prepareRow={prepareRow}
                  getTableBodyProps={getTableBodyProps}
                  page={page}
                  setSelectedRow={setSelectedRow}
                  onDoubleClick={handleRowDoubleClick}
                />
              ) : (
                <TableBody
                  rows={rows}
                  prepareRow={prepareRow}
                  getTableBodyProps={getTableBodyProps}
                  page={page}
                />
              )}
            </table>
          </div>
        </div>
      </div>
      <Pagination
        pageIndex={pageIndex}
        previousPage={previousPage}
        nextPage={nextPage}
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        pageOptions={pageOptions}
        gotoPage={gotoPage}
      />
      <ApplicantCompanyInfoModal
        companyApplicant={selectedApplicantCompany}
        isOpen={updateModalApplicantCompaniesOpen}
        onRequestClose={() => setUpdateModalApplicantCompaniesOpen(false)}
        opt="update"
        handleSubmit={(updateApplicantCompany) =>
          handleUpdateApplicantCompany(updateApplicantCompany)
        }
      />
      <ApplicantCompanyInfoModal
        companyApplicant={undefined}
        isOpen={insertModalApplicantCompaniesOpen}
        onRequestClose={() => setInsertModalApplicantCompaniesOpen(false)}
        opt="insert"
        handleSubmit={(updateApplicantCompany) =>
          handleAddApplicantCompany(updateApplicantCompany)
        }
      />
      <ConfirmationModal
        isOpen={deleteApplicantCompanyOpen}
        onRequestClose={() => setDeleteApplicantCompanyOpen(false)}
        title="Delete Selected Companies"
        message={`Are you sure you want to delete the Applicant Company '${selectedCompany?.CompanyId}'?`}
        onCancel={() => setDeleteApplicantCompanyOpen(false)}
        onConfirm={handleDeleteApplicantCompany}
      />
      <CompanyInfoModal
        company={selectedCompany}
        isOpen={addCompanyOpen}
        onRequestClose={() => setAddCompanyOpen(false)}
        opt="insert"
        handleSubmit={handleAddCompany}
      />
    </>
  );
};

export default ApplicantCompanies;

console.log("ApplicantCompanies.tsx");
