// CompaniesApi.ts

import { BASE_URL } from "../../env";
import { COMPANIES_BASE, COMPANIES_USER_BASE } from "./BaseManagers";

export const CompaniesApi = {
  Insert: {
    // Add new company
    url: `${BASE_URL}${COMPANIES_BASE}`,
    method: "POST",
  },
  Update: {
    // Enable/disable the company or add some data to it
    url: `${BASE_URL}${COMPANIES_BASE}`,
    method: "PATCH",
  },
  Delete: {
    // Delete company from the service
    url: `${BASE_URL}${COMPANIES_BASE}/`,
    method: "DELETE",
  },
  Get: {
    // Get companies data using params for specific data
    url: `${BASE_URL}${COMPANIES_BASE}`,
    method: "GET",
  },
  User: {
    Insert: {
      // Create a user in a specific company
      url: `${BASE_URL}${COMPANIES_USER_BASE}`,
      method: "POST",
    },
    Update: {
      // Create or update a user in a specific company
      url: `${BASE_URL}${COMPANIES_USER_BASE}`,
      method: "PATCH",
    },
    Delete: {
      // Delete a user in a specific company
      url: `${BASE_URL}${COMPANIES_USER_BASE}`,
      method: "DELETE",
    },
    Get: {
      // Get user data using the company record, filter by params
      url: `${BASE_URL}${COMPANIES_USER_BASE}`,
      method: "GET",
    },
  },
};
