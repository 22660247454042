// SearchBar.tsx

import { useState, useCallback } from 'react';
import { CSSTransition } from 'react-transition-group';
import './SearchBar.css';
import CustomButton from '../CustomButton';

interface CompanySearchBarProps {
    onSearch: (companyName: string) => void;
    placeholder?: string;
}

const SearchBar: React.FC<CompanySearchBarProps> = ({
    onSearch,
    placeholder,
}) => {
    // State to manage the search input text
    const [searchText, setSearchText] = useState('');

    // State to manage whether the input field is focused or not
    const [isInputFocused, setIsInputFocused] = useState(false);

    // Handler for search form submission
    const handleSearchSubmit = useCallback((e: React.FormEvent) => {
        e.preventDefault();
        onSearch(searchText);
    }, [onSearch, searchText]);

    // Handler for reset button click
    const handleResetClick = useCallback(() => {
        setSearchText('');
    }, []);

    return (
        <div className="searchbar-container">
            <form onSubmit={handleSearchSubmit} className="searchbar-form">
                {/* Search icon animation when the input field is focused */}
                <CSSTransition in={isInputFocused} timeout={200} classNames="search-icon" unmountOnExit>
                    <i className="fas fa-search search-icon" />
                </CSSTransition>
                {/* Input field for search */}
                <input
                    type="text"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    onFocus={() => setIsInputFocused(true)}
                    onBlur={() => setIsInputFocused(false)}
                    className="searchbar-input"
                    placeholder={placeholder}
                />
                {/* Reset button to clear the search input */}
                {searchText && (
                    <div className="searchbar-reset">
                        <CustomButton
                            type="reset"
                            color="transparent"
                            onClick={handleResetClick}
                        >
                            <i className="fas fa-times" />
                        </CustomButton>
                    </div>
                )}
                {/* Search button to submit the form */}
                <CustomButton
                    type="search"
                    onClick={handleSearchSubmit}
                >
                    Search
                </CustomButton>
            </form>
        </div>
    );
};

export default SearchBar;
