// FilterInputs.tsx

import React from 'react';
import './FilterInputs.css';
import { FaClock, FaSearch } from 'react-icons/fa';

interface FiltersProps {
    setGlobalFilter: (filterValue: any) => void;
    handleCompanyFilterChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleEmailFilterChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handlePhoneFilterChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    handleStartTimeFilterChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleEndTimeFilterChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleTitleFilterChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    handleHintIdFilterChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const FilterInputs: React.FC<FiltersProps> = ({
    handleCompanyFilterChange,
    handleEmailFilterChange,
    handlePhoneFilterChange,
    handleStartTimeFilterChange,
    handleEndTimeFilterChange,
    handleTitleFilterChange,
    handleHintIdFilterChange,
}) => {
    return (
        <div className="my-4 flex justify-start items-center">
            {/* Time Filters */}
            {/* <div className="input-container"> */}
            {handleStartTimeFilterChange && (
                <div className="input-container">
                    <input
                        type="datetime-local"
                        name="StartTime"
                        className="border border-gray-300 p-2 rounded-lg"
                        onChange={handleStartTimeFilterChange}
                    />
                    <span className="input-label">
                        <FaClock /> Start Time
                    </span>
                </div>
            )}
            {handleEndTimeFilterChange && (
                <div className="input-container">
                    <input
                        type="datetime-local"
                        name="EndTime"
                        className="border border-gray-300 p-2 rounded-lg"
                        onChange={handleEndTimeFilterChange}
                    />
                    <span className="input-label">
                        <FaClock /> End Time
                    </span>
                </div>
            )}
            {/* </div> */}
            {/* Text Filters */}
            {handleCompanyFilterChange && (
                <div className="input-container">
                    <input
                        type="text"
                        name="Company"
                        placeholder="Search by company"
                        className="w-60 border-gray-300 border-2 rounded-md p-1"
                        onChange={handleCompanyFilterChange}
                    />
                    <span className="input-label">
                        <FaSearch />
                    </span>
                </div>
            )
            }
            {handleEmailFilterChange && (
                <div className="input-container">
                    <input
                        type="text"
                        name="ContactEmail"
                        placeholder="Search by email"
                        className="w-60 border-gray-300 border-2 rounded-md p-1"
                        onChange={handleEmailFilterChange}
                    />
                    <span className="input-label">
                        <FaSearch />
                    </span>
                </div>
            )
            }
            {handleHintIdFilterChange && (
                <div className="input-container">
                    <input
                        type="text"
                        name="HintID"
                        placeholder="Search by Hint ID"
                        className="w-60 border-gray-300 border-2 rounded-md p-1"
                        onChange={handleHintIdFilterChange}
                    />
                    <span className="input-label">
                        <FaSearch />
                    </span>
                </div>
            )
            }
            {handlePhoneFilterChange && (
                <div className="input-container">
                    <input
                        type="text"
                        name="phoneNumbers"
                        placeholder="Search by phone number"
                        className="w-60 border-gray-300 border-2 rounded-md p-1"
                        onChange={handlePhoneFilterChange}
                    />
                    <span className="input-label">
                        <FaSearch />
                    </span>
                </div>
            )
            }
            {handleTitleFilterChange && (
                <div className="input-container">
                    <input
                        type="text"
                        name="Title"
                        placeholder="Search by Title"
                        className="w-60 border-gray-300 border-2 rounded-md p-1"
                        onChange={handleTitleFilterChange}
                    />
                    <span className="input-label">
                        <FaSearch />
                    </span>
                </div>
            )
            }
        </div >
    );
};

export default FilterInputs;