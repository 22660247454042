// useFetchCompaniesName.ts

import { useCallback, useEffect } from "react";
import { ApiCallFunction } from "../interfaces/hooks";
import { CompaniesApi } from "../config/api/managers/CompaniesApi";

interface useFetchCompaniesNameProps {
  setCompanyOptions: any;
  setIsLoading: (loading: boolean) => void;
  apiCall: ApiCallFunction;
}

const useFetchCompaniesName = ({
  setCompanyOptions,
  setIsLoading,
  apiCall,
}: useFetchCompaniesNameProps) => {
  const fetchData = useCallback(async () => {
    try {
      // Set the loading state to true
      setIsLoading(true);

      // Fetch the data
      const jsonData = await apiCall(
        CompaniesApi.Get.url,
        CompaniesApi.Get.method,
        undefined,
        {}
      );

      // Create company options from the parsed JSON data
      const companyOptions = jsonData.map((company: any) => ({
        value: company.CompanyId,
        label: company.CompanyId,
      }));

      // Update the company options
      setCompanyOptions(companyOptions);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      // Set the loading state to false
      setIsLoading(false);
    }
  }, [apiCall, setCompanyOptions, setIsLoading]);

  useEffect(() => {
    console.log("useFetchCompaniesName.ts useEffect()");
    // Set the loading state to true
    setIsLoading(true);

    // Execute the fetchData function
    fetchData();
  }, [fetchData, setIsLoading]);
};

export default useFetchCompaniesName;
