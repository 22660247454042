// AddCompany.tsx

import 'react-toastify/dist/ReactToastify.css';
// import './Dashboard.css';
import { useKeycloak } from '@react-keycloak/web';
import CompanyForm from '../../../../components/Form/CompanyForm';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import PageHeader from '../../../../components/PageHeader';
import { useCompanyActions } from '../../../../hooks/useCompanyActions';
import useApiCall from '../../../../hooks/apiCall';

const AddCompany = () => {
    // Get the Keycloak instance
    const { keycloak } = useKeycloak();

    // Get the apiCall hook
    const apiCall = useApiCall(keycloak);

    // Get the useCompanyActions hook
    const {
        isLoading,
        handleInsertSubmit,
    } = useCompanyActions(apiCall, true);


    console.log('AddCompany');
    return (
        <>
            {isLoading ? (
                <LoadingSpinner />
            ) : (
                <div className="container mx-auto mt-10">
                    <PageHeader title="Add Company" className="text-2xl font-semibold mb-4" />
                    <CompanyForm
                        onSubmit={handleInsertSubmit}
                        opt='page-insert'
                        onRequestClose={() => { }} />
                </div>
            )
            }
        </>
    );
}

export default AddCompany;
