// TableHead.tsx

import React from 'react';

interface TableHeadProps {
    headerGroups: any[];
    onSelectAll: (checked: boolean) => void;
    allRowsSelected: boolean;
    selectionColumnId: string;
    isEnableSelectAll: boolean;
}

export const TableHead: React.FC<TableHeadProps> = ({
    headerGroups,
    onSelectAll,
    allRowsSelected,
    selectionColumnId,
    isEnableSelectAll,
}) => {
    const handleSelectAllChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onSelectAll(event.target.checked);
    };

    return (
        <thead>
            {headerGroups.map((headerGroup: any) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column: any) => (
                        <th
                            {...column.getHeaderProps(column.getSortByToggleProps())}
                            className="text-left py-2 px-3 border-b-2 border-gray-200 bg-gray-100 font-semibold text-sm"
                            style={{ textAlign: 'center' }} // add this inline style to center text
                        >
                            {column.render('Header')}
                            {column.id === selectionColumnId && isEnableSelectAll && (
                                <input
                                    type="checkbox"
                                    checked={allRowsSelected}
                                    onChange={handleSelectAllChange}
                                    style={{ marginLeft: '5px' }}
                                />
                            )}
                            <span>
                                {column.isSorted
                                    ? column.isSortedDesc
                                        ? ' 🔽'
                                        : ' 🔼'
                                    : ''}
                            </span>
                            {/* {column.id === 'phoneNumbers' && column.render('Filter')} */}
                        </th>
                    ))}
                </tr>
            ))}
        </thead>
    );
};