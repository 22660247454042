// TableHints.tsx

import React, { useEffect } from 'react';
import {
  useTable,
  useFilters,
  useSortBy,
  Column,
  useGlobalFilter,
  usePagination,
  Row,
} from 'react-table';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import { TableBody } from '../../../../components/tables/TableBody';
import Pagination from '../../../../components/tables/Pagination';
import { useKeycloak } from '@react-keycloak/web';
import PageHeader from '../../../../components/PageHeader';
// import { TableHeadWithNoSelectAll } from '../../../../components/tables/TableHeadWithNoSelectAll';
import './TableHints.css';
import useApiCall from '../../../../hooks/apiCall';
import useFetchHints from '../../../../hooks/useFetchHints';
import { HintProps, HintReviews } from '../../../../interfaces/Hint';
import FilterInputs from '../../../../components/tables/FilterInputs';
import { useHintsActions } from '../../../../hooks/useHintsActions';
import { TableBodyTouch } from '../../../../components/tables/TableBodyTouch';
import HintInfoModal from '../../../../components/tables/HintsTable/HintInfoModal';
import DeleteAllButton from '../../../../components/tables/DeleteAllButton';
import CustomButton from '../../../../components/CustomButton';
import { TableHead } from '../../../../components/tables/TableHead';
import { IS_ENABLE_SELECT_ALL, IS_LINE_CLICKABLE, MAX_NUM_OF_ROWS } from '../../../../config/env';


const TableHints: React.FC = () => {
  // Get the Keycloak instance
  const { keycloak } = useKeycloak();

  // Get the apiCall hook
  const apiCall = useApiCall(keycloak);

  const {
    hintsData,
    setHintsData,
    isLoading,
    setIsLoading,
    selectedRow,
    setSelectedRow,
    // startTime,
    setStartTime,
    // endTime,
    setEndTime,
    updateModalHintOpen,
    setUpdateModalHintOpen,
    insertModalHintOpen,
    setInsertModalHintOpen,
    selectedRows,
    setSelectedRows,
    refreshCounter,
    // setRefreshCounter,
    // selectAllChecked,
    setSelectAllChecked,
    // 
    handleRowDoubleClick,
    filterByTimestamp,
    handlePhoneFilterChange,
    handleHintIdFilterChange,
    handleUpdateSubmit,
    handleDeleteSubmit,
    handleInsertSubmit,
    handleSelectAll,
    selectAllChecked,
    // handleCompanyFilterChange,
    // handleTitleFilterChange,
  } = useHintsActions(apiCall, false);

  // Get the Hint data
  useFetchHints({ apiCall, setHintsData, setIsLoading, dependencies: refreshCounter });

  // Define table columns
  const columns: Column<HintProps>[] = React.useMemo(() => {
    const phoneFilter = (rows: Row<HintProps>[], id: string, filterValue: any) => {
      return rows.filter((row) => {
        const rowValue = row.values[id];
        return Object.keys(rowValue).some((phoneNumber: string) =>
          phoneNumber.toLowerCase().includes(filterValue.toLowerCase())
        );
      });
    };

    // Set autoRemove to true to remove the filter when the value is falsy
    phoneFilter.autoRemove = (val: any) => !val;

    return [
      {
        Header: 'Time Stamp',
        accessor: 'TimeStamp',
        sortType: 'basic'
      },
      {
        Header: 'Language',
        accessor: 'Language',
        sortType: 'basic'
      },
      {
        Header: 'Hint ID',
        accessor: 'HintId',
        sortType: 'basic'
      },
      {
        Header: 'Hint Data',
        accessor: 'HintData',
        sortType: 'basic',
      },
      {
        Header: 'Stars',
        accessor: 'Stars',
        sortType: 'basic',
      },
      {
        Header: 'Is Active',
        accessor: 'IsActive',
        sortType: 'basic',
        Cell: React.memo(({ value }: { value: boolean }) => (
          <div>{value ? "Yes" : "No"}</div>
        )),
      },
      {
        Header: 'Users Who Review',
        accessor: (row: HintProps) => row.HintReviews,
        Cell: ({ value }: { value: { [keyPhoneNumber: string]: HintReviews } }) => {
          const phoneNumbers = Object.keys(value).join(', ');
          return <div>{phoneNumbers}</div>;
        },
        id: 'HintReviews',
        filter: phoneFilter,
        sortType: 'basic',
      },
      {
        Header: 'Select',
        id: 'selection',
        Cell: ({ row }: { row: any }) => {
          return (
            <input
              type="checkbox"
              checked={selectedRows.includes(row.id)}
              onChange={() => {
                const newRowId = row.id;
                const newSelectedRows = selectedRows.includes(newRowId)
                  ? selectedRows.filter((id) => id !== newRowId)
                  : [...selectedRows, newRowId];

                setSelectedRows(newSelectedRows);
              }}
              onClick={(e: React.MouseEvent<HTMLInputElement>) => {
                e.stopPropagation();
              }}
            />
          );
        },
      },
    ];
  }, [selectedRows, setSelectedRows]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setFilter,
    setGlobalFilter,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    state: { pageIndex },
  } = useTable<HintProps>(
    {
      columns,
      data: React.useMemo(() => filterByTimestamp(hintsData), [filterByTimestamp, hintsData]),
      initialState: { pageIndex: 0, pageSize: MAX_NUM_OF_ROWS },
      filterTypes: {
        // phoneFilter: phoneFilter,
      }
    } as any,
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
  ) as any;

  // Reset selected rows when page changes
  useEffect(() => {
    console.log('useEffect-pageIndex');
    setSelectedRows([]);
    setSelectAllChecked(false)
  }, [setSelectAllChecked, setSelectedRows, refreshCounter, pageIndex]);


  console.log('TableHints');
  return (
    (isLoading) ? <LoadingSpinner /> :
      <>
        <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }} className="screen-size-limit">
            <PageHeader
              title="Hints"
              className="text-3xl font-bold mb-8"
            />
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
              <FilterInputs
                setGlobalFilter={setGlobalFilter}
                handleStartTimeFilterChange={(e) => setStartTime(e.target.value)}
                handleEndTimeFilterChange={(e) => setEndTime(e.target.value)}
                handleHintIdFilterChange={handleHintIdFilterChange(setFilter)}
                handlePhoneFilterChange={handlePhoneFilterChange(setFilter)}
              />
              <CustomButton
                disabled={isLoading}
                onClick={() => setInsertModalHintOpen(true)}
                type="add"
              // style={{ marginLeft: '400px' }}
              >
                Add Hint
              </CustomButton>
            </div>
            <div style={{ flexGrow: 1, width: '100%' }}>
              <table {...getTableProps()} className="w-full">
                <TableHead
                  headerGroups={headerGroups}
                  onSelectAll={handleSelectAll(page)}
                  allRowsSelected={selectAllChecked}
                  selectionColumnId={'selection'}
                  isEnableSelectAll={IS_ENABLE_SELECT_ALL}
                />
                {IS_LINE_CLICKABLE ?
                  <TableBodyTouch
                    rows={rows}
                    prepareRow={prepareRow}
                    getTableBodyProps={getTableBodyProps}
                    page={page}
                    setSelectedRow={setSelectedRow}
                    onDoubleClick={handleRowDoubleClick}
                  /> :
                  <TableBody
                    rows={rows}
                    prepareRow={prepareRow}
                    getTableBodyProps={getTableBodyProps}
                    page={page}
                  />
                }
              </table>
            </div>
            <div
              // className="float-right"
              style={{
                marginTop: '30px',
                display: 'flex',
                flexDirection: 'row',
                // alignItems: 'end',
                justifyContent: 'end'
              }}
            >
              <DeleteAllButton
                selectedRows={selectedRows}
                rows={rows}
                onSubmit={handleDeleteSubmit}
              />
            </div>
          </div>
        </div >
        <Pagination
          pageIndex={pageIndex}
          previousPage={previousPage}
          nextPage={nextPage}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          pageOptions={pageOptions}
          gotoPage={gotoPage}
        />
        <HintInfoModal
          rowData={selectedRow}
          isOpen={updateModalHintOpen}
          onRequestClose={setUpdateModalHintOpen}
          onSubmit={handleUpdateSubmit}
          opt="update"
        />
        <HintInfoModal
          rowData={undefined}
          isOpen={insertModalHintOpen}
          onRequestClose={setInsertModalHintOpen}
          onSubmit={handleInsertSubmit}
          opt="insert"
        />
      </>
  );
};

export default TableHints;