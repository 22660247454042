// ButtonType.ts

import { IconType } from "react-icons";
import * as AiIcons from "react-icons/ai";
import * as FaIcons from "react-icons/fa";
// import * as IoIcons from 'react-icons/io';

export const TypeIconMap: Record<string, IconType> = {
  submit: AiIcons.AiOutlineCheck,
  reset: AiIcons.AiOutlineClose,
  add: FaIcons.FaPlus,
  // remove: FaIcons.FaMinus,
  search: AiIcons.AiOutlineSearch,
  download: AiIcons.AiOutlineDownload,
  upload: AiIcons.AiOutlineUpload,
  edit: AiIcons.AiOutlineEdit,
  save: AiIcons.AiOutlineSave,
  delete: AiIcons.AiOutlineDelete,
  home: AiIcons.AiOutlineHome,
  email: AiIcons.AiOutlineMail,
  user: AiIcons.AiOutlineUser,
  info: AiIcons.AiOutlineInfoCircle,
  warning: AiIcons.AiOutlineWarning,
  help: AiIcons.AiOutlineQuestionCircle,
  back: AiIcons.AiOutlineArrowLeft,
  next: AiIcons.AiOutlineArrowRight,
  settings: AiIcons.AiOutlineSetting,
  cart: AiIcons.AiOutlineShoppingCart,
  notification: AiIcons.AiOutlineBell,
  logout: AiIcons.AiOutlineLogout,
  login: AiIcons.AiOutlineLogin,
  update: AiIcons.AiOutlineReload,
  cancel: AiIcons.AiOutlineStop,
  filter: AiIcons.AiOutlineFilter,
  close: AiIcons.AiOutlineCloseCircle,
  print: AiIcons.AiOutlinePrinter,
  share: AiIcons.AiOutlineShareAlt,
  copy: AiIcons.AiOutlineCopy,
  cut: AiIcons.AiOutlineScissor,
  paste: AiIcons.AiOutlineFileAdd,
  move: AiIcons.AiOutlineArrowsAlt,
  lock: AiIcons.AiOutlineLock,
  unlock: AiIcons.AiOutlineUnlock,
  calendar: AiIcons.AiOutlineCalendar,
  clock: AiIcons.AiOutlineClockCircle,
  time: AiIcons.AiOutlineFieldTime,
  location: AiIcons.AiOutlineEnvironment,
  globe: AiIcons.AiOutlineGlobal,
  link: AiIcons.AiOutlineLink,
  image: AiIcons.AiOutlinePicture,
  video: AiIcons.AiOutlineVideoCamera,
  play: AiIcons.AiOutlineCaretRight,
  pause: AiIcons.AiOutlinePauseCircle,
  stop: AiIcons.AiOutlinePause,
  previous: AiIcons.AiOutlineStepBackward,
  zoomIn: AiIcons.AiOutlineZoomIn,
  zoomOut: AiIcons.AiOutlineZoomOut,
  chat: AiIcons.AiOutlineMessage,
  phone: AiIcons.AiOutlinePhone,
  star: AiIcons.AiOutlineStar,
  document: AiIcons.AiOutlineFileText,
  unlink: AiIcons.AiOutlineLink,
  // next: AiIcons.AiOutlineForward,
  music: AiIcons.AiOutlineAudio,
  confirm: AiIcons.AiOutlineCheckCircle,
  remove: AiIcons.AiOutlineMinusCircle,
  change: AiIcons.AiOutlineSwap,
  button: AiIcons.AiOutlineAppstore,
};
