// ConfirmationModal.tsx

import React from 'react';
import Modal from 'react-modal';
import CustomButton from './CustomButton';

Modal.setAppElement('#root'); // Set app element for accessibility purposes


// ConfirmationModal component properties
interface ConfirmationModalProps {
    isOpen: boolean;
    onRequestClose: () => void;
    title: string;
    message: string;
    onCancel: () => void;
    onConfirm: () => void;
    confirmButtonText?: string;
    cancelButtonText?: string;
}

// Custom confirmation modal component
export const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
    isOpen,
    onRequestClose,
    title,
    message,
    onCancel,
    onConfirm,
    confirmButtonText = 'Confirm',
    cancelButtonText = 'Cancel',
}) => (
    <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        className="bg-white rounded-md p-8 mx-auto mt-24 max-w-md"
        overlayClassName="fixed inset-0 bg-black bg-opacity-40 flex items-center justify-center"
    >
        <h2 className="text-xl font-bold mb-4">{title}</h2>
        <p className="text-gray-600 mb-6">{message}</p>
        <div className="flex justify-between">
            <CustomButton
                type="confirm"
                onClick={onConfirm}
                // color="#33B000"
            >
                {confirmButtonText}
            </CustomButton>
            <CustomButton
                type="cancel"
                onClick={onCancel}
                // color="gray"
            >
                {cancelButtonText}
            </CustomButton>
        </div>
    </Modal>
);

export default ConfirmationModal;
