// components/Home/Features.tsx

import React from "react";
import { motion, AnimatePresence, MotionConfig } from "framer-motion";

const features = [
  {
    title: "Generating Smart Reports",
    description:
      "Facilitate the creation of professional, image-inclusive reports, leveraging AI for smart formatting suggestions and predictive text completion. Customize report layouts for a more personalized reporting experience.",
  },
  {
    title: "Accelerating Team Collaboration",
    description:
      "Revolutionize team collaboration with a controlled pipeline process, integrating seamlessly with Slack, Discord, and project management tools. Enable real-time collaborative editing and document sharing.",
  },
  {
    title: "Boosting Social Interactions",
    description:
      "Enhance social interaction between workers with in-app requests for shared meals, events, sports activities, and more. Promote team cohesion with features for organizing and tracking team building activities.",
  },
  {
    title: "Comprehensive In-app Currency and Storage Solutions",
    description:
      "Introduce an in-app currency system for transactions and rewards. Offer in-app storage with encoding, sorting, and AI auto-tagging for media, supporting collaborative editing and advanced search options.",
  },
];

const animationVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0 },
};

const Features: React.FC = () => {
  return (
    <section className="container mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <h2 className="text-2xl font-bold mb-8">Features</h2>
      <MotionConfig>
        <AnimatePresence mode="wait">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {features.map((feature, index) => (
              <motion.div
                key={index}
                className="flex items-start"
                initial="hidden"
                animate="visible"
                exit="hidden"
                variants={animationVariants}
                transition={{ duration: 0.5, delay: index * 0.1 }}
              >
                <div className="mr-4">
                  <span className="text-3xl font-semibold text-primary-500">
                    {index + 1}
                  </span>
                </div>
                <div>
                  <h3 className="text-xl font-semibold mb-2">
                    {feature.title}
                  </h3>
                  <p>{feature.description}</p>
                </div>
              </motion.div>
            ))}
          </div>
        </AnimatePresence>
      </MotionConfig>
    </section>
  );
};

export default Features;
