// NotificationApi.ts

import { BASE_URL } from "../../env";
import { NOTIFICATION_BASE } from "./BaseManagers";

export const NotificationApi = {
  Send: {
    // Send notification to all companies
    url: `${BASE_URL}${NOTIFICATION_BASE}`,
    method: "POST",
  },
  Get: {
    // Get notification history
    url: `${BASE_URL}${NOTIFICATION_BASE}`,
    method: "GET",
  },
};
