import logo from '../assets/images/brand/logo.png';


const Logo = () => {
  return (
    <div className="flex items-center space-y-0">
      <img className="h-24" src={logo} alt="BuildTech App logo" />
      <h1 className="text-blue font-semibold text-lg ml-2">BuildTech App</h1>
    </div>
  );
};

export default Logo;
