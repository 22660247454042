// AboutPage.tsx

import React from "react";
import PageHeader from "../../components/PageHeader";
import PageSection from "../../components/PageSection";
import TeamMember from "../../components/About/TeamMember";
import CEO_CTO from "../../assets/images/personals/uriel-almassy.jpg";
import CEO_CFO from "../../assets/images/personals/refael-naaman.jpg";

const AboutPage: React.FC = () => {
  const headerHeight = 80; // Replace with the actual header height
  const footerHeight = 80; // Replace with the actual footer height

  return (
    <div
      className="container mx-auto px-4 sm:px-6 lg:px-8 py-12"
      style={{
        backgroundImage: `url('https://source.unsplash.com/random/1600x900?about'`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        minHeight: `calc(100vh - ${headerHeight}px - ${footerHeight}px)`,
      }}
    >
      <div className="h-full relative">
        <div
          className="bg-white opacity-80 p-8 rounded-md h-full"
          style={{ boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)" }}
        >
          <PageHeader
            title="About BuildTech"
            className="text-3xl font-bold mb-8"
          />

          <PageSection title="Our Mission">
            At BuildTech, we are dedicated to revolutionizing the construction
            industry and beyond with our AI-driven platform. We empower our
            users to generate smart reports, accelerate team collaboration, and
            boost social interactions, thereby elevating the standard of project
            management and execution.
          </PageSection>

          <PageSection title="Our Vision">
            Our vision is to lead the wave of digital transformation across
            various industries starting with construction, by providing an
            all-encompassing platform that not only simplifies report generation
            and team collaboration but also enriches workplace interactions and
            enhances project management with cutting-edge technology.
          </PageSection>

          <PageSection title="Innovations & Progress">
            BuildTech proudly stands at the forefront of technological
            advancement in the industry. With our robust microservice
            infrastructure, comprehensive backend server development, and
            dynamic app development efforts, we've established a scalable and
            secure platform. Our ongoing developments in AI integration,
            project-specific solutions, and multilingual support are setting new
            benchmarks for excellence.
          </PageSection>

          <h2 className="text-2xl font-bold mb-4">Leadership Team</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols gap-8">
            <TeamMember
              name="Uriel Almassy"
              title="CEO & CTO"
              imageUrl={CEO_CTO}
            />
            <TeamMember
              name="Refael Naaman"
              title="CEO & CFO"
              imageUrl={CEO_CFO}
            />
            {/* <TeamMember
                            name="Alice Johnson"
                            title="CFO"
                            imageUrl="https://via.placeholder.com/150"
                        /> */}
            {/* Add more TeamMember components for each team member */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutPage;
