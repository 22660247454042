import React from 'react';
import { FaUser } from 'react-icons/fa';

const UserProfileInfo = () => {
    const demoUser = {
        name: 'John Doe',
        email: 'john.doe@example.com',
        phone: '+1 (234) 567-8901',
        address: '123 Demo St, Suite 456, City, Country',
        bio: 'I am a passionate software developer with over 5 years of experience in full-stack web development. I love creating beautiful, functional, and responsive websites.',
    };

    return (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div>
                <h2 className="text-2xl font-bold mb-4">Personal Information</h2>
                <p>
                    <FaUser className="inline-block mr-2 text-2xl" />
                    <strong>Name:</strong> {demoUser.name}
                </p>
                <p>
                    <strong>Email:</strong> {demoUser.email}
                </p>
                <p>
                    <strong>Phone:</strong> {demoUser.phone}
                </p>
                <p>
                    <strong>Address:</strong> {demoUser.address}
                </p>
            </div>
            <div>
                <h2 className="text-2xl font-bold mb-4">About Me</h2>
                <p>{demoUser.bio}</p>
            </div>
        </div>
    );
};

export default UserProfileInfo;