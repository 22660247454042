// ./src/config/keycloak.ts

import Keycloak, { KeycloakConfig } from "keycloak-js";
import { KEYCLOAK_BASE_URL, KEYCLOAK_CLIENT_ID, KEYCLOAK_REALM } from "./env";

const keycloakConfig: KeycloakConfig = {
  url: KEYCLOAK_BASE_URL,
  realm: KEYCLOAK_REALM,
  clientId: KEYCLOAK_CLIENT_ID,
};

const kc = new Keycloak(keycloakConfig);

export default kc;
