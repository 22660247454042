// index.tsx

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// import { BrowserRouter } from 'react-router-dom';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import keycloak from './config/keycloak';
import Modal from 'react-modal';

const onEvent = (event: string) => {
  console.log('log - index.tsx - onEvent: ', event);
  if (event === 'onTokenExpired') {
    keycloak
      .updateToken(5)
      .then((refreshed) => {
        if (refreshed) {
          console.log('Access token was successfully refreshed');
        } else {
          console.error('Failed to refresh access token');
          keycloak.login();
        }
      })
      .catch(() => {
        console.error('Failed to refresh token');
      });
  }
};

// const onTokens = (tokens: any) => { }
const appElement = document.getElementById('root');
Modal.setAppElement(appElement as any);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <ReactKeycloakProvider
    authClient={keycloak}
    initOptions={{
      onLoad: "check-sso",
      enableLogging: true,
      pkceMethod: "S256",
      // checkLoginIframe: false,
    }}
    onEvent={onEvent}
  >
    <App />
  </ReactKeycloakProvider>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

