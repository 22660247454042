// TeamMember.tsx

import React from 'react';

interface TeamMemberProps {
    name: string;
    title: string;
    imageUrl: string;
}

const TeamMember: React.FC<TeamMemberProps> = ({ name, title, imageUrl }) => {
    return (
        <div className="flex flex-col items-center space-y-2">
            <img className="w-32 h-32 rounded-full" src={imageUrl} alt={name} />
            <h3 className="text-xl font-bold">{name}</h3>
            <p>{title}</p>
        </div>
    );
};

export default TeamMember;