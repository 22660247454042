// HomePage.tsx

// import React from "react";
// import Gallery from "../../components/Home/Gallery";
import Features from "../../components/Home/Features";
// import Testimonials from "../../components/Home/Testimonials";
import CallToAction from "../../components/Home/CallToAction";
// import MediaPlayer from "../../components/Home/MediaPlayer";
import FlashUpdates from "../../components/Home/FlashUpdates";

// const HomePage: React.FC = () => {
//   return (
//     <div className="bg-gray-900 text-white min-h-screen">
//       {/* <FlashUpdates /> */}
//       <Gallery />
//       {/* <MediaPlayer />
//       <Features />
//       <Testimonials />
//       <CallToAction /> */}
//     </div>
//   );
// };

import React from "react";
import { FaBuilding } from "react-icons/fa";
import { AiOutlineTeam, AiOutlineInteraction } from "react-icons/ai";

const HomePage = () => {
  return (
    <>
      <FlashUpdates />
      <div className="bg-gray-900 text-white min-h-screen flex flex-col items-center justify-start p-4">
        <header className="text-center my-10">
          <h1 className="text-4xl font-bold animate-bounce">🏢 BuildTech</h1>
          <p className="mt-2 text-xl">
            Revolutionizing Reporting & Collaboration in Construction and Beyond
          </p>
        </header>

        <main className="w-full flex flex-wrap justify-center items-start">
          <section id="core-functions" className="w-full lg:w-2/3">
            <h2 className="text-3xl font-bold mt-8 mb-4">💼 Core Functions</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {/* Each Feature Block with Animation */}
              <div className="feature-block bg-gray-800 p-4 rounded-lg shadow-lg hover:shadow-2xl transition ease-in-out duration-300 cursor-pointer">
                <div className="flex items-center text-xl font-semibold mb-2">
                  <FaBuilding className="mr-2" />
                  Comprehensive Service
                </div>
                <p>
                  An all-encompassing platform for smart report generation, team
                  collaboration, and more.
                </p>
              </div>

              {/* Repeating for other services with respective icons */}
              {/* Add animations for interactions, like hover effects on the blocks to make them raise or change color slightly to indicate interactivity */}

              <div className="feature-block bg-gray-800 p-4 rounded-lg shadow-lg hover:bg-gray-700 transition ease-in-out duration-300 cursor-pointer">
                <div className="flex items-center text-xl font-semibold mb-2">
                  <AiOutlineTeam className="mr-2" />
                  Accelerating Team Collaboration
                </div>
                <p>
                  Revolutionize team collaboration with seamless communication
                  and efficient task tracking.
                </p>
              </div>

              <div className="feature-block bg-gray-800 p-4 rounded-lg shadow-lg hover:bg-gray-700 transition ease-in-out duration-300 cursor-pointer">
                <div className="flex items-center text-xl font-semibold mb-2">
                  <AiOutlineInteraction className="mr-2" />
                  Boosting Social Interactions
                </div>
                <p>
                  Enhance social interaction between workers with in-app
                  features for shared meals, events, and more.
                </p>
              </div>

              <div className="feature-block bg-gray-800 p-4 rounded-lg shadow-lg hover:bg-gray-700 transition ease-in-out duration-300 cursor-pointer">
                <div className="flex items-center text-xl font-semibold mb-2">
                  {/* <AiOutlineAd className="mr-2" /> */}
                  Company Advertising
                </div>
                <p>
                  Provide a platform for companies to advertise, analyzing
                  exposure and engagement effectively.
                </p>
              </div>

              {/* Continue adding other sections with animations and interactions as per the company overview */}
            </div>
          </section>
        </main>
      </div>
      <Features />
      {/* <Testimonials /> */}
      <CallToAction />
    </>
  );
};

export default HomePage;
