// InputField.tsx

import React, { ChangeEvent, useState } from 'react';
import { validateInput } from '../validation/validation';

interface InputFieldProps {
  label: string;
  value?: string;
  onChange: (value: string) => void;
  type?: string;
  placeholder?: string;
  className?: string;
  readOnly?: boolean;
  show?: boolean;
  onValidationChange?: (isValid: boolean) => void;
  min?: number;
  max?: number;
}

const InputField: React.FC<InputFieldProps> = ({
  label,
  value,
  onChange,
  type = 'text',
  placeholder = '',
  className,
  readOnly = false,
  show = true,
  onValidationChange,
  min,
  max,
}) => {

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    onChange(newValue);

    // Validate the input value based on the input type
    const validationError = validateInput(type, newValue);
    setErrorMessage(validationError);

    // Inform parent component about validation change
    if (onValidationChange) {
      onValidationChange(validationError === null);
    }
  };

  const styles = {
    inputField: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: '1rem',
    },
    label: {
      marginBottom: '0.5rem',
      fontWeight: 600,
    },
    input: {
      padding: '0.5rem',
      fontSize: '1rem',
      border: '1px solid #ccc',
      borderRadius: '4px',
      outline: 'none',
      transition: 'border-color 0.15s ease-in-out',
      backgroundColor: readOnly ? '#f0f0f0' : 'white', // Change the background color based on the 'contentEditable' prop
    },
    inputFocus: {
      borderColor: readOnly ? '#ccc' : '#007bff', // Change the borderColor based on the 'contentEditable' prop
    },
  } as any;

  // Change the background color based on the 'contentEditable' prop
  const inputStyle = {
    ...styles.input,
    backgroundColor: errorMessage ? '#FFC1C1' : readOnly ? '#f0f0f0' : 'white',
  };

  return (
    <>
      {
        (show) && (
          <div className={`input-field ${className}`} style={styles.inputField}>
            {label && <label style={styles.label}>{label}</label>}
            <input
              type={type}
              value={value}
              placeholder={placeholder}
              onChange={handleChange}
              autoCorrect="on"
              // autoComplete="on"
              className="input-element"
              readOnly={readOnly}
              style={inputStyle}
              // style={styles.input}
              onFocus={(e) => (e.target.style.borderColor = styles.inputFocus.borderColor)}
              onBlur={(e) => {
                e.target.style.borderColor = errorMessage ? 'red' : styles.input.borderColor;
              }}
              min={min}
              max={max}
            />
            {errorMessage && <p style={styles.error}>{errorMessage}</p>}
          </div>
        )
      }
    </>
  );
};

export default InputField;