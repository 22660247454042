// UserItem.tsx

import React, { useEffect, useState } from 'react';
import InputField from '../../InputField';
import SelectField from '../../SelectField';
import MultiSelect from '../../MultiSelect';
import { User } from '../../../interfaces/Company';
import CustomButton from '../../CustomButton';


interface UserItemProps {
    user: User;
    keyPhoneNumber: string;
    indexUser: number;
    onUpdate: (keyPhoneNumber: string, updatedUser: User) => void;
    onRemove: (keyPhoneNumber: string) => void;
    handleFieldValidationChange: any;
}

// let countNewUsers = 0;

const UserItem: React.FC<UserItemProps> = ({
    user,
    keyPhoneNumber,
    indexUser,
    onUpdate,
    onRemove,
    handleFieldValidationChange,
}) => {
    const [flagNewUser, setFlagNewUser] = useState<boolean>(false);

    const handleFieldChange = (field: keyof User, value: any) => {
        const updatedUser = { ...user, [field]: value };
        // setUser(updatedUser); // Update the local user state
        // if (!flagNewUser) {
        onUpdate(keyPhoneNumber, updatedUser); // Update the user in the parent component
        // } else {
        //     onUpdate(`newUser_${countNewUsers}`, updatedUser); // Update the user in the parent component
        // }
    };

    // Set the flag if it new user.
    useEffect(() => {
        console.log('useEffect-data-flagNewUser');

        if (keyPhoneNumber.includes("newUser")) {
            setFlagNewUser(true);

        }
    }, [keyPhoneNumber]);


    console.log('user:', user);

    // Return an empty fragment if any of the props are not defined
    if (!user || !keyPhoneNumber || indexUser === undefined) {
        return (
            <></>
        );
    }

    // console.log('user?.keyPhoneNumber:', user?.PhoneNumber);
    return (
        <div className="bg-white shadow rounded p-4 mb-4">
            <h3 className="font-bold mb-2">User {indexUser + 1}:</h3>
            <InputField
                label="Phone Number"
                value={user?.PhoneNumber}
                onChange={(value) => handleFieldChange('PhoneNumber', value)}
                onValidationChange={(isValid) => handleFieldValidationChange('PhoneNumber', isValid, keyPhoneNumber)}
                type="tel"
                readOnly={!flagNewUser}
            />
            <InputField
                label="Time stamp"
                value={user?.TimeStamp}
                onChange={(value) => handleFieldChange('TimeStamp', value)}
                onValidationChange={() => { }}
                type="text"
                readOnly={true}
            />
            <InputField
                label="Position"
                onChange={(value) => handleFieldChange('Position', value)}
                onValidationChange={(isValid) => handleFieldValidationChange('Position', isValid, keyPhoneNumber)}
                type="text"
                value={user?.Position}
            />
            <MultiSelect
                label="Permissions"
                options={[
                    { value: 'notification', label: 'Notification' },
                    { value: 'reports', label: 'Reports' },
                ]}
                value={user?.Permissions?.map((permission) => ({ value: permission, label: permission }))}
                onChange={(selectedOptions) => handleFieldChange('Permissions', selectedOptions.map((option: any) => option.value))}
            />
            {/* Add a text area or custom component for Permissions */}
            <SelectField
                label="Manager?"
                value={user?.IsManager ? 'Yes' : 'No'}
                options={[
                    { value: 'Yes', label: 'Yes' },
                    { value: 'No', label: 'No' },
                ]}
                onChange={(value) => handleFieldChange('IsManager', value === 'Yes')}
            />
            <SelectField
                label="Main Manager?"
                value={user.IsMainManager ? 'Yes' : 'No'}
                options={[
                    { value: 'Yes', label: 'Yes' },
                    { value: 'No', label: 'No' },
                ]}
                onChange={(value) => handleFieldChange('IsMainManager', value === 'Yes')}
            />
            <CustomButton
                className="bg-red-500 text-white px-2 py-1 rounded"
                type="remove"
                onClick={() => onRemove(keyPhoneNumber)}
            >
                Remove
            </CustomButton>
        </div>

    );
};

export default UserItem;
