// useFetchHints.ts

import { useCallback, useEffect } from "react";
import { ApiCallFunction } from "../interfaces/hooks";
import { HintsApi } from "../config/api/managers/HintsApi";

interface useFetchHintsProps {
  setHintsData: any;
  setIsLoading: (loading: boolean) => void;
  apiCall: ApiCallFunction;
  dependencies: any;
}

const useFetchHints = ({
  setHintsData,
  setIsLoading,
  apiCall,
  dependencies,
}: useFetchHintsProps) => {

  // for fetching data
  const fetchData = useCallback(async () => {
    try {
      // Fetch the data
      // Add the Language parameter to the URL
      const urlWithLanguageParam = `${HintsApi.Get.url}?Language=en`;

      // Fetch the data
      const jsonData = await apiCall(
        urlWithLanguageParam,
        HintsApi.Get.method,
        undefined,
        {}
      );

      // debug
      console.log("jsonData:", jsonData);

      // Update the hint data
      setHintsData(jsonData);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      // Set the loading state to false
      setIsLoading(false);
    }
  }, [apiCall, setHintsData, setIsLoading]);

  useEffect(() => {
    console.log("useFetchHints hook called");
    // Set the loading state to true
    setIsLoading(true);

    // Execute the fetchData function
    fetchData();
  }, [fetchData, setIsLoading, dependencies]);
};

export default useFetchHints;
