// routes.ts

import HomePage from "./pages/HomePage";
import AboutPage from "./pages/AboutPage";
import TermsPage from "./pages/TermsPage";
import AddCompany from "./pages/manager/companies/AddCompany";
import ContactPage from "./pages/ContactPage";
import ProfilePage from "./pages/ProfilePage";
import FAQPage from "./pages/FAQPage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import HelpPage from "./pages/HelpPage";
import SendNotification from "./pages/manager/notification/SendNotification";
import HistoryNotification from "./pages/manager/notification/HistoryNotification";
import TableHints from "./pages/manager/hints/TableHints";
import AddHint from "./pages/manager/hints/AddHint";
import UpdateHint from "./pages/manager/hints/UpdateHint";
import UpdateCompany from "./pages/manager/companies/UpdateCompany";
import ApplicantCompanies from "./pages/manager/companies/ApplicantCompanies";
import TableCompanies from "./pages/manager/companies/TableCompanies";

const routes = [
  // home page
  {
    path: "/",
    component: HomePage,
    private: false,
  },
  // general pages
  {
    path: "/profile",
    component: ProfilePage,
    private: false,
  },
  // {
  //   path: "/contact",
  //   component: ContactPage,
  //   private: false,
  // },
  // {
  //   path: "/faq",
  //   component: FAQPage,
  //   private: false,
  // },
  {
    path: "/privacy",
    component: PrivacyPolicyPage,
    private: false,
  },
  // {
  //   path: "/terms",
  //   component: TermsPage,
  //   private: false,
  // },
  // {
  //   path: "/help",
  //   component: HelpPage,
  //   private: false,
  // },
  {
    path: "/about",
    component: AboutPage,
    private: false,
  },
  // companies pages
  {
    path: "/companies",
    component: TableCompanies,
    private: true,
  },
  {
    path: "/add-company",
    component: AddCompany,
    private: true,
  },
  {
    path: "/update-company",
    component: UpdateCompany,
    private: true,
  },
  {
    path: "/applicant-companies",
    component: ApplicantCompanies,
    private: true,
  },
  // hints
  {
    path: "/table-hints",
    component: TableHints,
    private: true,
  },
  {
    path: "/add-hint",
    component: AddHint,
    private: true,
  },
  {
    path: "/update-hint",
    component: UpdateHint,
    private: true,
  },
  // notification
  {
    path: "/notification",
    component: SendNotification,
    private: true,
  },
  {
    path: "/history-notification",
    component: HistoryNotification,
    private: true,
  },
];

export default routes;
