// CompanyInfoModal.tsx

import React from 'react';
import Modal from 'react-modal';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
// import { customToast } from '../CustomToastOptions';
import CompanyForm from '../../Form/CompanyForm';
import { Company } from '../../../interfaces/Company';

// Define the prop types for the CompanyInfoModal component
interface CompanyInfoModalProps {
    company: Company | undefined;
    isOpen: boolean;
    onRequestClose: () => void;
    onUpdate?: (updatedCompany: Company) => void;
    keycloak?: any;
    setCompanyData?: (companyData: Company[]) => void;
    setIsLoading?: (isLoading: boolean) => void;
    opt?: string;
    handleSubmit: (newCompany: Company) => void;
}

const CompanyInfoModal: React.FC<CompanyInfoModalProps> = ({
    company,
    isOpen,
    onRequestClose,
    onUpdate,
    keycloak,
    setCompanyData,
    setIsLoading,
    opt,
    handleSubmit,
}) => {


    // Function to handle closing the modal without saving
    const handleCloseWithoutSaving = () => {
        onRequestClose();
    };


    return (
        <TransitionGroup component={null}>
            {isOpen && (
                <CSSTransition classNames="modal-transition" timeout={300}>
                    <Modal
                        isOpen={isOpen}
                        onRequestClose={() => handleCloseWithoutSaving()}
                        contentLabel="Company Info Modal"
                        className="bg-white rounded-md p-8 mx-auto mt-24 modal-content"
                        overlayClassName="fixed inset-0 bg-black bg-opacity-40 flex items-center justify-center modal-overlay"
                    >
                        <CompanyForm
                            onSubmit={handleSubmit}
                            company={company}
                            onRequestClose={onRequestClose}
                            opt={opt}
                        />
                    </Modal>
                </CSSTransition>
            )}
        </TransitionGroup>
    );
};

export default CompanyInfoModal;

