// useHintsActions.ts

import { useCallback, useState } from "react";
import { ApiCallFunction } from "../interfaces/hooks";
import { HintProps, HintReview } from "../interfaces/Hint";
import { HintsApi } from "../config/api/managers/HintsApi";
import { customToast } from "../components/CustomToastOptions";

interface useHintsActionsProps {
  hintsData: HintProps[];
  setHintsData: React.Dispatch<React.SetStateAction<HintProps[]>>;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  selectedRow: HintProps;
  setSelectedRow: React.Dispatch<React.SetStateAction<HintProps>>;
  startTime: string | null;
  setStartTime: React.Dispatch<React.SetStateAction<string | null>>;
  endTime: string | null;
  setEndTime: React.Dispatch<React.SetStateAction<string | null>>;
  handleRowDoubleClick: (row: HintProps) => void;
  filterByTimestamp: (rows: any[]) => any[];
  handlePhoneFilterChange: (
    setFilter: any
  ) => (event: React.ChangeEvent<HTMLInputElement>) => void;
  updateModalHintOpen: boolean;
  setUpdateModalHintOpen: React.Dispatch<React.SetStateAction<boolean>>;
  insertModalHintOpen: boolean;
  setInsertModalHintOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedRows: string[];
  setSelectedRows: React.Dispatch<React.SetStateAction<string[]>>;
  selectAllChecked: boolean;
  setSelectAllChecked: React.Dispatch<React.SetStateAction<boolean>>;
  handleHintIdFilterChange: (
    setFilter: any
  ) => (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleDeleteSubmit: (rows: any) => Promise<void>;
  refreshCounter: number;
  setRefreshCounter: React.Dispatch<React.SetStateAction<number>>;
  handleUpdateSubmit: (updatedHint: HintProps) => Promise<void>;
  selectedHint: HintProps | undefined;
  setSelectedHint: React.Dispatch<React.SetStateAction<HintProps | undefined>>;
  handleInsertSubmit: (newHint: HintProps) => Promise<void>;
  handleSearch: (hintId: string) => Promise<void>;
  handleSelectAll: (page: any) => (checked: boolean) => void;
}

export const useHintsActions = (
  apiCall: ApiCallFunction,
  isPage: boolean
): useHintsActionsProps => {
  // debug
  console.log("useHintsActions hook called");

  // State for managing hints data
  const [hintsData, setHintsData] = useState<HintProps[]>([]);

  // State for managing the loading state
  const [isLoading, setIsLoading] = useState<boolean>(!isPage);

  // State for managing the selected row
  const [selectedRow, setSelectedRow] = useState<HintProps>({} as HintProps);

  // State for managing the selected row
  const [selectedHint, setSelectedHint] = useState<HintProps>();

  // State for managing the start and end times
  const [startTime, setStartTime] = useState<string | null>(null);
  const [endTime, setEndTime] = useState<string | null>(null);

  // State for managing the update data function
  const [updateModalHintOpen, setUpdateModalHintOpen] =
    useState<boolean>(false);

  // State for managing the insert data function
  const [insertModalHintOpen, setInsertModalHintOpen] =
    useState<boolean>(false);
  // State for managing the selected rows
  const [selectedRows, setSelectedRows] = useState<string[]>([]);

  // Add a new state for managing the select all checkbox
  const [selectAllChecked, setSelectAllChecked] = useState(false);

  // for fetch the data again when a "refresh" needed
  const [refreshCounter, setRefreshCounter] = useState(0);

  // Action handlers //

  // Function to handle searching for a company by name
  const handleSearch = useCallback(
    async (hintId: string) => {
      if (!hintId) return;

      try {
        // set the loading state to true
        setIsLoading(true);

        const queryParams = { Language: "en", HintId: hintId };
        const url = new URL(HintsApi.Get.url);
        const searchParams = new URLSearchParams(queryParams);
        url.search = searchParams.toString();

        // Send GET request to the API
        const response = await apiCall(url.toString(), HintsApi.Get.method);

        // debug
        console.log("response:", response);

        // Check if the response is valid and there is only one company in it
        if (!(response?.length === 1)) {
          throw new Error(`HintId ${hintId} not found`);
        }

        // Display a success toast
        customToast(`The HintId ${hintId} was found.`, "success");
        // set the company data
        setSelectedHint(response[0]);
      } catch (error: any) {
        setSelectedHint(undefined);
        console.error("Error updating company:", error);
        if (error instanceof Error) {
          customToast(error.message, "error");
        } else {
          customToast("An unknown error occurred.", "error");
        }
      } finally {
        // set the loading state to false
        setIsLoading(false);
      }
    },
    [apiCall]
  );

  const handleInsertSubmit = useCallback(
    async (newHint: HintProps) => {
      if (!newHint) {
        return;
      }
      try {
        setIsLoading(true);

        // Move on the object in updateCompany.UsersList and replace all the keys that start with "newUser".
        // The new key will be the phone number of the user.
        // Use reduce on the object's entries to create a new object with updated keys
        const updatedReviewsList = Object.entries(newHint.HintReviews).reduce(
          (accumulator: { [key: string]: HintReview }, [key, review]) => {
            const newKey = key.includes("newReview") ? review.PhoneNumber : key;
            if (newKey) {
              accumulator[newKey] = review;
            }
            return accumulator;
          },
          {}
        );
        // Replace the original UsersList with the updated one
        newHint.HintReviews = updatedReviewsList;

        // Send the POST request to the API
        await apiCall(HintsApi.Insert.url, HintsApi.Insert.method, newHint);
        // Display a success toast
        customToast(`Hint ${newHint.HintId} has been added.`, "success");
      } catch (error: any) {
        console.error("Error inserting hint:", error);
        if (error instanceof Error) {
          customToast(error.message, "error");
        } else {
          customToast("An unknown error occurred.", "error");
        }
      } finally {
        if (!isPage) {
          // close the update modal
          setUpdateModalHintOpen(false);
        }
        // set the loading state to false
        setIsLoading(false);
      }
    },
    [apiCall, isPage]
  );

  // Function to handle updating the company
  const handleUpdateSubmit = useCallback(
    async (updatedHint: HintProps) => {
      // Check if the new company is defined
      if (!updatedHint) {
        return;
      }
      // debug
      console.log("updatedHint: ", updatedHint);

      try {
        // set the loading state to true
        setIsLoading(true);

        // for adding a review to the hint - inactive now //
        // Move on the object in updatedHint.HintReviews and replace all the keys that start with "newReview".
        // The new key will be the phone number of the user.
        // Use reduce on the object's entries to create a new object with updated keys
        const updatedReviewsList = Object.entries(
          updatedHint.HintReviews
        ).reduce(
          (accumulator: { [key: string]: HintReview }, [key, review]) => {
            const newKey = key.includes("newReview") ? review.PhoneNumber : key;
            if (newKey) {
              accumulator[newKey] = review;
            }
            return accumulator;
          },
          {}
        );
        // Replace the original UsersList with the updated one
        updatedHint.HintReviews = updatedReviewsList;
        // inactive now //

        // Send the PATCH request to the API
        await apiCall(HintsApi.Update.url, HintsApi.Update.method, updatedHint);
        // show a success toast
        customToast(`Hint ${updatedHint.HintId} has been updated.`, "success");
        // update the company data table
        if (!isPage) {
          setRefreshCounter((prevCounter) => prevCounter + 1);
        } else {
          setSelectedHint(updatedHint);
        }
      } catch (error: any) {
        console.error("Error updating company:", error);
        if (error instanceof Error) {
          customToast(error.message, "error");
        } else {
          customToast("An unknown error occurred.", "error");
        }
      } finally {
        if (!isPage) {
          // close the update modal
          setUpdateModalHintOpen(false);
        }
        // set the loading state to false
        setIsLoading(false);
      }
    },
    [apiCall, isPage]
  );

  // delete the hints selected
  const handleDeleteSubmit = useCallback(
    async (rows: any) => {
      // debug
      console.log("handleDeleteSubmit: ", rows);

      // Set the loading state
      setIsLoading(true);

      // Get an array of rows to delete and join the company names for display in the confirmation modal
      const rowsToDelete = rows.filter((row: any) =>
        selectedRows.includes(row.id)
      );
      try {
        // Send delete request to the server for each selected row
        for (const row of rowsToDelete) {
          setIsLoading(true); // Start the loading spinner
          const jsonToSend = { HintId: row.values.HintId, Language: "en" };
          await apiCall(
            HintsApi.Delete.url,
            HintsApi.Delete.method,
            jsonToSend
          );

          // Show a success toast
          customToast(
            `Hint ${row.values.HintId} deleted successfully.`,
            "success",
            {
              autoClose: 3000,
            }
          );
        }
      } catch (error: any) {
        console.error("handleUpdateApplicantCompany:", error);
        if (error instanceof Error) {
          customToast(error.message, "error");
        } else {
          customToast("An unknown error occurred.", "error");
        }
      } finally {
        // update the company data table - for refresh the table
        setRefreshCounter((prevCounter) => prevCounter + 1);
        // Set the loading state
        setIsLoading(false);
      }
    },
    [apiCall, selectedRows]
  );

  // Function to handle the double click event of a row
  const handleRowDoubleClick = useCallback((row: HintProps) => {
    setSelectedRow(row);
    setUpdateModalHintOpen(true);
  }, []);

  // filter the data bu the timestamp
  const filterByTimestamp = useCallback(
    (rows: any[]) => {
      if (!startTime && !endTime) return rows;

      const filteredRows = rows.filter((row) => {
        if (!row) return false;

        const timestamp = new Date(row.timestamp);
        const start = startTime ? new Date(startTime) : null;
        const end = endTime ? new Date(endTime) : null;

        return (!start || timestamp >= start) && (!end || timestamp <= end);
      });

      return filteredRows;
    },
    [startTime, endTime]
  );

  // Function to handle the change event of the phone number filter input
  const handlePhoneFilterChange = useCallback(
    (setFilter: any) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const target = event.target as HTMLInputElement;
      const { value } = target;
      setFilter("HintReviews", value); // set the filter for the 'UsersList' column
    },
    []
  );
  // Function to handle the change event of the phone number filter input
  const handleHintIdFilterChange = useCallback(
    (setFilter: any) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const target = event.target as HTMLInputElement;
      const { value } = target;
      setFilter("HintId", value); // set the filter for the 'UsersList' column
    },
    []
  );

  // Function to handle the checkbox
  const handleSelectAll = useCallback(
    (page: any) => (checked: boolean) => {
      // If the checkbox is checked, select all rows on the current page
      if (checked) {
        setSelectedRows(page.map((row: any) => row.id));
      } else {
        // If the checkbox is unchecked, clear the selected rows
        setSelectedRows([]);
      }
      // Update the selectAllChecked state to reflect the new checked status
      setSelectAllChecked(checked);
    },
    []
  );

  return {
    hintsData,
    setHintsData,
    isLoading,
    setIsLoading,
    selectedRow,
    setSelectedRow,
    startTime,
    setStartTime,
    endTime,
    setEndTime,
    updateModalHintOpen,
    setUpdateModalHintOpen,
    insertModalHintOpen,
    setInsertModalHintOpen,
    selectedRows,
    setSelectedRows,
    selectAllChecked,
    setSelectAllChecked,
    refreshCounter,
    setRefreshCounter,
    selectedHint,
    setSelectedHint,
    //
    handleRowDoubleClick,
    filterByTimestamp,
    handlePhoneFilterChange,
    handleHintIdFilterChange,
    handleDeleteSubmit,
    handleUpdateSubmit,
    handleInsertSubmit,
    handleSearch,
    handleSelectAll,
  };
};
