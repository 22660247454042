// PrivacyPolicyPage.tsx

import PageSection from '../../components/PageSection';

function PrivacyPolicyPage() {
    const headerHeight = 80; // Replace with the actual header height
    const footerHeight = 80; // Replace with the actual footer height

    return (
        <div
            className="max-w-screen-lg mx-auto px-4 sm:px-6 lg:px-8 py-8"
            style={{
                backgroundImage: `url('https://source.unsplash.com/random/1600x900?privacy')`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                minHeight: `calc(100vh - ${headerHeight}px - ${footerHeight}px)`,
            }}
        >
            <div
                className="bg-white opacity-80 p-8 rounded-md opacity-70"
                style={{
                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                }}
            >
                <PageSection title="Security of Data">
                    <p>
                        We take the security of your data seriously. We use a variety of
                        security technologies and procedures to help protect your personal
                        information from unauthorized access, use, or disclosure. For
                        example, we store the personal information you provide on computer
                        systems with limited access that are located in controlled
                        facilities.
                    </p>
                    <p>
                        However, no method of transmission over the Internet, or method of
                        electronic storage, is 100% secure. Therefore, while we strive to
                        use commercially acceptable means to protect your personal
                        information, we cannot guarantee its absolute security.
                    </p>
                </PageSection>

                <PageSection title="Links to Other Sites">
                    <p>
                        Our Service may contain links to other sites that are not operated
                        by us. If you click on a third-party link, you will be directed to
                        that third party's site. We strongly advise you to review the
                        privacy policy of every site you visit.
                    </p>
                    <p>
                        We have no control over, and assume no responsibility for the
                        content, privacy policies or practices of any third-party sites or
                        services.
                    </p>
                </PageSection>

                <PageSection title="Children's Privacy">
                    <p>
                        Our Service does not address anyone under the age of 18 ("Children").
                    </p>
                    <p>
                        We do not knowingly collect personally identifiable information from
                        anyone under the age of 18. If you are a parent or guardian and you
                        are aware that your Children has provided us with Personal Data,
                        please contact us. If we become aware that we have collected
                        Personal Data from children without verification of parental
                        consent, we take steps to remove that information from our servers.
                    </p>
                </PageSection>

                <PageSection title="Changes to This Privacy Policy">
                    <p>
                        We may update our Privacy Policy from time to time. We will notify
                        you of any changes by posting the new Privacy Policy on this page.
                    </p>
                    <p>
                        We will let you know via email and/or a prominent notice on our
                        Service, prior to the change becoming effective and update the
                        "effective date" at the top of this Privacy Policy.
                    </p>
                    <p>
                        You are advised to review this Privacy Policy periodically for any
                        changes. Changes to this Privacy Policy are effective when they are
                        posted on this page.
                    </p>
                </PageSection>

                <PageSection title="Contact Us">
                    <p>If you have any questions about this Privacy Policy, please contact us:</p>
                    <ul>
                        <li>By email: info@company.com</li>
                        <li>By phone: +1-555-555-1234</li>
                        <li>By mail: 123 Main St, Anytown USA 12345</li>
                    </ul>
                </PageSection>
            </div>
        </div>
    );
}

export default PrivacyPolicyPage;
