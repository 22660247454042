// PageHeader.tsx

import React from 'react';

interface PageHeaderProps {
    title: string;
    description?: string;
    icon?: string;
    className?: string;
}

const PageHeader: React.FC<PageHeaderProps> = ({
    title,
    description,
    icon,
    className,
}) => {
    const baseStyle = 'flex flex-col md:flex-row md:items-center md:justify-between mb-10';
    const combinedClassNames = className ? `${baseStyle} ${className}` : baseStyle;

    return (
        <header className={combinedClassNames}>
            <div className="flex items-center mb-4 md:mb-0">
                {icon && (
                    <img
                        src={icon}
                        alt={`${title} Icon`}
                        className="w-8 h-8 mr-4"
                        aria-hidden="true"
                    />
                )}
                <h1 className="text-2xl font-bold">{title}</h1>
            </div>
            {description && (
                <p className="text-gray-600 text-sm md:text-base md:mt-2">{description}</p>
            )}
        </header>
    );
};

export default PageHeader;