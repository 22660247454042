// HintInfoModal.tsx

import React from 'react';
import Modal from 'react-modal';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import HintForm from '../../Form/HintForm';
import { HintProps } from '../../../interfaces/Hint';

interface HintInfoModalProps {
    rowData: HintProps | undefined;
    isOpen: boolean;
    onRequestClose: React.Dispatch<React.SetStateAction<boolean>>;
    opt: string;
    onSubmit: (newHint: HintProps) => void;
}

const HintInfoModal: React.FC<HintInfoModalProps> = ({
    rowData,
    isOpen,
    onRequestClose,
    opt,
    onSubmit,
}) => {
    const handleCloseWithoutSaving = () => {
        onRequestClose(false);
    };

    return (
        <TransitionGroup component={null}>
            {isOpen && (
                <CSSTransition classNames="modal-transition" timeout={300}>
                    <Modal
                        isOpen={isOpen}
                        onRequestClose={() => handleCloseWithoutSaving()}
                        contentLabel="HintInfoModal"
                        className="bg-white rounded-md p-8 mx-auto mt-24 modal-content"
                        overlayClassName="fixed inset-0 bg-black bg-opacity-40 flex items-center justify-center modal-overlay"
                    >
                        <HintForm
                            onSubmit={onSubmit}
                            hint={rowData}
                            onRequestClose={onRequestClose}
                            opt={opt}
                        />
                    </Modal>
                </CSSTransition>
            )}
        </TransitionGroup>
    );
};

export default HintInfoModal;
