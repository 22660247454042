import { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import NavLinks from './NavLinks';

const Dropdown = ({
    name,
    links,
    activeDropdown,
    setActiveDropdown,
}: any) => {
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        const handleClickOutside = (e: MouseEvent) => {
            if (isOpen) {
                setIsOpen(false);
            }
        };

        if (isOpen) {
            window.addEventListener('click', handleClickOutside);
        }

        return () => {
            window.removeEventListener('click', handleClickOutside);
        };
    }, [isOpen]);



    useEffect(() => {
        if (activeDropdown === name) {
            setIsOpen(true);
        } else {
            setIsOpen(false);
        }
    }, [activeDropdown, name]);

    const handleClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        if (activeDropdown === name) {
            setActiveDropdown('');
        } else {
            setActiveDropdown(name);
        }
    };


    return (
        <div className="relative">
            <motion.button
                initial="hidden"
                animate="visible"
                variants={{
                    hidden: { opacity: 0, y: -10 },
                    visible: { opacity: 1, y: 0 },
                }}
                transition={{ duration: 0.5 }}
                className="text-base block px-3 py-2 rounded-md text-base font-medium text-gray-800 hover:text-gray-900 focus:outline-none focus:text-gray-900"
                onClick={handleClick}
            >
                {name}
            </motion.button>
            {isOpen && links && (
                <motion.div
                    initial="hidden"
                    animate="visible"
                    variants={{
                        hidden: { opacity: 0, y: -10 },
                        visible: { opacity: 1, y: 0 },
                    }}
                    transition={{ duration: 0.5 }}
                    className="absolute z-10 mt-2 w-56 bg-white rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 divide-y divide-gray-100"
                >
                    <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                        <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-4 sm:p-4">
                            <NavLinks links={links} />
                        </div>
                    </div>
                </motion.div>
            )}
        </div>
    );
};

export default Dropdown;



// return (
//     <div className="relative">
//         <motion.button
//             initial="hidden"
//             animate="visible"
//             variants={{
//                 hidden: { opacity: 0, y: -10 },
//                 visible: { opacity: 1, y: 0 },
//             }}
//             transition={{ duration: 0.5 }}
//             className="block px-3 py-2 rounded-md text-sm font-medium text-gray-800 hover:text-gray-900 focus:outline-none focus:text-gray-900"
//             onClick={toggleDropdown}
//         >
//             {name}
//         </motion.button>
//         {links && isOpen && (
//             <motion.ul
//                 initial="hidden"
//                 animate="visible"
//                 variants={{
//                     hidden: { opacity: 0, y: -10 },
//                     visible: { opacity: 1, y: 0 },
//                 }}
//                 transition={{ duration: 0.5 }}
//                 className="absolute z-10 mt-2 w-56 bg-white rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 divide-y divide-gray-100"
//             >
//                 <div className="py-1">
//                     <NavLinks links={links} />
//                 </div>
//             </motion.ul>
//         )}
//     </div>
// );