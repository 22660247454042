
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const LoadingSpinner = () => {
    return (
        <div className="fixed inset-0 h-screen w-screen bg-white opacity-75 z-50 flex justify-center items-center">
            <div className="flex justify-center items-center">
                <FontAwesomeIcon icon={faSpinner} spin className="text-blue-500 text-4xl" />
            </div>
        </div>
    );
};

export default LoadingSpinner;