// useApplicantCompanyActions.ts

import { useCallback, useState } from "react";
import { ApiCallFunction } from "../interfaces/hooks";
import {
  NotificationHistoryProps,
  // NotificationProps,
} from "../interfaces/Notification";

interface useHistoryNotificationActionsProps {
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  //
  selectedRow?: NotificationHistoryProps | undefined;
  setSelectedRow?: React.Dispatch<
    React.SetStateAction<NotificationHistoryProps | undefined>
  >;

  selectedHistoryNotification?: NotificationHistoryProps | undefined;
  setSelectedHistoryNotification?: React.Dispatch<
    React.SetStateAction<NotificationHistoryProps | undefined>
  >;
  modalHistoryNotificationOpen: boolean;
  setModalHistoryNotificationOpen: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  deleteApplicantCompanyOpen?: boolean;
  setDeleteApplicantCompanyOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  addCompanyOpen?: boolean;
  setAddCompanyOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  insertModalApplicantCompaniesOpen?: boolean;
  setInsertModalApplicantCompaniesOpen?: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  handleRowDoubleClick: (row: NotificationHistoryProps) => void;
  //
  historyNotificationData: NotificationHistoryProps[];
  setHistoryNotificationData: React.Dispatch<
    React.SetStateAction<NotificationHistoryProps[]>
  >;

  startTime: string | null;
  setStartTime: React.Dispatch<React.SetStateAction<string | null>>;

  endTime: string | null;
  setEndTime: React.Dispatch<React.SetStateAction<string | null>>;

  //
  filterByTimestamp: (rows: any[]) => any[];
  handleCompanyFilterChange: (
    setFilter: any
  ) => (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleTitleFilterChange: (
    setFilter: any
  ) => (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const useHistoryNotificationActions = (
  apiCall: ApiCallFunction
): useHistoryNotificationActionsProps => {
  // debug
  console.log("useHistoryNotificationActions hook called");

  // State for managing selected company
  const [historyNotificationData, setHistoryNotificationData] = useState<
    NotificationHistoryProps[]
  >([]);

  // State for managing the loading state
  const [isLoading, setIsLoading] = useState<boolean>(true);

  // State for managing the selected row and popup visibility
  const [selectedRow, setSelectedRow] = useState<
    NotificationHistoryProps | undefined
  >(undefined);

  // State for managing selected applicant company
  const [selectedHistoryNotification, setSelectedHistoryNotification] =
    useState<NotificationHistoryProps | undefined>();

  // State for managing update modal
  const [modalHistoryNotificationOpen, setModalHistoryNotificationOpen] =
    useState(false);

  // set the start time for the filter
  const [startTime, setStartTime] = useState<string | null>(null);

  // set the end time for the filter
  const [endTime, setEndTime] = useState<string | null>(null);

  // Action handlers

  // Add the missing functions
  const handleRowDoubleClick = (row: NotificationHistoryProps) => {
    setSelectedHistoryNotification(row);
    setModalHistoryNotificationOpen(true);
  };

  // The filterByTimestamp function is used to filter rows based on the start and end times
  const filterByTimestamp = useCallback(
    (rows: any[]) => {
      // If both start and end times are not set, return all rows unfiltered
      if (!startTime && !endTime) return rows;

      // Filter rows based on the given start and end times
      const filteredRows = rows.filter((row) => {
        // If the row data is not available, exclude it from the filtered results
        if (!row) return false;

        // Convert the TimeStamp string to a Date object
        const timestamp = new Date(row.TimeStamp);

        // Convert the startTime and endTime strings to Date objects if they exist
        const start = startTime ? new Date(startTime) : null;
        const end = endTime ? new Date(endTime) : null;

        // Check if the timestamp is within the specified range (inclusive)
        return (!start || timestamp >= start) && (!end || timestamp <= end);
      });

      // Return the filtered rows
      return filteredRows;
    },
    // Add historyNotificationData to the dependency array to ensure the function is updated when the data changes
    [startTime, endTime]
  );

  // The handleCompanyFilterChange function is used to filter rows based on the company
  const handleCompanyFilterChange =
    (setFilter: any) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const target = event.target as HTMLInputElement;
      const { value } = target;
      setFilter("CompanyId", value);
    };

  // The handleTitleFilterChange function is used to filter rows based on the title
  const handleTitleFilterChange =
    (setFilter: any) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const target = event.target as HTMLInputElement;
      const { value } = target;
      setFilter("Title", value);
    };

  return {
    historyNotificationData,
    setHistoryNotificationData,
    isLoading,
    setIsLoading,
    selectedRow,
    setSelectedRow,
    selectedHistoryNotification,
    setSelectedHistoryNotification,
    modalHistoryNotificationOpen,
    setModalHistoryNotificationOpen,
    startTime,
    setStartTime,
    endTime,
    setEndTime,
    //

    handleRowDoubleClick,
    filterByTimestamp,
    handleCompanyFilterChange,
    handleTitleFilterChange,
  };
};
