// MultiSelect.tsx

import React, { useEffect, useMemo, useState } from 'react';
import Select, { components } from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
// Import the CSS file
import './MultiSelect.css';
import CustomButton from './CustomButton';

interface MultiSelectProps {
    label: string;
    options: Array<{ value: string; label: string }>;
    value: any[] | undefined;
    onChange: (selectedOptions: any) => void;
    readOnly?: boolean; // Add the readOnly prop
}

const DropdownIndicator = (props: any) => {
    return (
        <components.DropdownIndicator {...props}>
            <FontAwesomeIcon icon={faCheck} />
        </components.DropdownIndicator>
    );
};

const MultiSelect: React.FC<MultiSelectProps> = ({
    label,
    options,
    value,
    onChange,
    readOnly = false,
}) => {
    const [isFocused, setIsFocused] = useState(false);

    // Define the number of lines to display
    const maxVisibleLines = 1;
    // Calculate the maxHeight based on the line height and margin
    const optionLineHeight = 35; // Change this value to match your option line height
    const optionMargin = 4; // Change this value to match your option margin
    const maxHeight = maxVisibleLines * (optionLineHeight + optionMargin);

    // Define custom styles for the react-select component
    const customStyles = {
        control: (base: any) => ({
            ...base,
            height: isFocused ? 'auto' : `${maxHeight}px`,
            minHeight: `${maxHeight}px`,
            backgroundColor: readOnly ? '#ccc' : base.backgroundColor, // Set the background color based on the readOnly prop
        }),
        menu: (base: any) => ({
            ...base,
            marginTop: 0,
        }),
    };

    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = () => {
        setIsFocused(false);
    };

    const handleSelectAll = () => {
        if (value && value.length === options.length) {
            onChange([]);
        } else {
            onChange(options);
        }
    };

    console.log("MultiSelect");
    return (
        <div className="pt-2 pb-2">
            <label className="block text-sm font-bold mb-2">{label}</label>
            <Select
                options={options}
                value={value} // Use defaultValue if value has no elements
                onChange={readOnly ? () => { } : onChange}
                isMulti
                closeMenuOnSelect={false}
                components={{
                    DropdownIndicator,
                }}
                onFocus={handleFocus}
                onBlur={handleBlur}
                isDisabled={readOnly}
            />
            {!readOnly && ( // Only show the "Select All" button if the component is not readOnly
                <CustomButton
                    type="button"
                    className="bg-blue-500 text-white px-2 py-1 rounded mt-2 transition-colors duration-200 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-300 active:bg-blue-700"
                    onClick={handleSelectAll}
                >
                    <TransitionGroup>
                        {value && value.length === options.length ? (
                            <CSSTransition
                                key="unselect-all"
                                timeout={0}
                                classNames="transition"
                            >
                                <span>Unselect All</span>
                            </CSSTransition>
                        ) : (
                            <CSSTransition
                                key="select-all"
                                timeout={0}
                                classNames="transition"
                            >
                                <span>Select All</span>
                            </CSSTransition>
                        )}
                    </TransitionGroup>
                </CustomButton>
            )}
        </div>
    );
};

export default MultiSelect;