import React from "react";

interface PageSectionProps {
    title: string;
    children: React.ReactNode;
}

const PageSection: React.FC<PageSectionProps> = ({ title, children }) => {
    return (
        <section className="my-8">
            <h2 className="text-2xl font-semibold">{title}</h2>
            <div className="mt-4">{children}</div>
        </section>
    );
};

export default PageSection;