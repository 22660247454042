// SendNotification.tsx

import { useState } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import './Dashboard.css';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import PageHeader from '../../../../components/PageHeader';
import { customToast } from '../../../../components/CustomToastOptions';
import NotificationForm from '../../../../components/Form/NotificationForm';
import { NotificationApi } from '../../../../config/api/managers/NotificationApi';
import useApiCall from '../../../../hooks/apiCall';
import useFetchCompaniesName from '../../../../hooks/useFetchCompaniesName';


const SendNotification = () => {
  // State for loading spinner
  const [isLoading, setIsLoading] = useState<boolean>(true);
  // Get the keycloak hook
  const { keycloak } = useKeycloak();
  // Get the apiCall hook
  const apiCall = useApiCall(keycloak);

  const [companyOptions, setCompanyOptions] = useState([]);

    // Fetch company list and set companyOptions
    useFetchCompaniesName({ setCompanyOptions, setIsLoading, apiCall });

  // Handle the send notification form submit.
  const handleSendSubmit = async (notificationData: any) => {
    if (!notificationData) {
      return;
    }
    console.log("handleSendSubmit:", notificationData);

    try {
      // Set the loading state
      setIsLoading(true);

      // make the api coll
      await apiCall(
        NotificationApi.Send.url,
        NotificationApi.Send.method,
        notificationData
      );
      // Show a success toast
      customToast("Notification has been sent successfully.", "success");
    } catch (error: any) {
      console.error("handleSubmit:", error);
      if (error instanceof Error) {
        customToast(error.message, "error");
      } else {
        customToast("An unknown error occurred.", "error");
      }
    } finally {
      // Set the loading state
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="container mx-auto mt-10">
          <PageHeader
            title="Send Notification"
            className="text-2xl font-semibold mb-4"
          />
          <NotificationForm
            onSubmit={handleSendSubmit}
            opt="send"
            companyOptions={companyOptions}
          />
          <ToastContainer />
        </div>
      )}
    </>
  );
};

export default SendNotification