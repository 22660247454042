// useFetchHints.ts

import { useCallback, useEffect } from "react";
import { ApiCallFunction } from "../interfaces/hooks";
import { CompaniesApi } from "../config/api/managers/CompaniesApi";

interface useFetchCompaniesProps {
  setCompanyData: any;
  setIsLoading: (loading: boolean) => void;
  apiCall: ApiCallFunction;
  setSelectedRows: React.Dispatch<React.SetStateAction<string[]>>;
  dependencies: any;
}

const useFetchCompanies = ({
  setCompanyData,
  setIsLoading,
  apiCall,
  setSelectedRows,
  dependencies,
}: useFetchCompaniesProps) => {
  // for fetching data
  const fetchData = useCallback(async () => {
    try {
      // Fetch the data
      const jsonData = await apiCall(
        CompaniesApi.Get.url,
        CompaniesApi.Get.method,
        undefined,
        {}
      );

      // debug
      console.log("jsonData:", jsonData);

      // Update the companies data
      setCompanyData(jsonData);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      // Clear the selected rows
      setSelectedRows([]);
      // Set the loading state to false
      setIsLoading(false);
    }
  }, [apiCall, setCompanyData, setIsLoading, setSelectedRows]);

  useEffect(() => {
    console.log("useFetchCompanies hook called");
      // Set the loading state to true
      setIsLoading(true);

      // Execute the fetchData function
      fetchData();
  }, [fetchData, setIsLoading, dependencies]);
};

export default useFetchCompanies;
