// CompanyForm.tsx

import React, { useState } from "react";
// import { Company, User } from '../../components/CompaniesTable/CompaniesTable';
import InputField from "../InputField";
import SelectField from "../SelectField";
import CustomButton from "../../components/CustomButton";
import UserItem from "../tables/CompaniesTable/UserItem";
import ConfirmationModal from "../ConfirmationModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { Company, User } from "../../interfaces/Company";
import { Capitalize, RemovePrefix } from "../../utils/Strings";

interface CompanyFormProps {
  onSubmit: (newCompany: Company) => void;
  company?: Company | undefined;
  onRequestClose: React.Dispatch<React.SetStateAction<boolean>>;
  opt?: string;
}

const CompanyForm: React.FC<CompanyFormProps> = ({
  onSubmit,
  company,
  onRequestClose,
  opt,
}) => {
  // manage the visibility of the ConfirmationModal
  const [showConfirmation, setShowConfirmation] = useState(false);

  // manage the count state for the new user
  const [countNewUsers, setCountNewUsers] = useState<number>(0);

  // New company data to send to api
  const [newCompany, setNewCompany] = useState<Company | undefined>(() => {
    if (company) {
      return company;
    } else {
      return {
        CompanyId: "",
        ContactPhoneNumber: "",
        ContactEmail: "",
        IsActive: true,
        UsersList: {},
      };
    }
  });

  // Keep track of invalid fields
  const [invalidFields, setInvalidFields] = useState<Set<keyof Company>>(() => {
    const initialInvalidFields = new Set<keyof Company>();
    if (!company) {
      initialInvalidFields.add("CompanyId");
      initialInvalidFields.add("ContactPhoneNumber");
      initialInvalidFields.add("ContactEmail");
    }
    return initialInvalidFields;
  });

  // Update the newCompany state when the company prop changes
  const handleFieldChange = (field: keyof Company, value: any) => {
    setNewCompany((tempNewCompany) => {
      if (tempNewCompany) {
        return { ...tempNewCompany, [field]: value };
      }
      return newCompany;
    });
  };

  // Update the newCompany state when the company prop changes
  const handleFieldValidationChange = (
    field: keyof Company | string,
    isValid: boolean,
    userIndex?: string
  ) => {
    // Set the new state of invalidFields based on the previous state
    setInvalidFields((prevInvalidFields) => {
      // debug
      console.log("prevInvalidFields", prevInvalidFields);

      // Create a new Set to store the updated invalid fields
      const newInvalidFields = new Set(prevInvalidFields);

      // If userIndex is provided, create a fieldKey using userIndex and field, otherwise use field as is
      const fieldKey =
        userIndex !== undefined ? `user_${userIndex}_${field}` : field;

      // If the field is valid, remove it from the set of invalid fields
      if (isValid) {
        newInvalidFields.delete(fieldKey as keyof Company);
      } else {
        // If the field is not valid, add it to the set of invalid fields
        newInvalidFields.add(fieldKey as keyof Company);
      }

      // Return the updated set of invalid fields
      return newInvalidFields;
    });
  };

  // Function to handle updating a user
  const handleUserUpdate = (keyPhoneNumber: string, updatedUser: User) => {
    setNewCompany((newCompany) => {
      if (newCompany) {
        return {
          ...newCompany,
          UsersList: { ...newCompany.UsersList, [keyPhoneNumber]: updatedUser },
        };
      }
      return newCompany;
    });
  };

  // Function to handle removing a user
  const handleRemoveUser = (keyPhoneNumber: string) => {
    setNewCompany((prevCompany) => {
      if (prevCompany) {
        // if (keyPhoneNumber.includes("newUser")) {
        //     setCountNewUsers(countNewUsers - 1);
        // }
        handleFieldValidationChange("PhoneNumber", true, keyPhoneNumber);
        handleFieldValidationChange("Position", true, keyPhoneNumber);
        const updatedUsersList = { ...prevCompany.UsersList };
        delete updatedUsersList[keyPhoneNumber];
        return {
          ...prevCompany,
          UsersList: updatedUsersList,
        };
      }

      return prevCompany;
    });
  };

  // Function to handle adding a new user
  const handleAddUser = () => {
    setNewCompany((newCompany) => {
      setCountNewUsers(countNewUsers + 1);
      if (newCompany) {
        // Create a new user
        const keyPhoneNumber = "newUser-" + countNewUsers; // generate a unique ID for this field
        const newUser: User = {
          PhoneNumber: "",
          Position: "",
          Permissions: [],
          IsManager: false,
          IsMainManager: false,
        };
        // add validation needed to pass for the new user
        handleFieldValidationChange("PhoneNumber", false, keyPhoneNumber);
        handleFieldValidationChange("Position", false, keyPhoneNumber);
        return {
          ...newCompany,
          UsersList: { ...newCompany.UsersList, [keyPhoneNumber]: newUser },
        };
      } else {
        // Create the first user in UsersList.
        const keyPhoneNumber = "newUser-" + countNewUsers; // generate a unique ID for this field
        const newUser: User = {
          PhoneNumber: "",
          Position: "",
          Permissions: [],
          IsManager: false,
          IsMainManager: false,
        };
        handleFieldValidationChange("PhoneNumber", false, keyPhoneNumber);
        handleFieldValidationChange("Position", false, keyPhoneNumber);
        return {
          ...(newCompany as any),
          UsersList: { [keyPhoneNumber]: newUser },
        };
      }
    });
  };

  const handleCancel = () => {
    setShowConfirmation(false);
  };

  const handleConfirm = () => {
    setShowConfirmation(false);
    if (newCompany) {
      onSubmit(newCompany);
    }
    // console.log('01 - company: ', company);
    // console.log('01 - newCompany: ', newCompany);
  };

  const handleFormSubmit = (
    e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>
  ) => {
    // Prevent the default form submission behavior
    e.preventDefault();

    // Perform form validation and data processing here

    // Call the onSubmit prop with the updated company data
    setShowConfirmation(true);
  };

  console.log("CompanyForm");
  return (
    // <form onSubmit={(e) => { e.preventDefault(); setShowConfirmation(true); }}>
    <form onSubmit={handleFormSubmit}>
      {/* InputField and SelectField components */}
      <InputField
        label="Company"
        onChange={(value) => handleFieldChange("CompanyId", value)}
        onValidationChange={(isValid) =>
          handleFieldValidationChange("CompanyId", isValid)
        }
        type="text"
        value={newCompany?.CompanyId}
        readOnly={company !== undefined}
      />
      <InputField
        label="Time Stamp"
        value={newCompany?.TimeStamp}
        onChange={() => {}}
        readOnly={true}
        show={company !== undefined}
      />
      <InputField
        label="Contact Phone Number"
        onChange={(value) => handleFieldChange("ContactPhoneNumber", value)}
        onValidationChange={(isValid) =>
          handleFieldValidationChange("ContactPhoneNumber", isValid)
        }
        type="tel"
        value={newCompany?.ContactPhoneNumber}
      />
      <InputField
        label="Contact Email"
        onChange={(value) => handleFieldChange("ContactEmail", value)}
        onValidationChange={(isValid) =>
          handleFieldValidationChange("ContactEmail", isValid)
        }
        type="email"
        value={newCompany?.ContactEmail}
      />
      {opt !== "insert" ? (
        <SelectField
          label="Active?"
          options={[
            { value: "Yes", label: "Yes" },
            { value: "No", label: "No" },
          ]}
          onChange={(value) => handleFieldChange("IsActive", value === "Yes")}
          value={newCompany?.IsActive ? "Yes" : "No"}
        />
      ) : null}
      {/* Render UsersList */}
      <div>
        <h2 className="font-bold mb-2">Users List</h2>
        <div
          className="users-grid"
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(3, 1fr)", // Change 'auto-fill' to 4 to have 4 users per row
            gridGap: "1rem",
            maxWidth: "100%",
          }}
        >
          {Object.entries(newCompany?.UsersList || {}).map(
            ([keyPhoneNumber, user], index) => {
              // user.keyPhoneNumber = keyPhoneNumber
              if (!keyPhoneNumber.includes("newUser")) {
                user.PhoneNumber = keyPhoneNumber;
              }
              return (
                <UserItem
                  key={keyPhoneNumber}
                  indexUser={index}
                  keyPhoneNumber={keyPhoneNumber}
                  user={user}
                  onUpdate={handleUserUpdate}
                  onRemove={handleRemoveUser}
                  handleFieldValidationChange={handleFieldValidationChange}
                />
              );
            }
          )}
          <CustomButton
            className={`bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-300 active:bg-green-700 text-white px-4 py-2 rounded transition-colors duration-200`}
            type="add"
            onClick={handleAddUser}
          >
            <FontAwesomeIcon icon={faUserPlus} className="mr-1" />
            Add User
          </CustomButton>
        </div>
      </div>
      <div className="flex justify-between mt-4 pb-10">
        <ConfirmationModal
          isOpen={showConfirmation}
          onRequestClose={handleCancel}
          title="Update Company"
          message="Are you sure you want to update this company?"
          onCancel={handleCancel}
          onConfirm={handleConfirm}
        />
        {opt === "insert" || opt === "update" ? (
          <>
            <CustomButton
              onClick={handleFormSubmit}
              disabled={invalidFields.size > 0}
              type={opt}
            >
              {Capitalize(opt)}
            </CustomButton>
            <CustomButton type="cancel" onClick={() => onRequestClose(false)}>
              Cancel
            </CustomButton>
          </>
        ) : opt === "page-update" || opt === "page-insert" ? (
          <>
            <CustomButton
              onClick={handleFormSubmit}
              disabled={invalidFields.size > 0}
              type={RemovePrefix(opt, "page-")}
            >
              {Capitalize(RemovePrefix(opt, "page-"))}
            </CustomButton>
          </>
        ) : (
          <></>
        )}
      </div>
    </form>
  );
};

export default CompanyForm;
