// useApplicantCompanyActions.ts

import { useCallback, useState } from "react";
import { CompaniesApi } from "../config/api/managers/CompaniesApi";
import { CompaniesApplicantApi } from "../config/api/managers/CompaniesApplicantApi";
import { customToast } from "../components/CustomToastOptions";
import { CompanyApplicant } from "../interfaces/CompanyApplicant";
import { Company, User } from "../interfaces/Company";
import { ApiCallFunction } from "../interfaces/hooks";

interface UseApplicantCompanyActions {
  handleAddCompanyOpen: (company: CompanyApplicant) => void;
  handleDeleteApplicantCompany: () => Promise<void>;
  handleAddCompany: (newCompany: Company) => Promise<void>;
  handleUpdateApplicantCompany: (
    updateApplicantCompany: CompanyApplicant
  ) => Promise<void>;
  handleAddApplicantCompany: (
    updateApplicantCompany: CompanyApplicant
  ) => Promise<void>;
  selectedCompany: Company | undefined;
  setSelectedCompany: React.Dispatch<React.SetStateAction<Company | undefined>>;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  //
  selectedRow: CompanyApplicant | undefined;
  setSelectedRow: React.Dispatch<
    React.SetStateAction<CompanyApplicant | undefined>
  >;
  selectedRows: string[];
  setSelectedRows: React.Dispatch<React.SetStateAction<string[]>>;
  applicantCompanyData: CompanyApplicant[];
  setApplicantCompanyData: React.Dispatch<
    React.SetStateAction<CompanyApplicant[]>
  >;
  selectedApplicantCompany: CompanyApplicant | undefined;
  setSelectedApplicantCompany: React.Dispatch<
    React.SetStateAction<CompanyApplicant | undefined>
  >;
  updateModalApplicantCompaniesOpen: boolean;
  setUpdateModalApplicantCompaniesOpen: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  deleteApplicantCompanyOpen: boolean;
  setDeleteApplicantCompanyOpen: React.Dispatch<React.SetStateAction<boolean>>;
  addCompanyOpen: boolean;
  setAddCompanyOpen: React.Dispatch<React.SetStateAction<boolean>>;
  insertModalApplicantCompaniesOpen: boolean;
  setInsertModalApplicantCompaniesOpen: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  handleRowDoubleClick: (row: CompanyApplicant) => void;
}

export const convertApplicantCompanyToCompany = (
  companyApplicant: CompanyApplicant
): Company => {
  return {
    ...companyApplicant,
    // UsersList: companyApplicant.UsersList || {},
    UsersList: {},
  };
};

export const useApplicantCompanyActions = (
  apiCall: ApiCallFunction
): UseApplicantCompanyActions => {
  // State for managing selected company
  const [selectedCompany, setSelectedCompany] = useState<Company | undefined>();

  // State for managing the loading state
  const [isLoading, setIsLoading] = useState<boolean>(true);

  // State for managing the selected row and popup visibility
  const [selectedRow, setSelectedRow] = useState<CompanyApplicant | undefined>(
    undefined
  );

  // State for managing the selected rows
  const [selectedRows, setSelectedRows] = useState<string[]>([]);

  // State for managing the data
  const [applicantCompanyData, setApplicantCompanyData] = useState<
    CompanyApplicant[]
  >([]);

  // State for managing selected applicant company
  const [selectedApplicantCompany, setSelectedApplicantCompany] = useState<
    CompanyApplicant | undefined
  >();

  // State for managing update modal
  const [
    updateModalApplicantCompaniesOpen,
    setUpdateModalApplicantCompaniesOpen,
  ] = useState(false);

  // State for managing delete modal
  const [deleteApplicantCompanyOpen, setDeleteApplicantCompanyOpen] =
    useState(false);

  // State for managing add company modal
  const [addCompanyOpen, setAddCompanyOpen] = useState(false);

  // State for managing insert modal
  const [
    insertModalApplicantCompaniesOpen,
    setInsertModalApplicantCompaniesOpen,
  ] = useState<boolean>(false);

  // Get the apiCall hook
  // const apiCall = useApiCall();

  // Add the missing functions
  const handleRowDoubleClick = (row: CompanyApplicant) => {
    setSelectedApplicantCompany(row);
    setUpdateModalApplicantCompaniesOpen(true);
  };

  // Action handlers

  // Open the add company modal - Wrap handleAddCompanyOpen with useCallback
  const handleAddCompanyOpen = useCallback(
    (company: CompanyApplicant) => {
      setSelectedCompany(convertApplicantCompanyToCompany(company));

      // Open the add company modal
      setAddCompanyOpen(true);
    },
    [setSelectedCompany]
  );

  // Delete the selected Applicant Company
  const handleDeleteApplicantCompany = useCallback(async () => {
    // debug
    console.log("handleDeleteApplicantCompany");

    // Set the loading state
    setIsLoading(true);
    // Close the modal
    setDeleteApplicantCompanyOpen(false);
    try {
      const companyName = selectedCompany?.CompanyId;
      await apiCall(
        CompaniesApplicantApi.Delete.url,
        CompaniesApplicantApi.Delete.method,
        { CompanyId: companyName }
      );

      customToast("Company deleted successfully.", "success", {
        autoClose: 3000,
      });
    } catch (error: any) {
      console.error("handleDeleteApplicantCompany:", error);
      if (error instanceof Error) {
        customToast(error.message, "error");
      } else {
        customToast("An unknown error occurred.", "error");
      }
    } finally {
      // update the company data table - for refresh the table
      setApplicantCompanyData([]);
      // Set the loading state
      setIsLoading(false);
    }
  }, [apiCall, selectedCompany?.CompanyId]);

  // Add a new company
  const handleAddCompany = useCallback(
    async (newCompany: Company) => {
      // debug
      console.log("newCompany: ", newCompany);

      // Set the loading state
      setIsLoading(true);
      // Close the modal
      setAddCompanyOpen(false);
      try {
        // Move on the object in updateCompany.UsersList and replace all the keys that start with "newUser".
        // The new key will be the phone number of the user.
        // Use reduce on the object's entries to create a new object with updated keys
        const updatedUsersList = Object.entries(newCompany.UsersList).reduce(
          (accumulator: { [key: string]: User }, [key, user]) => {
            const newKey = key.includes("newUser") ? user.PhoneNumber : key;
            if (newKey) {
              accumulator[newKey] = user;
            }
            return accumulator;
          },
          {}
        );

        // Replace the original UsersList with the updated one
        newCompany.UsersList = updatedUsersList;

        // make the api coll
        await apiCall(
          CompaniesApi.Insert.url,
          CompaniesApi.Insert.method,
          newCompany
        );

        // Show a success toast
        customToast("Your company has been added.", "success");
      } catch (error: any) {
        console.error("handleAddCompany:", error);
        if (error instanceof Error) {
          customToast(error.message, "error");
        } else {
          customToast("An unknown error occurred.", "error");
        }
      } finally {
        // update the company data table - for refresh the table
        setApplicantCompanyData([]);
        // Set the loading state
        setIsLoading(false);
      }
    },
    [apiCall]
  );

  // Update the Applicant Company
  const handleUpdateApplicantCompany = useCallback(
    async (updateApplicantCompany: CompanyApplicant) => {
      // debug
      console.log("updateApplicantCompany: ", updateApplicantCompany);

      // Set the loading state
      setIsLoading(true);
      // Close the modal
      setUpdateModalApplicantCompaniesOpen(false);
      try {
        await apiCall(
          CompaniesApplicantApi.Update.url,
          CompaniesApplicantApi.Update.method,
          updateApplicantCompany
        );

        // Show a success toast
        customToast("Your Applicant Company has been updated.", "success");
      } catch (error: any) {
        console.error("handleUpdateApplicantCompany:", error);
        if (error instanceof Error) {
          customToast(error.message, "error");
        } else {
          customToast("An unknown error occurred.", "error");
        }
      } finally {
        // update the company data table - for refresh the table
        setApplicantCompanyData([]);
        // Set the loading state
        setIsLoading(false);
      }
    },
    [apiCall]
  );

  // Add a new applicant company
  const handleAddApplicantCompany = useCallback(
    async (updateApplicantCompany: CompanyApplicant) => {
      // debug
      console.log("updateApplicantCompany: ", updateApplicantCompany);

      // Set the loading state
      setIsLoading(true);
      // Close the modal
      setInsertModalApplicantCompaniesOpen(false);
      try {
        await apiCall(
          CompaniesApplicantApi.Insert.url,
          CompaniesApplicantApi.Insert.method,
          updateApplicantCompany
        );

        // Show a success toast
        customToast("Your Applicant Company has been added.", "success");
      } catch (error: any) {
        console.error("handleAddApplicantCompany:", error);
        if (error instanceof Error) {
          customToast(error.message, "error");
        } else {
          customToast("An unknown error occurred.", "error");
        }
      } finally {
        // update the company data table - for refresh the table
        setApplicantCompanyData([]);
        // Set the loading state
        setIsLoading(false);
      }
    },
    [apiCall]
  );

  return {
    selectedCompany,
    setSelectedCompany,
    isLoading,
    setIsLoading,
    selectedRow,
    setSelectedRow,
    selectedRows,
    setSelectedRows,
    applicantCompanyData,
    setApplicantCompanyData,
    selectedApplicantCompany,
    setSelectedApplicantCompany,
    updateModalApplicantCompaniesOpen,
    setUpdateModalApplicantCompaniesOpen,
    deleteApplicantCompanyOpen,
    setDeleteApplicantCompanyOpen,
    addCompanyOpen,
    setAddCompanyOpen,
    insertModalApplicantCompaniesOpen,
    setInsertModalApplicantCompaniesOpen,
    //
    handleRowDoubleClick,
    handleAddCompanyOpen,
    handleDeleteApplicantCompany,
    handleAddCompany,
    handleUpdateApplicantCompany,
    handleAddApplicantCompany,
  };
};
