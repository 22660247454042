// TableCompanies.tsx

import React, { useEffect } from 'react';
import {
    useTable,
    useFilters,
    useSortBy,
    Column,
    Row,
    useGlobalFilter,
    usePagination,
} from 'react-table';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import { TableBody } from '../../../../components/tables/TableBody';
import Pagination from '../../../../components/tables/Pagination';
import { useKeycloak } from '@react-keycloak/web';
import PageHeader from '../../../../components/PageHeader';
import CompanyInfoModal from '../../../../components/tables/CompaniesTable/CompanyInfoModal';
import './TableCompanies.css';
import { useCompanyActions } from '../../../../hooks/useCompanyActions';
import useApiCall from '../../../../hooks/apiCall';
import { TableBodyTouch } from '../../../../components/tables/TableBodyTouch';
import FilterInputs from '../../../../components/tables/FilterInputs';
import { IS_ENABLE_SELECT_ALL, IS_LINE_CLICKABLE, MAX_NUM_OF_ROWS } from '../../../../config/env';
import { TableHead } from '../../../../components/tables/TableHead';
import useFetchCompanies from '../../../../hooks/useFetchCompanies';
import { Company, User } from '../../../../interfaces/Company';
import DeleteAllButton from '../../../../components/tables/DeleteAllButton';


const TableCompanies = () => {
    // Get the Keycloak instance
    const { keycloak } = useKeycloak();

    // Get the apiCall hook
    const apiCall = useApiCall(keycloak);
    const {
        companyData,
        setCompanyData,
        isLoading,
        setIsLoading,
        // selectedRow,
        setSelectedRow,
        selectedRows,
        setSelectedRows,
        selectAllChecked,
        setSelectAllChecked,
        // startTime,
        // setStartTime,
        // endTime,
        // setEndTime,
        selectedCompany,
        // setSelectedCompany,
        modalOpen,
        // setModalOpen,
        // filterByTimestamp,
        handleCompanyFilterChange,
        handleEmailFilterChange,
        handlePhoneFilterChange,
        handleRowDoubleClick,
        handleModalClose,
        // handleUpdate,
        handleUpdateSubmit,
        handleDeleteSubmit,
        handleSelectAll,
        refreshCounter,
    } = useCompanyActions(apiCall, false);

    // Define table columns
    // const columns: Column<Company>[] = React.useMemo(
    const columns: Column<Company>[] = React.useMemo(() => {
        const phoneFilter = (rows: Row<Company>[], id: string, filterValue: any) => {
            return rows.filter((row) => {
                const rowValue = row.values[id];
                return Object.keys(rowValue).some((phoneNumber: string) =>
                    phoneNumber.toLowerCase().includes(filterValue.toLowerCase())
                );
            });
        };

        // Set autoRemove to true to remove the filter when the value is falsy
        phoneFilter.autoRemove = (val: any) => !val;

        return [
            {
                Header: 'Time Stamp',
                accessor: 'TimeStamp',
                sortType: 'basic'
            },
            {
                Header: 'CompanyId',
                accessor: 'CompanyId',
                sortType: 'basic'
            },
            {
                Header: 'Contact Phone Number',
                accessor: 'ContactPhoneNumber',
                sortType: 'basic'
            },
            {
                Header: 'Contact Email',
                accessor: 'ContactEmail',
                sortType: 'basic'
            },
            {
                Header: 'Active',
                accessor: 'IsActive',
                sortType: 'basic',
                Cell: ({ value }: { value: boolean }) => <div>{value ? 'Yes' : 'No'}</div>,
            },
            {
                Header: 'Users',
                accessor: (row: Company) => Object.keys(row.UsersList).length,
                sortType: 'basic',
                id: 'usersCount',
            },
            {
                Header: 'Users phone number',
                accessor: (row: Company) => row.UsersList,
                Cell: ({ value }: { value: { [keyPhoneNumber: string]: User } }) => {
                    const phoneNumbers = Object.keys(value).join(', ');
                    return <div>{phoneNumbers}</div>;
                },
                id: 'UsersList',
                filter: phoneFilter,
                sortType: 'basic',
            },
            {
                Header: 'Select',
                id: 'selection',
                Cell: ({ row }: { row: any }) => {
                    return (
                        <input
                            type="checkbox"
                            checked={selectedRows.includes(row.id)}
                            onChange={() => {
                                const newRowId = row.id;
                                const newSelectedRows = selectedRows.includes(newRowId)
                                    ? selectedRows.filter((id) => id !== newRowId)
                                    : [...selectedRows, newRowId];

                                setSelectedRows(newSelectedRows);
                            }}
                            onClick={(e: React.MouseEvent<HTMLInputElement>) => {
                                e.stopPropagation();
                            }}
                        />
                    );
                },
            },
        ];
    }, [selectedRows, setSelectedRows]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        setFilter,
        setGlobalFilter,
        page,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        pageOptions,
        gotoPage,
        state: { pageIndex },
    } = useTable<Company>(
        {
            columns,
            data: companyData,
            initialState: { pageIndex: 0, pageSize: MAX_NUM_OF_ROWS }, // default page size is 10
            // defaultColumn,
            filterTypes: {
                // phoneFilter: phoneFilter,
            }
        } as any,
        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination,
    ) as any//CustomTableInstance<Company> & CustomTableState<Company>;


    // Fetch data again
    useFetchCompanies({ setCompanyData, setIsLoading, apiCall, setSelectedRows, dependencies: refreshCounter });


    // Set initial data state and loading state
    useEffect(() => {
        console.log('useEffect-data');
        if (companyData?.length > 0 && refreshCounter === 0) {
            // setCompanyData(companyData);
            setIsLoading(false);
        }
    }, [companyData?.length, refreshCounter, setIsLoading]);

    // Reset selected rows when page changes
    useEffect(() => {
        console.log('useEffect-pageIndex');
        setSelectedRows([]);
        setSelectAllChecked(false)
    }, [pageIndex, setSelectAllChecked, setSelectedRows]);


    console.log('CompaniesTable');
    return (
        <>
            {/* Conditionally render the spinner based on the isLoading state */}
            {isLoading ? (
                <LoadingSpinner />
            ) : (
                <>
                    <div className="table-container">
                        <div className="table-content">
                            <PageHeader title="Companies" className="text-3xl font-bold mb-8" />
                            <FilterInputs
                                setGlobalFilter={setGlobalFilter}
                                handleCompanyFilterChange={handleCompanyFilterChange(setFilter)}
                                handleEmailFilterChange={handleEmailFilterChange(setFilter)}
                                handlePhoneFilterChange={handlePhoneFilterChange(setFilter)}
                            />
                            <div style={{ flexGrow: 1, width: '100%' }}>
                                <table {...getTableProps()} className="w-full">
                                    <TableHead
                                        headerGroups={headerGroups}
                                        onSelectAll={handleSelectAll(page)}
                                        allRowsSelected={selectAllChecked}
                                        selectionColumnId={'selection'}
                                        isEnableSelectAll={IS_ENABLE_SELECT_ALL} />
                                    {IS_LINE_CLICKABLE ?
                                        <TableBodyTouch
                                            rows={rows}
                                            prepareRow={prepareRow}
                                            getTableBodyProps={getTableBodyProps}
                                            page={page}
                                            setSelectedRow={setSelectedRow}
                                            onDoubleClick={handleRowDoubleClick}
                                        /> :
                                        <TableBody
                                            rows={rows}
                                            prepareRow={prepareRow}
                                            getTableBodyProps={getTableBodyProps}
                                            page={page}
                                        />
                                    }
                                </table>
                            </div>
                            <div
                                // className="float-right"
                                style={{
                                    marginTop: '30px',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    // alignItems: 'end',
                                    justifyContent: 'end'
                                }}
                            >
                                <DeleteAllButton
                                    selectedRows={selectedRows}
                                    rows={rows}
                                    onSubmit={handleDeleteSubmit}
                                />
                            </div>
                        </div>
                    </div>
                    {/* </div > */}
                    <Pagination
                        canPreviousPage={canPreviousPage}
                        canNextPage={canNextPage}
                        gotoPage={gotoPage}
                        nextPage={nextPage}
                        pageIndex={pageIndex}
                        previousPage={previousPage}
                        pageOptions={pageOptions}
                    />
                    <CompanyInfoModal
                        company={selectedCompany}
                        isOpen={modalOpen}
                        onRequestClose={handleModalClose}
                        // onUpdate={handleUpdate}
                        keycloak={keycloak}
                        setCompanyData={setCompanyData}
                        setIsLoading={setIsLoading}
                        handleSubmit={handleUpdateSubmit}
                        opt='update'
                    />
                </>
            )}
        </>
    );
};

export default TableCompanies;

console.log('TableCompanies.tsx');