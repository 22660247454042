interface TableBodyProps {
    rows: any[];
    prepareRow: any;
    getTableBodyProps: any;
    page: any;
}

export const TableBody: React.FC<TableBodyProps> = ({ rows, prepareRow, getTableBodyProps, page }) => {
    return (
        <tbody {...getTableBodyProps()}>
            {page.map((row: any) => {
                prepareRow(row);
                return (
                    <tr {...row.getRowProps()}>
                        {row.cells.map((cell: any) => {
                            return (
                                <td
                                    {...cell.getCellProps()}
                                    className="py-2 px-3 border-b border-gray-200 text-sm"
                                >
                                    {cell.render('Cell')}
                                    {cell.column.Header === 'Active' && cell.value === false && (
                                        <span className="text-red-500 ml-1">(Inactive)</span>
                                    )}
                                </td>
                            );
                        })}
                    </tr>
                );
            })}
        </tbody>
    );
};