// ProfilePage.tsx

import PageHeader from '../../components/PageHeader';
import UserProfileInfo from '../../components/ProfileInfo/UserProfileInfo';

const ProfilePage = () => {
    const headerHeight = 80; // Replace with the actual header height
    const footerHeight = 80; // Replace with the actual footer height

    return (
        <div
            className="container mx-auto px-4 sm:px-6 lg:px-8 py-12"
            style={{
                backgroundImage:
                    "url('https://source.unsplash.com/random/1600x900?profile')",
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                minHeight: `calc(100vh - ${headerHeight}px - ${footerHeight}px)`,
            }}
        >
            <div
                className="bg-white opacity-80 p-8 rounded-md"
                style={{
                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                }}
            >
                <PageHeader title="Profile" className="text-3xl font-bold mb-8" />
                <UserProfileInfo />
            </div>
        </div>
    );
};

export default ProfilePage;