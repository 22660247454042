// CustomToastOptions.tsx

import { toast } from 'react-toastify';

interface CustomToastOptions {
  position?: 'top-right' | 'top-center' | 'top-left' | 'bottom-right' | 'bottom-center' | 'bottom-left';
  autoClose?: number;
  hideProgressBar?: boolean;
  closeOnClick?: boolean;
  pauseOnHover?: boolean;
  draggable?: boolean;
  progress?: any;
}

export const customToast = (
  message: string,
  type: 'success' | 'error',
  options: CustomToastOptions = {}
) => {
  const defaultOptions: CustomToastOptions = {
    position: 'top-center',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  const finalOptions = { ...defaultOptions, ...options };

  if (type === 'success') {
    toast.success(message, finalOptions);
  } else if (type === 'error') {
    toast.error(message, finalOptions);
  }
};