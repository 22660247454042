// PrivateRoute.tsx

// import { Navigate } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';

// Define the props for the PrivateRoute component
interface PrivateRouteProps {
    component: React.ComponentType; // The component that should be rendered when the user is authenticated
}

// Define the PrivateRoute component
const PrivateRoute: React.FC<PrivateRouteProps> = ({ component: Component }) => {
    const { keycloak } = useKeycloak(); // Get the keycloak instance from the useKeycloak hook

    // If the user is not authenticated, redirect to the Keycloak login page
    if (!keycloak.authenticated) {
        keycloak.login();
        return null;
        // for making all the website private
        // return <Navigate to="/" replace />;
    }

    // If the user is authenticated, render the specified component
    return <Component />;
};

export default PrivateRoute;