// Strings.ts

export function Capitalize(word: string): string {
  return word.charAt(0).toUpperCase() + word.slice(1);
}

export function RemovePrefix(input: string, prefix: string): string {
  if (input.startsWith(prefix)) {
    return input.slice(prefix.length);
  }
  return input;
}
