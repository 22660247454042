// useCompanyActions.ts

import { useCallback, useState } from "react";
import { ApiCallFunction } from "../interfaces/hooks";
import { Company, User } from "../interfaces/Company";
import { CompaniesApi } from "../config/api/managers/CompaniesApi";
import { customToast } from "../components/CustomToastOptions";

interface useCompanyActionsProps {
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  startTime: string | null;
  setStartTime: React.Dispatch<React.SetStateAction<string | null>>;
  endTime: string | null;
  setEndTime: React.Dispatch<React.SetStateAction<string | null>>;
  filterByTimestamp: (rows: any[]) => any[];
  handlePhoneFilterChange: (
    setFilter: any
  ) => (event: React.ChangeEvent<HTMLInputElement>) => void;
  selectedRow: Company;
  setSelectedRow: React.Dispatch<React.SetStateAction<Company>>;
  selectedRows: string[];
  setSelectedRows: React.Dispatch<React.SetStateAction<string[]>>;
  selectAllChecked: boolean;
  setSelectAllChecked: React.Dispatch<React.SetStateAction<boolean>>;
  handleSelectAll: (page: any) => (checked: boolean) => void;
  handleCompanyFilterChange: (
    setFilter: any
  ) => (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleEmailFilterChange: (
    setFilter: any
  ) => (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleRowDoubleClick: (row: Company) => void;
  handleModalClose: () => void;
  // handleUpdate: (updatedCompany: Company) => void;
  handleUpdateSubmit: (updateCompany: Company) => Promise<void>;
  handleDeleteSubmit: (rows: any) => Promise<void>;
  companyData: Company[];
  setCompanyData: React.Dispatch<React.SetStateAction<Company[]>>;

  selectedCompany: Company | undefined;
  setSelectedCompany: React.Dispatch<React.SetStateAction<Company | undefined>>;
  modalOpen: boolean;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleInsertSubmit: (newCompany: Company) => Promise<void>;
  handleSearch: (companyName: string) => Promise<void>;
  refreshCounter: number;
  setRefreshCounter: React.Dispatch<React.SetStateAction<number>>;
}

export const useCompanyActions = (
  apiCall: ApiCallFunction,
  isPage: boolean
): useCompanyActionsProps => {
  // debug
  console.log("useCompanyActions hook called");

  // State for managing the loading state
  const [isLoading, setIsLoading] = useState<boolean>(!isPage);

  // State for managing the selected row
  const [selectedRow, setSelectedRow] = useState<Company>({} as Company);

  // State for managing the start and end times
  const [startTime, setStartTime] = useState<string | null>(null);
  const [endTime, setEndTime] = useState<string | null>(null);

  // State for managing the selected rows
  const [selectedRows, setSelectedRows] = useState<string[]>([]);

  // Add a new state for managing the select all checkbox
  const [selectAllChecked, setSelectAllChecked] = useState(false);

  // State for managing the selected company
  const [selectedCompany, setSelectedCompany] = useState<Company | undefined>();

  // State for managing the update modal
  const [modalOpen, setModalOpen] = useState(false);

  // State for managing company data
  const [companyData, setCompanyData] = useState<Company[]>([]);

  // for fetch the data again when a "refresh" needed
  const [refreshCounter, setRefreshCounter] = useState(0);

  // Action handlers //

  // Function to handle searching for a company by name
  const handleSearch = useCallback(
    async (companyName: string) => {
      if (!companyName) return;

      try {
        // set the loading state to true
        setIsLoading(true);

        const queryParams = { CompanyId: companyName };
        const url = new URL(CompaniesApi.Get.url);
        const searchParams = new URLSearchParams(queryParams);
        url.search = searchParams.toString();

        // Send GET request to the API
        const response = await apiCall(url.toString(), CompaniesApi.Get.method);

        // debug
        console.log("response:", response);

        // Check if the response is valid and there is only one company in it
        if (!(response?.length === 1)) {
          throw new Error(`The company ${companyName} not found`);
        }

        // Display a success toast
        customToast(`The company ${companyName} was found.`, "success");
        // set the company data
        setSelectedCompany(response[0]);
      } catch (error: any) {
        setSelectedCompany(undefined);
        console.error("Error updating company:", error);
        if (error instanceof Error) {
          customToast(error.message, "error");
        } else {
          customToast("An unknown error occurred.", "error");
        }
      } finally {
        // set the loading state to false
        setIsLoading(false);
      }
    },
    [apiCall]
  );

  // Function to handle updating the company
  const handleUpdateSubmit = useCallback(
    async (updateCompany: Company) => {
      // Check if the new company is defined
      if (!updateCompany) {
        return;
      }
      // Send PATCH request to the API
      try {
        // set the loading state to true
        setIsLoading(true);

        // Move on the object in updateCompany.UsersList and replace all the keys that start with "newUser".
        // The new key will be the phone number of the user.
        // Use reduce on the object's entries to create a new object with updated keys
        const updatedUsersList = Object.entries(updateCompany.UsersList).reduce(
          (accumulator: { [key: string]: User }, [key, user]) => {
            const newKey = key.includes("newUser") ? user.PhoneNumber : key;
            if (newKey) {
              accumulator[newKey] = user;
            }
            return accumulator;
          },
          {}
        );

        // Replace the original UsersList with the updated one
        updateCompany.UsersList = updatedUsersList;

        // Send the PATCH request to the API
        await apiCall(
          CompaniesApi.Update.url,
          CompaniesApi.Update.method,
          updateCompany
        );
        // Display a success toast
        customToast(
          `The company ${updateCompany.CompanyId} has been updated.`,
          "success"
        );

        // Close the modal
        // handleUpdate(updateCompany);

        // update the company data table
        if (!isPage) {
          setRefreshCounter((prevCounter) => prevCounter + 1);
        } else {
          setSelectedCompany(updateCompany);
        }
      } catch (error: any) {
        console.error("Error updating company:", error);
        if (error instanceof Error) {
          customToast(error.message, "error");
        } else {
          customToast("An unknown error occurred.", "error");
        }
      } finally {
        // close the update modal
        setModalOpen(false);
        // set the loading state to false
        setIsLoading(false);
      }
    },
    [apiCall, isPage]
  );

  const handleInsertSubmit = useCallback(
    async (newCompany: Company) => {
      if (!newCompany) {
        return;
      }

      try {
        setIsLoading(true);

        // Move on the object in updateCompany.UsersList and replace all the keys that start with "newUser".
        // The new key will be the phone number of the user.
        // Use reduce on the object's entries to create a new object with updated keys
        const updatedUsersList = Object.entries(newCompany.UsersList).reduce(
          (accumulator: { [key: string]: User }, [key, user]) => {
            const newKey = key.includes("newUser") ? user.PhoneNumber : key;
            if (newKey) {
              accumulator[newKey] = user;
            }
            return accumulator;
          },
          {}
        );

        // Replace the original UsersList with the updated one
        newCompany.UsersList = updatedUsersList;

        // Send the POST request to the API
        await apiCall(
          CompaniesApi.Insert.url,
          CompaniesApi.Insert.method,
          newCompany
        );
        // Display a success toast
        customToast(
          `The company ${newCompany.CompanyId} has been added.`,
          "success"
        );

        // update the company data table
        if (!isPage) {
          setRefreshCounter((prevCounter) => prevCounter + 1);
        }
      } catch (error: any) {
        console.error("Error updating company:", error);
        if (error instanceof Error) {
          customToast(error.message, "error");
        } else {
          customToast("An unknown error occurred.", "error");
        }
      } finally {
        if (!isPage) {
          // close the update modal
          setModalOpen(false);
        }
        // set the loading state to false
        setIsLoading(false);
      }
    },
    [apiCall, isPage]
  );

  // delete the Companies selected
  const handleDeleteSubmit = useCallback(
    async (rows: any) => {
      // debug
      console.log("handleDeleteSubmit: ", rows);

      // Set the loading state
      setIsLoading(true);

      // Get an array of rows to delete and join the company names for display in the confirmation modal
      const rowsToDelete = rows.filter((row: any) =>
        selectedRows.includes(row.id)
      );
      try {
        // Send delete request to the server for each selected row
        for (const row of rowsToDelete) {
          setIsLoading(true); // Start the loading spinner
          const companyName = row.values.CompanyId;
          const jsonToSend = { CompanyId: companyName };
          await apiCall(
            CompaniesApi.Delete.url,
            CompaniesApi.Delete.method,
            jsonToSend
          );

          // Show a success toast
          customToast(
            `The company ${companyName} deleted successfully.`,
            "success",
            {
              autoClose: 3000,
            }
          );
        }
        // update the company data table
        if (!isPage) {
          setRefreshCounter((prevCounter) => prevCounter + 1);
        }
      } catch (error: any) {
        console.error("handleUpdateApplicantCompany:", error);
        if (error instanceof Error) {
          customToast(error.message, "error");
        } else {
          customToast("An unknown error occurred.", "error");
        }
      } finally {
        // Set the loading state
        setIsLoading(false);
      }
    },
    [apiCall, isPage, selectedRows]
  );

  // Function to handle the change event of the company filter input
  const handleCompanyFilterChange = useCallback(
    (setFilter: any) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const target = event.target as HTMLInputElement;
      const { value } = target;
      setFilter("CompanyId", value);
    },
    []
  );

  // filter according to the time stamp
  const filterByTimestamp = useCallback(
    (rows: any[]) => {
      if (!startTime && !endTime) return rows;

      const filteredRows = rows.filter((row) => {
        if (!row) return false;

        const timestamp = new Date(row.timestamp);
        const start = startTime ? new Date(startTime) : null;
        const end = endTime ? new Date(endTime) : null;

        return (!start || timestamp >= start) && (!end || timestamp <= end);
      });

      return filteredRows;
    },
    [startTime, endTime]
  );

  // Function to handle the change event of the phone number filter input
  const handleEmailFilterChange = useCallback(
    (setFilter: any) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const target = event.target as HTMLInputElement;
      const { value } = target;
      setFilter("ContactEmail", value);
    },
    []
  );

  // Function to handle the change event of the phone number filter input
  const handlePhoneFilterChange = useCallback(
    (setFilter: any) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const target = event.target as HTMLInputElement;
      const { value } = target;
      setFilter("UsersList", value); // set the filter for the 'UsersList' column
    },
    []
  );

  // Function to handle the double click event of a row
  const handleRowDoubleClick = useCallback((row: Company) => {
    setSelectedCompany(row);
    setModalOpen(true);
  }, []);

  const handleModalClose = () => {
    setModalOpen(false);
  };

  // Function to handle the checkbox
  const handleSelectAll = useCallback(
    (page: any) => (checked: boolean) => {
      // If the checkbox is checked, select all rows on the current page
      if (checked) {
        setSelectedRows(page.map((row: any) => row.id));
      } else {
        // If the checkbox is unchecked, clear the selected rows
        setSelectedRows([]);
      }
      // Update the selectAllChecked state to reflect the new checked status
      setSelectAllChecked(checked);
    },
    []
  );

  return {
    companyData,
    setCompanyData,
    isLoading,
    setIsLoading,
    selectedRow,
    setSelectedRow,
    selectedRows,
    setSelectedRows,
    selectAllChecked,
    setSelectAllChecked,
    startTime,
    setStartTime,
    endTime,
    setEndTime,
    selectedCompany,
    setSelectedCompany,
    modalOpen,
    setModalOpen,
    filterByTimestamp,
    handleCompanyFilterChange,
    handleEmailFilterChange,
    handlePhoneFilterChange,
    handleRowDoubleClick,
    handleModalClose,
    refreshCounter,
    setRefreshCounter,
    // handleUpdate,
    handleUpdateSubmit,
    handleDeleteSubmit,
    handleSelectAll,
    handleInsertSubmit,
    handleSearch,
  };
};
