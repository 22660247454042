// useFetchHistoryNotification.ts

import { useCallback, useEffect } from "react";
import { ApiCallFunction } from "../interfaces/hooks";
import { NotificationApi } from "../config/api/managers/NotificationApi";

interface useFetchCompaniesNameProps {
  setHistoryNotificationData: any;
  setIsLoading: (loading: boolean) => void;
  apiCall: ApiCallFunction;
}

const useFetchHistoryNotification = ({
  setHistoryNotificationData,
  setIsLoading,
  apiCall,
}: useFetchCompaniesNameProps) => {
  const fetchData = useCallback(async () => {
    try {
      // Fetch the data
      const jsonData = await apiCall(
        NotificationApi.Get.url,
        NotificationApi.Get.method,
        undefined,
        {}
      );

      // debug
      console.log("jsonData:", jsonData);

      // Update the company options
      setHistoryNotificationData(jsonData);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      // Set the loading state to false
      setIsLoading(false);
    }
  }, [apiCall, setHistoryNotificationData, setIsLoading]);

  useEffect(() => {
    console.log("useFetchHistoryNotification hook called");
    // Set the loading state to true
    setIsLoading(true);

    // Execute the fetchData function
    fetchData();
  }, [fetchData, setIsLoading]);
};

export default useFetchHistoryNotification;