import { useEffect } from "react";
import { CompanyApplicant } from "../interfaces/CompanyApplicant";
import { CompaniesApplicantApi } from "../config/api/managers/CompaniesApplicantApi";
import Keycloak from "keycloak-js";
import { ApiCallFunction } from "../interfaces/hooks";

interface UseFetchApplicantCompaniesProps {
  applicantCompanyData: CompanyApplicant[];
  setApplicantCompanyData: (data: CompanyApplicant[]) => void;
  setIsLoading: (loading: boolean) => void;
  apiCall: ApiCallFunction;
  keycloak: Keycloak;
}

const useFetchApplicantCompanies = ({
  applicantCompanyData,
  setApplicantCompanyData,
  setIsLoading,
  apiCall,
  keycloak,
}: UseFetchApplicantCompaniesProps) => {
  useEffect(() => {
    if (applicantCompanyData.length === 0) {
      const fetchData = async () => {
        try {
          const response = await apiCall(
            CompaniesApplicantApi.Get.url,
            CompaniesApplicantApi.Get.method,
            undefined,
            {
              headers: {
                Authorization: `Bearer ${keycloak.token}`,
                "Content-Type": "application/json",
                Accept: "application/json",
              },
            }
          );
          console.log("response", response);
          setApplicantCompanyData(response as any);
        } catch (error) {
          console.error("Error fetching data:", error);
        } finally {
          setIsLoading(false);
        }
      };
      fetchData();
    }
  }, [apiCall, applicantCompanyData.length, keycloak.token, setApplicantCompanyData, setIsLoading]); // Removed the dependency array
};

export default useFetchApplicantCompanies;
