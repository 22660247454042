// App.tsx

import { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import './App.css';
import NavBar from './components/NavBar/NavBar';
import PrivateRoute from './routes/PrivateRoute';
import routes from './routes/routes';
import Footer from './components/Footer/Footer';
import { ToastContainer } from 'react-toastify';

function App() {
  const { keycloak, initialized } = useKeycloak();

  // Check if the user is authenticated - for making all the website private.
  // useEffect(() => {
  //   if (initialized && !keycloak.authenticated) {
  //     keycloak.login();
  //   }
  // }, [keycloak, initialized]);

  // Check if the user is authenticated for private routes only
  useEffect(() => {
    if (initialized && !keycloak.authenticated) {
      const currentPath = window.location.pathname;
      const isPrivateRoute = routes.some(route => route.private && route.path === currentPath);

      if (isPrivateRoute) {
        keycloak.login();
      }
    }
  }, [keycloak, initialized]);

  // Show a loading message while initializing the keycloak instance
  if (!initialized) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <BrowserRouter>
        <div className="flex flex-col min-h-screen">
          {/* Render the NavBar component */}
          <NavBar />
          {/* Define application routes */}
          <div className="content flex-grow container mx-auto px-4">
            <Routes>
              {routes.map((route) =>
                route.private ? (
                  <Route
                    key={route.path}
                    path={route.path}
                    element={<PrivateRoute component={route.component} />}
                  />
                ) : (
                  <Route
                    key={route.path}
                    path={route.path}
                    element={<route.component />}
                  />
                )
              )}
            </Routes>
          </div>
          {/* Render the Footer component */}
          <Footer />
        </div>
      </BrowserRouter>
      <ToastContainer />
    </>
  );
}

export default App;
