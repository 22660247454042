// env.ts

// Declare the global _env_ object
declare global {
  interface Window {
    _env_: {
      REACT_APP_KEYCLOAK_BASE_URL?: string;
      REACT_APP_KEYCLOAK_REALM?: string;
      REACT_APP_KEYCLOAK_CLIENT_ID?: string;
      REACT_APP_BASE_URL?: string;
      REACT_APP_IS_LINE_CLICKABLE?: string;
      REACT_APP_MAX_NUM_OF_ROWS?: string;
      REACT_APP_IS_ENABLE_SELECT_ALL?: string;
    };
  }
}

// keycloak
const DEFAULT_KEYCLOAK_BASE_URL = "https://keycloak.tech-ua.com/";
export const KEYCLOAK_BASE_URL =
  window._env_.REACT_APP_KEYCLOAK_BASE_URL || DEFAULT_KEYCLOAK_BASE_URL;

const DEFAULT_KEYCLOAK_REALM = "buildtech";
export const KEYCLOAK_REALM =
  window._env_.REACT_APP_KEYCLOAK_REALM || DEFAULT_KEYCLOAK_REALM;

const DEFAULT_KEYCLOAK_CLIENT_ID = "login";
export const KEYCLOAK_CLIENT_ID =
  window._env_.REACT_APP_KEYCLOAK_CLIENT_ID || DEFAULT_KEYCLOAK_CLIENT_ID;

// BuildTech - BackEnd
const DEFAULT_BASE_URL = "http://localhost:3000/api/v1";
export const BASE_URL = window._env_.REACT_APP_BASE_URL || DEFAULT_BASE_URL;

// generals
export const IS_LINE_CLICKABLE =
  window._env_.REACT_APP_IS_LINE_CLICKABLE !== "false";

const DEFAULT_MAX_NUM_OF_ROWS = 10;
export const MAX_NUM_OF_ROWS = parseInt(
  window._env_.REACT_APP_MAX_NUM_OF_ROWS ?? `${DEFAULT_MAX_NUM_OF_ROWS}`,
  10
);

export const IS_ENABLE_SELECT_ALL =
  window._env_.REACT_APP_IS_ENABLE_SELECT_ALL !== "false";
