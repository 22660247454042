// DeleteAllButton.tsx

import React, { useState } from "react";
// import Keycloak from 'keycloak-js';
import { ConfirmationModal } from "../ConfirmationModal";
import CustomButton from "../CustomButton";

interface DeleteAllButtonProps {
  selectedRows: string[];
  rows: any;
  onSubmit: (rows: any) => void;
}

const DeleteAllButton: React.FC<DeleteAllButtonProps> = ({
  selectedRows,
  rows,
  onSubmit,
}) => {
  // State to manage the loading state of the button and the modal's open state
  const [isModalOpen, setIsModalOpen] = useState(false);

  let tempID = "";

  // Get an array of rows to delete and join the company names for display in the confirmation modal
  const rowsToDelete = rows.filter((row: any) => selectedRows.includes(row.id));
  console.log("rowsToDelete", rowsToDelete);
  if (rowsToDelete?.[0]?.values?.CompanyId) {
    tempID = rowsToDelete.map((row: any) => row.values.CompanyId).join(", ");
  } else if (rowsToDelete?.[0]?.values?.HintId) {
    tempID = rowsToDelete.map((row: any) => row.values.HintId).join(", ");
  }

  const handleConfirm = () => {
    setIsModalOpen(false);
    if (rows) {
      onSubmit(rows);
    }
  };

  return (
    <>
      <CustomButton
        onClick={() => setIsModalOpen(true)}
        disabled={selectedRows.length === 0}
        type="delete"
      >
        <span>Delete All</span>
      </CustomButton>

      {/* Confirmation modal */}
      <ConfirmationModal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        title="Delete Selected Companies"
        message={`Are you sure you want to delete ${tempID}?`}
        onCancel={() => setIsModalOpen(false)}
        onConfirm={handleConfirm}
      />
    </>
  );
};

export default DeleteAllButton;
