// FlashUpdates.tsx

import React from "react";
import { motion, AnimatePresence, MotionConfig } from "framer-motion";

import {
  FaRegLightbulb,
  FaTools,
  FaBuilding,
  FaUserFriends,
  FaDollarSign,
  FaArchive,
} from "react-icons/fa";

const updates = [
  {
    id: 1,
    text: "BuildTech introduces AI-driven Smart Reports to revolutionize report creation and compliance in the construction industry.",
    icon: FaRegLightbulb,
  },
  {
    id: 2,
    text: "Our platform now features enhanced Team Collaboration tools, enabling seamless integration with Slack, Discord, and project management software.",
    icon: FaUserFriends,
  },
  {
    id: 3,
    text: "Unveiling new In-app Currency options, facilitating seamless transactions and rewards within BuildTech’s ecosystem.",
    icon: FaDollarSign,
  },
  {
    id: 4,
    text: "Advanced In-app Storage solutions launched, featuring AI auto-tagging and collaborative editing for stored documents.",
    icon: FaArchive,
  },
  // Adjusting content to reflect provided company overview
  {
    id: 5,
    text: "Introducing real-time collaborative editing and multilingual support, making report generation more accessible and efficient.",
    icon: FaTools,
  },
  {
    id: 6,
    text: "BuildTech’s new feature enables detailed report archives, predictive text completion, and customizable report layouts for enhanced personalization.",
    icon: FaBuilding,
  },
];

const animationVariants = {
  hidden: { opacity: 0, y: -20 },
  visible: { opacity: 1, y: 0 },
};

const FlashUpdates: React.FC = () => {
  return (
    <section className="bg-blue-500 py-4">
      <MotionConfig>
        <AnimatePresence mode="wait">
          <motion.div
            className="container mx-auto px-4 sm:px-6 lg:px-8 text-center"
            initial="hidden"
            animate="visible"
            exit="hidden"
            variants={animationVariants}
            transition={{ duration: 0.5 }}
          >
            <h2 className="text-xl font-bold text-white mb-4">
              Latest Updates
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              {updates.map((update) => (
                <motion.div
                  key={update.id}
                  className="bg-white text-blue-700 font-bold p-6 rounded-lg shadow-lg flex flex-col items-center"
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                >
                  <update.icon className="text-2xl mb-2" />
                  {update.text}
                </motion.div>
              ))}
            </div>
          </motion.div>
        </AnimatePresence>
      </MotionConfig>
    </section>
  );
};

export default FlashUpdates;
