// NavBar.tsx

import { Link } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import "./NavBar.css";
import Dropdown from "./Dropdown";
import Logo from "../Logo";
import NavLinks from "./NavLinks";
import { useState } from "react";

function NavBar() {
  const [activeDropdown, setActiveDropdown] = useState("");
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const { keycloak } = useKeycloak();

  const handleLogout = () => {
    keycloak.logout();
  };

  const navLinks = [
    // { name: 'Profile', to: '/profile' },
    // { name: 'Terms of Service', to: '/terms' },
    // { name: 'FAQ', to: '/faq' },
    // { name: 'Help', to: '/help' },
    // // { name: 'Privacy Policy', to: '/privacy' },
    // { name: 'Contact', to: '/contact' },
    { name: "About", to: "/about" },
  ];

  const companyLinks = [
    { name: "Companies", to: "/companies" },
    { name: "Add Company", to: "/add-company" },
    { name: "Update Company", to: "/update-company" },
    { name: "Applicant Companies", to: "/applicant-companies" },
  ];

  const notificationLinks = [
    { name: "Send Notification", to: "/notification" },
    { name: "History Notification", to: "/history-notification" },
  ];

  const hintsLinks = [
    { name: "Hints", to: "/table-hints" },
    { name: "Add hint", to: "/add-hint" },
    { name: "Update hint", to: "/update-hint" },
  ];

  return (
    <nav className="bg-lightblue">
      <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-19">
          <div className="flex">
            <Link to="/">
              <Logo />
            </Link>
          </div>
          <div className="-mr-2 flex md:hidden">
            <button
              onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-500 hover:text-white hover:bg-gray-800 focus:outline-none focus:bg-gray-900 focus:text-white"
            >
              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            </button>
          </div>
          <div className="hidden md:block">
            <div className="ml-10 flex items-baseline space-x-4 space-y-4">
              <NavLinks links={[{ name: "Home", to: "/" }]} />
              <Dropdown
                name="Companies"
                links={companyLinks}
                activeDropdown={activeDropdown}
                setActiveDropdown={setActiveDropdown}
              />
              <Dropdown
                name="Notification"
                links={notificationLinks}
                activeDropdown={activeDropdown}
                setActiveDropdown={setActiveDropdown}
              />
              <Dropdown
                name="Hints"
                links={hintsLinks}
                activeDropdown={activeDropdown}
                setActiveDropdown={setActiveDropdown}
              />
              <NavLinks links={navLinks} />
              {keycloak.authenticated && (
                <button
                  onClick={handleLogout}
                  className="text-gray-800 hover:text-gray-900 focus:outline-none focus:text-gray-900"
                >
                  Log Out
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      {isMobileMenuOpen && (
        <div className="md:hidden">
          <div className="px-2 pt-2 pb-3 space-y-1">
            <NavLinks links={[{ name: "Home", to: "/" }]} />
            <Dropdown
              name="Companies"
              links={companyLinks}
              activeDropdown={activeDropdown}
              setActiveDropdown={setActiveDropdown}
            />
            <Dropdown
              name="Notification"
              links={notificationLinks}
              activeDropdown={activeDropdown}
              setActiveDropdown={setActiveDropdown}
            />
            <Dropdown
              name="Hints"
              links={hintsLinks}
              activeDropdown={activeDropdown}
              setActiveDropdown={setActiveDropdown}
            />
            <NavLinks links={navLinks} />
            <button
              onClick={handleLogout}
              className="block w-full text-left px-3 py-2 text-gray-800 hover:text-gray-900 focus:outline-none focus:text-gray-900"
            >
              Log Out
            </button>
          </div>
        </div>
      )}
    </nav>
  );
}
export default NavBar;
